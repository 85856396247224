import React, { useContext, useEffect, useState, useRef } from "react"
import { _nopropagate } from "../helpers/utils"
import {  useLocalBoundData } from "../hooks/useBoundData"
import { DISCOVERY_POST_SECTION_CONFIG } from "../consts"
import { useCommonBESectionFeatures } from "./helperHooks"
import { dataContext } from "../contexts/datacontext"
import { apiContext } from "../contexts/apicontext"
import { useModalHelper } from "../components/dialogs/creatorContentModal"
import { useAuthHelper } from "./useAuthHelper"
import { GET_CALLS } from "../consts"
import { AIFilterFilterValues } from "../components/structural/contentAndCreatorFilter"

const useFilteredContent = ({
  section,
  skipStats,
  cutoffAt = 0,
  item_modifier_func = (x) => x,
  showFilter = false,
  additionalFilters = {},
  overrideFilter = {},
  normalDefaultFilter,
  countAdditionalFilterAsOG = false,
  useExternalFilter = false,
  externalFilters,
  setExternalFilters,
  externalSortAndTimeFrame,
  setExternalSortAndTimeFrame,
  skipContentRequest = false,
  stopAutoApiCall = false,
}) => {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)

  const [filterQueryParam, setFilterQueryParam] = useState("")
  const [detailOpen, sDO] = useState(false)
  const [internalSortAndTimeFrame, setInternalSortAndTimeFrame] = useState({})
  const [internalFilter, setInternalFilter] = useState({})

  const { cutoff, hasVisual, isViewAllMode } = useCommonBESectionFeatures(section, cutoffAt)
  const showModal = useModalHelper()
  const { isGuest } = useAuthHelper()

  const {
    loader,
    payload: original_payload,
    pagination,
    pollingStatus,
  } = useLocalBoundData({
    ...GET_CALLS?.[section],
    filter: filterQueryParam
      ? { ...Object.fromEntries(new URLSearchParams(filterQueryParam).entries()), ...overrideFilter }
      : {},
    sub_brand_id: stopAutoApiCall ? null : data?.sub_brand_id,
    platform: data?.discovery_section_platform ?? "tiktok",
    skip: skipContentRequest || (showFilter && !filterQueryParam),
  })
  const { loader: stats_loader, payload: content_stats_payload } = useLocalBoundData({
    ...GET_CALLS.content_stats,
    skip: !filterQueryParam || skipStats,
    filter: filterQueryParam
      ? { ...Object.fromEntries(new URLSearchParams(filterQueryParam).entries()), ...overrideFilter }
      : {},
    sub_brand_id: data?.sub_brand_id,
    platform: data?.discovery_section_platform ?? "tiktok",
  })

  const modified_payload = original_payload?.map(item_modifier_func)
  const filters = useExternalFilter ? externalFilters : internalFilter
  const setFilters = useExternalFilter ? setExternalFilters : setInternalFilter
  const sortAndTimeFrame = useExternalFilter ? externalSortAndTimeFrame : internalSortAndTimeFrame
  const setSortAndTimeFrame = useExternalFilter ? setExternalSortAndTimeFrame : setInternalSortAndTimeFrame

  const onApply = async (default_filter = {}, skipSaveFilter = true, forceDefaultAtLast = false) => {
    let _filters = forceDefaultAtLast
      ? {
          ...sortAndTimeFrame,
          ...filters,
          ...default_filter,
        }
      : {
          ...default_filter,
          ...sortAndTimeFrame,
          ...filters,
        }
    // additional filter won't show up in the length count
    if (countAdditionalFilterAsOG) {
      _filters = {
        ...additionalFilters,
        ..._filters,
      }
    }
    if (showFilter && !Object.keys(_filters).length) {
      return
    }
    if (!countAdditionalFilterAsOG) {
      _filters = {
        ...additionalFilters,
        ..._filters,
      }
    }
    dispatch({
      [`bd_sec_${section}_page`]: null,
      ["bd_sec_" + section]: null,
      ["bd_sec_" + section + "_raw"]: null,
    })
    if (_filters?.engagement_rate__gte) {
      _filters.engagement_rate__gte = Number(_filters.engagement_rate__gte) / 100
    }
    if (_filters?.is_not_influencer_content) {
      _filters.is_influencer_content = false
      delete _filters.is_not_influencer_content
    }

    if (_filters?.exc_comment_mentioned && section == "all_posts") {
      _filters.is_tagged_post = true
    }
    _filters.fe_section = section
    if (_filters["ai_comment_filter"]) {
      const ai_comment_filter = AIFilterFilterValues[_filters["ai_comment_filter"]]?.filter
      _filters = {
        ..._filters,
        ...ai_comment_filter,
      }
    }
    delete _filters["ai_comment_filter"]

    // delete _filters["exc_comment_mentioned"] for engage page if brand setting allow_comment_mention_post_in_engage is true
    if (
      data?.me?.brand?.settings?.allow_comment_mention_post_in_engage &&
      section.includes("engage") &&
      _filters["exc_comment_mentioned"]
    ) {
      // console.log("_filters", _filters)
      delete _filters["exc_comment_mentioned"]
    }

    let _filterQuery = new URLSearchParams(_filters).toString()

    if (
      data?.me?.brand?.settings?.is_political_engage_enabled &&
      data?.sub_brand_id &&
      data?.me?.user_group == 1 &&
      section == "mass_engage_all" &&
      !skipSaveFilter
    ) {
      const payload = {
        section_filters: [
          {
            section_name: "mass_engage_all",
            filter_query: _filterQuery,
          },
        ],
      }
      await api.common.postRequestHelper({
        endpoint: `/Brand/${data?.sub_brand_id}/`,
        section: "mass_engage_all_filters",
        payload: payload,
        dataContextPrefix: "bd_sec_",
        requestType: "patch",
      })
      await api.brand.me()
    }
    setFilterQueryParam(_filterQuery)
  }

  const setPresetFilters = () => {
    if (DISCOVERY_POST_SECTION_CONFIG[section]?.filters || normalDefaultFilter) {
      setFilters({ ...(DISCOVERY_POST_SECTION_CONFIG[section]?.filters || normalDefaultFilter) })
    }

    if (DISCOVERY_POST_SECTION_CONFIG[section]?.sortAndTimeFrame) {
      setSortAndTimeFrame(DISCOVERY_POST_SECTION_CONFIG[section]?.sortAndTimeFrame)
    }
  }
  useEffect(() => {
    setPresetFilters()
  }, [])

  useEffect(() => {
    if (!showFilter || stopAutoApiCall) return
    if (normalDefaultFilter) {
      onApply(normalDefaultFilter)
      return
    }

    onApply()
  }, [sortAndTimeFrame])

  return {
    showModal,
    original_payload,
    modified_payload,
    hasVisual,
    content_stats_payload,
    stats_loader,
    cutoff,
    loader,
    pagination,
    pollingStatus,
    sortAndTimeFrame,
    setSortAndTimeFrame,
    detailOpen,
    filters,
    setFilters,
    sDO,
    isGuest,
    onApply,
    isViewAllMode,
    filterQueryParam,
  }
}

export { useFilteredContent }
