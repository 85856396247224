import { useState, useEffect, useContext, useRef } from "react"
import { dataContext } from "../contexts/datacontext"
import { BRAND_FE_CONFIG, MUTATION_CALLS } from "../consts"
import { apiContext } from "../contexts/apicontext"
import { useToast } from "./useToast"
import { useNavigate, useSearchParams } from "react-router-dom"
import { _empty, combineURLSearchParams, updateObjectCollection } from "../helpers/utils"
import { useBDBoundData, useBDPostData, useLocalBoundData, useLocalDataMutation } from "./useBoundData"
import clipboard from "clipboardy"
import { BanSolid } from "../components/Icons"
import { useAtom } from "jotai"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import { useServer } from "./useServer"
import { GET_CALLS } from "../consts"
import {
  addArchiveFromAICommentIDAtom,
  archiveFromAICommentIDSetAtom,
  clientSideContents,
  dailyStatsAtom,
  engageStatsAtom,
  removeArchiveFromAICommentIDAtom,
  restrictionAtom,
} from "../store/store"

dayjs.extend(duration)

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

function useCommonBESectionFeatures(section, cutoffAt) {
  const [data, dispatch] = useContext(dataContext)
  const isViewAllMode = !cutoffAt || (data?.be_section_view_all && data?.be_section_view_all === section)
  const cutoff = (items) => {
    if (!items?.length) return []
    if (isViewAllMode) return items
    return items.slice(0, cutoffAt)
  }
  const hasVisual = !data?.be_section_view_all || data?.be_section_view_all === section
  return {
    cutoff,
    hasVisual,
    isViewAllMode,
  }
}

function useBrandConfig() {
  const [data] = useContext(dataContext)
  const brand_id = data?.me?.brand?.id
  const fields = {
    brand_motto: null,
    brand_type: null,
    brand_category: null,
    is_mass_engage_enabled: null,
    is_transcription_enabled: null,
    is_ai_comment_enabled: null,
    is_campaign_enabled: null,
    is_circle_enabled: null,
    is_email_enabled: null,
    view_ai_generated_comment_only: null,
    view_sub_brand_ai_generated_comment: null,
    is_youtube_connect_enabled: null,
    is_political_engage_enabled: null,
    is_political_engage_kamala_harris_subbrand: null,
    is_zelf_tiktok_connect_enabled: null,
    is_news_feed_enabled: null,
    is_force_liked_enabled: null,
    is_ask_zelf_enabled: null
  }
  const getValues = (fieldName) => {
    return BRAND_FE_CONFIG?.[brand_id]?.[fieldName] ?? data?.me?.brand?.settings?.[fieldName]
  }
  for (let key in fields) {
    fields[key] = getValues(key)
  }

  return fields
}

function useClickAway(listener) {
  const ref = useRef()

  useEffect(() => {
    function handleClick(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        listener()
      }
    }

    document.addEventListener("mousedown", handleClick)
    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [listener])

  return ref
}

const useDataContextManyObjectType = ({ id, dataContextVariableName }) => {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const updateValue = (newValue, override = false) => {
    api?.common.updateManyObjectTypeInDataContent({ id, dataContextVariableName, newValue, override })
  }
  const deleteValue = () => {
    api?.common.updateManyObjectTypeInDataContent({
      id: id,
      dataContextVariableName: dataContextVariableName,
      deleteObj: true,
    })
  }
  return {
    updateValue,
    value: data?.[dataContextVariableName]?.[id],
    deleteValue,
  }
}

// deprecated, use useContentModificationHelper for new implementations
const useLikeContent = ({ content_id, content_platform, parent_comment }) => {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const toast = useToast()
  const [loader, setLoader] = useState(false)
  const { value: clientSideLikeValue, updateValue } = useDataContextManyObjectType({
    id: parent_comment ?? content_id,
    dataContextVariableName: parent_comment ? "clientSideCommentLike" : "clientSideLike",
  })

  const onLikeContent = async ({ is_like }) => {
    const id = content_id
    const otherPlatform = content_platform !== "tiktok"
    if (!id || otherPlatform) {
      toast(`Platform is not supported for ${typeof is_like === "boolean" ? "like" : "comment"}`)
      return
    }
    if (!data?.me?.brand?.is_tikapi_connected && !data?.me?.is_user_tikapi_connected) {
      toast("Need to connect your tiktok with zelf")
      api?.alert?.ConnectTiktokForLikeComment()
      return
    }
    if (typeof is_like === "boolean") {
      const payload = {
        content: id,
        platform: "tiktok",
      }
      if (parent_comment) {
        // delete payload.content
        payload.parent_comment = parent_comment
      }

      updateValue({ is_liked: is_like })
      setLoader(true)
      const res = await api?.brand.brandPostRequestHelper({
        section: parent_comment
          ? is_like
            ? "like_a_comment"
            : "unlike_a_comment"
          : is_like
            ? "like_content"
            : "unlike_content",
        payload: payload,
      })
      setLoader(false)
      if (!res) {
        toast("Something went wrong during like...", BanSolid)
        // dispatch({
        //     bd_sec_content_modal_stats_details:{
        //         ...data?.bd_sec_content_modal_stats_details,
        //         is_liked:!is_like
        //     }
        // })
        updateValue({ is_liked: !is_like })
      }

      return
    }
  }

  return {
    onLikeContent,
    clientSideLikeValue: clientSideLikeValue?.is_liked,
    loader,
  }
}

const useNavigateToCreatorProfile = () => {
  const navigate = useNavigate()
  const [_, dispatch] = useContext(dataContext)
  const toast = useToast()

  const navigateToCreator = (creator, sub_brand_id = null) => {
    const existingURLParams = new URLSearchParams(window.location.search)
    delete existingURLParams.delete("be_section_view_all")
    let _param = ""
    if (creator?.connected_accounts?.length) {
      // console.log(creator)
      for (let _creator of creator?.connected_accounts) {
        _param = combineURLSearchParams(
          new URLSearchParams({ username: _creator?.username, id: _creator?.id }),
          existingURLParams,
          true,
        ).toString()
        window.open("/onboarded_creator?" + _param, "")
        return
      }
    }
    if (Array.isArray(creator?.connected_accounts)) {
      toast(`The creator does not have any associated social media platforms linked to their Zelf profile`, BanSolid)
      return
    }
    const _param_object = { username: creator?.username, id: creator?.id }
    if (sub_brand_id) {
      _param_object.sub_brand_id = sub_brand_id
    }
    _param = combineURLSearchParams(existingURLParams, new URLSearchParams(_param_object), true)
    window.open(
      "/discovered_creator?" +
        combineURLSearchParams(existingURLParams, new URLSearchParams(_param_object), true).toString(),
      "",
    )
  }

  return navigateToCreator
}

const useAIComment = ({
  content,
  commentPayload,
  skip_auto_fetch_ai_comment,
  ai_generated_comment,
  skip_previous_comment,
  platform,
  skip_auto_generate = true,
}) => {
  const [currentClientComment, setCurrentClientComment] = useState("")
  const [selectedGeneratedComment, setSelectedGeneratedComment] = useState()
  const [data, dispatch] = useContext(dataContext)
  const { is_ai_comment_enabled } = useBrandConfig()
  const toast = useToast()
  const api = useContext(apiContext)
  const unique_content_id = content?.id
  const unique_comment_id = commentPayload?.id ? commentPayload?.id : ""
  const { value: __currentClientCommentObject, updateValue: __setCurrentClientCommentObject } =
    useDataContextManyObjectType({ id: unique_content_id, dataContextVariableName: "comment_generation_for_post" })
  const _currentClientComment = __currentClientCommentObject?.text ?? ""
  const _setCurrentClientComment = (value) => {
    // console.log(value,unique_content_id)
    __setCurrentClientCommentObject({ text: value })
  }
  const [pullStart, setPullStart] = useState(false)
  const {
    value: pullSetValue,
    updateValue: pullSetUpdate,
    deleteValue: pullSetDelete,
  } = useDataContextManyObjectType({
    id: unique_comment_id ? unique_comment_id : unique_content_id,
    dataContextVariableName: unique_comment_id ? "ai_reply_pull_set" : "ai_comment_pull_set",
  })
  const pullID = pullSetValue?.id
  const shouldPull = pullID && pullStart

  const isPoliticalEngageEnabled = data?.me?.brand?.settings?.is_political_engage_enabled

  const { loader: previous_comment_loader, payload: previous_comment_payload } = useBDBoundData({
    section: skip_previous_comment ? null : "content_modal_comments",
    skipBrandRefresh: true,
    skipDiscoveryPlatformRefresh: true,
    uniqueSectionPostfix: unique_content_id,
    queryFilter: `${isPoliticalEngageEnabled && data?.me ? "&user_id=" + data?.me?.id : ""}`,
  })
  const { loader: ai_previous_comment_loader, payload: ai_previous_comment_payload } = useBDBoundData({
    section: Boolean(ai_generated_comment) || skip_auto_fetch_ai_comment ? null : "get_ai_generated_comment_list",
    skipBrandRefresh: true,
    skipDiscoveryPlatformRefresh: true,
    uniqueSectionPostfix: unique_content_id,
    queryFilter: `${isPoliticalEngageEnabled && data?.me ? "&user_id=" + data?.me?.id : ""}`,
  })
  const {
    loader: ai_single_comment_loader,
    payload: ai_single_comment_payload,
    pollingStatus,
  } = useBDBoundData({
    section:
      shouldPull && pullID
        ? unique_comment_id
          ? "get_single_ai_generated_reply"
          : "get_single_ai_generated_comment"
        : "",
    polling: shouldPull && pullStart,
    skipBrandRefresh: true,
    skipDiscoveryPlatformRefresh: true,
    uniqueSectionPostfix: unique_comment_id ? pullID : unique_content_id,
  })
  const {
    loader: commentLoader,
    payload: commentPostedPayload,
    executeCall: postComment,
  } = useBDPostData({ section: "add_comment_content", uniqueSectionPostfix: unique_content_id })
  const {
    loader: ai_loader,
    payload: ai_comment_payload,
    executeCall: generateComment,
  } = useBDPostData({ section: "ai_generated_comment", uniqueSectionPostfix: unique_content_id })
  const {
    loader: update_content_loader,
    payload: updateContentBrandPayload,
    executeCall: updateContentBrand,
  } = useBDPostData({ section: "update_content_brand_unique", uniqueSectionPostfix: unique_content_id })
  const {
    loader: replyLoader,
    payload: replyPayload,
    executeCall: postReply,
  } = useBDPostData({ section: "add_comment_content", uniqueSectionPostfix: unique_comment_id })
  const id = content?.id
  const { value: clientPost, updateValue: updateClientPost } = useDataContextManyObjectType({
    id,
    dataContextVariableName: "ai_gen_post_comment_content",
  })
  const brand = data?.me?.brand
  const otherPlatform = platform !== "tiktok"

  const onGenerate = async ({ additionalPayload = {} }) => {
    setPullStart(false)
    // api.common.updateManyObjectTypeInDataContent({id:unique_content_id,dataContextVariableName:"ai_comment_pull_set",deleteObj:true})
    pullSetDelete()
    setSelectedGeneratedComment()
    if (!is_ai_comment_enabled) {
      toast("AI comment generation is disabled for this brand", BanSolid)
      return
    }
    const ai_generate_comment_payload = {
      content_id: id,
      content_platform: platform,
      brand_id: data?.sub_brand_id ? Number(data?.sub_brand_id) : brand?.id,
      ...additionalPayload,
    }
    if (unique_comment_id) {
      ai_generate_comment_payload.parent_comment_id = unique_comment_id
    }

    const res = await generateComment(ai_generate_comment_payload)
    if (res) {
      setPullStart(true)
      pullSetUpdate(res)
      // api.common.updateManyObjectTypeInDataContent({id:unique_content_id,dataContextVariableName:"ai_comment_pull_set",newValue:res})
    }

    if (res?.comment) {
      setCurrentClientComment(res.comment)
    }
  }
  const archiveContent = async () => {
    const newValue = {
      is_archived_from_ai_comment: true,
    }

    if (data?.sub_brand_id) {
      newValue.brand_id = data?.sub_brand_id
    }
    updateClientPost(newValue)
    const res = updateContentBrand(newValue)
    if (!res) {
      toast("Something went wrong")
      updateClientPost({ is_archived_from_ai_comment: false })
      return
    }
    toast("Content moved to archive")
  }
  const allLoading =
    ai_previous_comment_loader ||
    ai_single_comment_loader ||
    ai_loader ||
    pollingStatus == "run" ||
    update_content_loader ||
    replyLoader ||
    commentLoader
  useEffect(() => {
    setCurrentClientComment("")
    api?.brand?.hardRefreshDashboardSections({ sectionsArray: ["content_modal_comments"] })
  }, [unique_content_id])

  useEffect(() => {
    if (ai_previous_comment_payload?.length === 0 && !allLoading) {
      if (skip_auto_generate || currentClientComment) return
      onGenerate({})

      return
    }
    if (!ai_previous_comment_payload?.length) {
      return
    }
    const comment = ai_previous_comment_payload.findLast((x) => x.comment && x.content === id && !x.is_reject)

    if (comment) {
      setSelectedGeneratedComment(comment)
      setCurrentClientComment(comment?.comment)
      return
    }
    // onGenerate()
  }, [ai_previous_comment_payload, data?.ecosystem_selected_content?.content?.id])

  useEffect(() => {
    if (ai_generated_comment) {
      setCurrentClientComment(ai_generated_comment)
    }
  }, [ai_generated_comment])

  useEffect(() => {
    if (!ai_single_comment_payload?.comment) return

    if (ai_single_comment_payload?.id === pullID) {
      if (ai_single_comment_payload?.comment !== currentClientComment) {
        setSelectedGeneratedComment(ai_single_comment_payload)
        setCurrentClientComment(ai_single_comment_payload?.comment)
        _setCurrentClientComment(ai_single_comment_payload?.comment)
        setPullStart(false)
      }
    }
  }, [pullID, ai_single_comment_payload?.comment])

  useEffect(() => {
    if (unique_comment_id) return
    if (!_currentClientComment) return
    if (_currentClientComment == currentClientComment) return
    setCurrentClientComment(_currentClientComment)
  }, [_currentClientComment])

  return {
    currentClientComment,
    setCurrentClientComment,
    pullStart,
    setPullStart,
    shouldPull,
    pullID,
    previous_comment_loader,
    previous_comment_payload,
    ai_previous_comment_loader,
    ai_previous_comment_payload,
    ai_single_comment_loader,
    ai_single_comment_payload,
    postComment,
    ai_loader,
    ai_comment_payload,
    onGenerate,
    unique_content_id,
    pollingStatus,
    generateComment,
    allLoading,
    selectedGeneratedComment,
    archiveContent,
    postReply,
    replyLoader,
    replyPayload,
  }
}

const useCopyToClipBoard = () => {
  const toast = useToast()
  const copyToClipboard = (copyableText) => {
    console.log("copying,", copyableText)
    clipboard
      .write(copyableText)
      .then((status) => {
        toast("Copied to clipboard")
      })
      .catch((e) => {
        toast("Error: can't accessing clipboard", BanSolid)
        console.error(`Error accessing clipboard`)
      })
  }

  return {
    copyToClipboard,
  }
}

const useCampaignHelpers = () => {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const [searchParams, setSearchParams] = useSearchParams()

  const clearCampaign = () => {
    const params = new URLSearchParams(searchParams)
    params.delete("selected_campaign_for_viewing_id")
    params.delete("selected_campaign_for_viewing_name")
    setSearchParams(params)
    dispatch({ selected_campaign_for_viewing: null })
  }

  const setCampaignQueryParam = (campaign) => {
    setSearchParams({
      selected_campaign_for_viewing_id: campaign?.id,
      selected_campaign_for_viewing_name: campaign?.name,
    })
  }

  return { clearCampaign, setCampaignQueryParam }
}

const useSetSubBrandID = () => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [data, dispatch] = useContext(dataContext)
  const setSubBrandId = (id, remove = false, removeOtherValues = []) => {
    const newSearchParams = new URLSearchParams(searchParams)
    if (remove) {
      newSearchParams.delete("sub_brand_id")
      dispatch({ sub_brand_id: null })
    } else {
      dispatch({ sub_brand_id: id ? +id : null })
      newSearchParams.set("sub_brand_id", id)
    }
    removeOtherValues?.map((x) => newSearchParams.delete(x))
    setSearchParams(newSearchParams)
  }
  return { setSubBrandId }
}

const useSelfServerSearchSettingsHelper = () => {
  const [data, dispatch] = useContext(dataContext)
  const toggleShowSettings = () => {
    dispatch({
      show_self_serve_brand_settings: !data?.show_self_serve_brand_settings,
    })
  }

  return {
    toggleShowSettings,
  }
}

const useCommentRestrictionAndStats = () => {
  const [restriction, setRestrictionAtom] = useAtom(restrictionAtom)
  const [commentEngageStats, setCommentEngageStats] = useAtom(engageStatsAtom)
  const [dailyStats, setDailyStats] = useAtom(dailyStatsAtom)
  const server = useServer()
  const toast = useToast()

  const fetchRestriction = async () => {
    return await server.get(GET_CALLS.comment_restrictions.endpoint)
  }

  const syncRestriction = async () => {
    const res = await fetchRestriction()
    setRestrictionAtom(res)
  }

  const syncDailyStats = async () => {
    try {
      const res = await server.get(GET_CALLS.brand_daily_comment_stats.endpoint)
      if (res) {
        setDailyStats(res)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return {
    restriction,
    isRestricted: !_empty(restriction),
    setRestrictionAtom,
    syncRestriction,
    commentEngageStats,
    setCommentEngageStats,
    syncDailyStats,
    dailyStats,
    setDailyStats,
  }
}

const useCountdown = (targetDate, nowTimestamp = null) => {
  const calculateTimeLeft = () => {
    const now = nowTimestamp ? dayjs(nowTimestamp) : dayjs()
    const then = dayjs(targetDate)

    if (now.isAfter(then)) {
      return null // Timer is expired
    }

    const diff = then.diff(now)
    const timeLeft = dayjs.duration(diff)

    return {
      days: Math.floor(timeLeft.asDays()),
      hours: timeLeft.hours(),
      minutes: timeLeft.minutes(),
      seconds: timeLeft.seconds(),
    }
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    if (!timeLeft) return // If the time is expired, do nothing

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearInterval(timer)
  }, [timeLeft, nowTimestamp])

  return timeLeft
}

const useSearchParamsHelper = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const addQueryParams = (params) => {
    const newSearchParams = new URLSearchParams(searchParams)
    Object.keys(params).forEach((key) => {
      newSearchParams.set(key, params[key])
    })
    setSearchParams(newSearchParams)
  }

  const removeQueryParams = (keys) => {
    const newSearchParams = new URLSearchParams(searchParams)
    keys.forEach((key) => {
      newSearchParams.delete(key)
    })
    setSearchParams(newSearchParams)
  }

  const replaceQueryParams = (params) => {
    const newSearchParams = new URLSearchParams()
    Object.keys(params).forEach((key) => {
      newSearchParams.set(key, params[key])
    })
    setSearchParams(newSearchParams)
  }

  return {
    searchParams,
    addQueryParams,
    removeQueryParams,
    replaceQueryParams,
  }
}

const useClientSideContent = (id) => {
  if (!id) {
    throw new Error("id is required")
  }
  const [values, setValues] = useAtom(clientSideContents(id))
  return {
    clientContent: values,
    dispatchClientContent: setValues,
  }
}

const useGeneratedComment = ({ content, autoFetchPreviousAIComment = false }) => {
  const { clientContent, dispatchClientContent } = useClientSideContent(content?.id)
  const [selectedGeneratedComment, setSelectedGeneratedComment] = useState()
  const toast = useToast()
  const { is_ai_comment_enabled } = useBrandConfig()
  const [pullEndpoint, setPullEndpoint] = useState("")
  const attemptRef = useRef(0)
  const server = useServer()
  const { payload: previousComments, loader: previousCommentLoader } = useLocalBoundData({
    ...GET_CALLS.get_ai_generated_comment_list,
    endpoint: `/AIGeneratedComments/?content_id=${content?.id}`,
    skip: !content?.id || !autoFetchPreviousAIComment,
  })
  const { executeCall: generateComment, loader: generateCommentLoader } = useLocalDataMutation({
    ...MUTATION_CALLS.generate_ai_comment,
  })
  let comment = clientContent?.comment
  let setComment = (comment) => {
    dispatchClientContent({ comment })
  }

  const onGenerate = async ({ prompt = null, parent_ai_comment_id = null } = {}) => {
    attemptRef.current = 0
    if (!is_ai_comment_enabled) {
      toast("AI comment generation is disabled for this brand", BanSolid)
      return
    }
    const payload = {
      content_id: content?.id,
      content_platform: content?.content_platform,
      brand_id: content?.brand_id,
    }
    if (prompt) {
      payload.prompt = prompt
    }
    if (parent_ai_comment_id) {
      payload.parent_ai_comment_id = parent_ai_comment_id
    }

    console.log(payload)

    const res = await generateComment(payload)
    if (!res) {
      toast("Something went wrong while generating content", BanSolid)
    }
    if (res) {
      setPullEndpoint(`/AIGeneratedComments/${res?.id}/`)
    }
    // toast("call generate function")
  }
  useEffect(() => {
    if (previousCommentLoader) return
    if (!previousComments) return
    if (!previousComments?.length) return
    const _comment = previousComments.findLast((x) => x.comment && !x.is_reject)
    console.log(_comment)
    setSelectedGeneratedComment(_comment)
    if (comment === _comment?.comment) {
      return
    }
    setComment(_comment?.comment)
  }, [previousComments, previousCommentLoader])

  useEffect(() => {
    let interval
    const pull = async (resource) => {
      if (attemptRef.current >= 7) {
        setPullEndpoint("")
        attemptRef.current = 0
        return
      }
      const res = await server.get(resource)

      if (!res?.comment) {
        attemptRef.current = attemptRef.current + 1
        console.log(`pulling ${resource} ${attemptRef.current}`)
        return
      }

      setPullEndpoint("")
      attemptRef.current = 0
      setComment(res?.comment)
    }

    if (pullEndpoint) {
      interval = setInterval(() => {
        pull(pullEndpoint)
      }, 2000)
    } else {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [pullEndpoint])
  return {
    selectedGeneratedComment,
    onGenerate,
    generateLoader: !!pullEndpoint || generateCommentLoader,
  }
}

function useContentModificationHelper({ content, reply }) {
  const [data] = useContext(dataContext)
  const [, addArchive] = useAtom(addArchiveFromAICommentIDAtom)
  const [, removeArchive] = useAtom(removeArchiveFromAICommentIDAtom)
  const { syncRestriction, setDailyStats } = useCommentRestrictionAndStats()
  const { clientContent, dispatchClientContent } = useClientSideContent(content?.id)
  const toast = useToast()
  const {
    executeCall: postComment,
    loader: postCommentLoader,
    error: postCommentError,
  } = useLocalDataMutation({
    ...MUTATION_CALLS.add_content_comment,
    setLocalError: true,
  })
  const { executeCall: likeContent, loader: likeLoader } = useLocalDataMutation(MUTATION_CALLS.like_content)
  const { executeCall: unLikeContent, loader: unlikeLoader } = useLocalDataMutation(MUTATION_CALLS.unlike_content)
  const { executeCall: updateBrandContent } = useLocalDataMutation({
    ...MUTATION_CALLS?.update_content_brand,
    endpoint: `Content/${content?.id}/ContentBrand/`,
    sub_brand_id: content?.brand_id,
    throwOriginalError: true,
  })
  const nps_score = clientContent?.nps_score !== undefined ? clientContent?.nps_score : content?.nps_score
  const zelfTiktokConnected = (data?.me?.brand?.connected_social_accounts ?? []).some(
    (x) => x.platform === "tiktok" && x.auth_state === "SUCCESS" && x.is_healthy,
  )
  const zelfYoutubeConnected = (data?.me?.brand?.connected_social_accounts ?? []).some(
    (x) => x.platform === "youtube" && x.auth_state === "SUCCESS" && x.is_healthy,
  )
  const isAnyTiktokConnected =
    data?.me?.is_user_tikapi_connected || data?.me?.brand?.is_tikapi_connected || zelfTiktokConnected
  let posting_state = clientContent?.posting_state
  let comment = clientContent?.comment
  let is_archived_from_ai_comment = clientContent?.is_archived_from_ai_comment
  let likeStatus = clientContent?.like_status ?? content?.like_status

  const setComment = (c) => {
    dispatchClientContent({
      comment: c,
    })
  }

  const postCurrentComment = async () => {
    if (content?.content_platform === "tiktok") {
      if (!isAnyTiktokConnected) {
        // addToast({ title: "Please connect your TikTok with Zelf." })
        toast(`Please connect your TikTok with Zelf.`)
        return
      }
    }

    dispatchClientContent({ error: null })
    const _payload = {
      text: comment,
      content: content?.id,
      platform: content?.content_platform,
      brand_id: content?.brand_id,
    }
    if (reply?.id) {
      _payload.parent_comment = reply?.id
    }
    const response = await postComment(_payload)
    if (!response?.id || postCommentError) {
      toast(`Something went wrong while approving the comment`, BanSolid)
      console.log("caught an error", postCommentError)
      if (reply?.id) {
        dispatchClientContent({ replyError: postCommentError })
        return
      }
      dispatchClientContent({ error: postCommentError })
      syncRestriction()
      return
    }

    if (!reply?.id) {
      dispatchClientContent({
        posting_state: response?.posting_state,
        lastSuccessfulComment: comment,
      })
    }
    setDailyStats({
      comment_status_count: response?.comment_status_count,
      comment_restriction: response?.comment_restriction,
    })
    if (response?.id) {
      toast("Successfully Approved.")
    }
    setComment("")
  }

  const onLike = async () => {
    if (!isAnyTiktokConnected) {
      // addToast({ title: "Please connect your TikTok with Zelf." })
      return
    }

    const payload = {
      content: content?.id,
      platform: content?.content_platform,
    }
    dispatchClientContent({ like_status: "IN_QUEUE" })
    const call = likeStatus == "POSTED" ? unLikeContent : likeContent
    const res = await call(payload)
    if (!res) {
      dispatchClientContent({ like_status: null })
      syncRestriction()
    } else {
      dispatchClientContent({ like_status: res?.like_status })
    }
  }

  const onArchive = async (is_archived_from_ai_comment = true) => {
    dispatchClientContent({ is_archived_from_ai_comment })
    const _id = content?.id
    addArchive(_id)

    const res = await updateBrandContent({
      is_archived_from_ai_comment,
    })
    if (res && is_archived_from_ai_comment) {
      toast("Successfully Archived")
    }
    if (!res) {
      removeArchive(_id)
      toast("Something went wrong while archiving...")
    }
  }

  const updateNPSScore = async (new_nps_score) => {
    const old_nps = nps_score
    dispatchClientContent({
      nps_score: new_nps_score,
    })
    const res = await updateBrandContent({
      nps_score: new_nps_score,
    })
    if (!res) {
      toast("Something went wrong while updating the nps...")
      dispatchClientContent({
        nps_score: old_nps,
      })
    }
  }

  useEffect(() => {
    if (comment) return
    if (content?.ai_generated_comment) {
      setComment(content?.ai_generated_comment)
    }
  }, [])

  useEffect(() => {
    if (content?.posting_state && !posting_state) {
      dispatchClientContent({
        posting_state: content?.posting_state,
      })
    }
  }, [])

  // useEffect(() => {
  //   dispatchClientContent({
  //     like_status: content?.like_status,
  //   })
  // }, [])

  return {
    comment,
    setComment,
    postCurrentComment,
    posting_state,
    postCommentLoader,
    is_archived_from_ai_comment,
    currentLikeStatus: likeStatus,
    onLike,
    likeLoader,
    onArchive,
    nps_score,
    isAnyTiktokConnected,
    updateNPSScore,
    posted_comment: clientContent?.lastSuccessfulComment
      ? clientContent?.lastSuccessfulComment
      : content?.posted_comment,
  }
}

export {
  useDebounce,
  useCommonBESectionFeatures,
  useBrandConfig,
  useClickAway,
  useDataContextManyObjectType,
  useLikeContent,
  useNavigateToCreatorProfile,
  useAIComment,
  useCopyToClipBoard,
  useCampaignHelpers,
  useSetSubBrandID,
  useSelfServerSearchSettingsHelper,
  useCommentRestrictionAndStats,
  useCountdown,
  useSearchParamsHelper,
  useContentModificationHelper,
  useGeneratedComment,
}
