import { Modal } from "./modal"
import { Button } from "../button"
import { useContext, useEffect, useRef, useState } from "react"
import { Steps } from "../dumbs"
import QRCODEPLACEHOLDER from "../../assets/qr_code_placeholder.svg"
import { Input, OtpInput } from "../input"
import { useLocalBoundData, useLocalDataMutation } from "../../hooks/useBoundData"
import { GET_CALLS, MUTATION_CALLS } from "../../consts"
import { Loader } from "../loaders"
import { useToast } from "../../hooks/useToast"
import { LeftThinArrow } from "../Icons"
import { useServer } from "../../hooks/useServer"
import { Select } from "../select"
import { add, set } from "lodash"
import { ChipWithDelete } from "../structural/BrandEcosystem/selfServeSearchSettings"
import { apiContext } from "../../contexts/apicontext"
import { dataContext } from "../../contexts/datacontext"

let steps = [
  <span className="body-regular-font">
    {" "}
    <span className="body-highlight-font"> Open TikTok </span>on your smartphone and log into your account.
  </span>,
  <span className="body-regular-font">
    {" "}
    <span className="body-highlight-font"> Scan the QR </span>Code using your phone and tap the link that pops up.
  </span>,
]
const QRCodeLogin = () => {
  const [isActive, setIsActive] = useState(false) // Initially false
  const [timeLeft, setTimeLeft] = useState(55) // Timer starts at 55 seconds
  const [isSuccess, setIsSuccess] = useState(false)
  const [pullingEndpointUrl, setPullEndpointUrl] = useState(null)
  const {} = useLocalDataMutation({ endpoint: "", skip: true })
  const server = useServer()
  const {
    executeCall: qrCodeConnect,
    payload,
    loader: genQrLoading,
  } = useLocalDataMutation(MUTATION_CALLS.connect_new_social)

  const onGenerateQrCode = async () => {
    // setIsActive(true)
    const res = await qrCodeConnect({
      platform: "tiktok",
      authorization_type: "qr",
      code: "whatever",
    })
    if (res) {
      console.log(res)
      setIsActive(true)
      setPullEndpointUrl(`/Social/platform/${res?.id}/`)
    }
  }

  useEffect(() => {
    let timerInterval
    if (isActive) {
      setTimeLeft(55)

      timerInterval = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev > 1) {
            const new_time = prev - 1
            return new_time
          } else {
            setIsActive(false)
            clearInterval(timerInterval)
            return 0
          }
        })
      }, 1000)
    }

    // Cleanup on component unmount or `isActive` changes
    return () => clearInterval(timerInterval)
  }, [isActive])

  useEffect(() => {
    if (!isActive) return
    if (!timeLeft) return
    if (!pullingEndpointUrl) return
    if (timeLeft % 5 !== 0) return
    ;(async () => {
      const res = await server.get(pullingEndpointUrl)
      if (res?.is_healthy) {
        setIsSuccess(true)
      }
    })()
  }, [timeLeft, pullingEndpointUrl])

  if (isSuccess) {
    return <div className="margin-x-4x margin-y-4x  flex">Connection Successful</div>
  }

  return (
    <div className="margin-x-4x margin-y-4x  flex">
      <div className="full padding-3x">
        <p className="body-regular-font margin-bottom-3x">
          To connect you TikTok using QR Code, follow the following steps:
        </p>
        <div className="">
          {steps.map((text, i) => (
            <Steps key={i} className="margin-bottom-4x" number={i + 1} text={text} />
          ))}
        </div>
      </div>
      <div className="full flex a-center j-center">
        {isActive ? (
          <div>
            {payload?.authorization_details?.qr_code ? (
              <img style={{ width: 200 }} src={payload?.authorization_details?.qr_code ?? QRCODEPLACEHOLDER} />
            ) : null}
            <br />
            {isActive && <span>Time left: {timeLeft} seconds</span>}
          </div>
        ) : (
          <div className="position-relative">
            <img style={{ filter: "blur(2px)" }} src={QRCODEPLACEHOLDER} width={200} />
            <Button
              type="tertiary"
              onClick={onGenerateQrCode}
              disabled={genQrLoading}
              className="position-absolute old-center-trick"
              style={{ minWidth: "max-content" }}
            >
              {" "}
              Show QRCode {genQrLoading ? <Loader></Loader> : null}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const PasswordLogin = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [errorText, setErrorText] = useState("")
  const {
    executeCall: sendToBE,
    payload: socialConnectPayload,
    loader: loaderPasswordLogin,
  } = useLocalDataMutation(MUTATION_CALLS.connect_new_social)
  const { executeCall: multifactorAuthentication, loader: loaderMultiFactor } = useLocalDataMutation(
    MUTATION_CALLS.multi_factor_auth,
  )
  const [isSuccess, setIsSuccess] = useState(false)
  const [showMultiFactor, setShowMultiFactor] = useState(false)
  const [connectID, setConnectID] = useState()
  const [OPTinput, setOTPInput] = useState("")
  const server = useServer()
  const toast = useToast()
  const attemptRef = useRef(0)

  const onConnectClick = async () => {
    attemptRef.current = 0
    if (!username || !password) {
      setErrorText("Username or password can't be empty")
      return
    }

    const res = await sendToBE({
      platform: "tiktok",
      username: username,
      password: password,
      authorization_type: "username_password",
    })
    if (res) {
      setErrorText("")
      // setIsSuccess(true)
      setConnectID(res?.id)
      if (res?.auth_state === "TWO_FA_WAITING") {
        setShowMultiFactor(true)
        return
      }
      if (res?.auth_state === "SUCCESS") {
        setIsSuccess(true)
      }
    } else {
      setConnectID("")
    }
  }

  const sendMultifactor = async () => {
    setErrorText("")
    if (!OPTinput) {
      setErrorText("Code can't be empty")
      return
    }
    if (!connectID) {
      setShowMultiFactor(false)
      return
    }
    const res = await multifactorAuthentication({
      social_platform_id: connectID,
      multi_factor_code: OtpInput,
    })
    if (!res) {
      setErrorText("Something went wrong ...")
      return
    }

    if (res?.auth_state === "SUCCESS") {
      setIsSuccess(true)
    }
  }

  useEffect(() => {
    if (!connectID) {
      return
    }
    if (showMultiFactor) {
      return
    }
    if (isSuccess) {
      return
    }
    let interval
    const pull = async (resource) => {
      if (attemptRef.current >= 60) {
        setConnectID("")
        attemptRef.current = 0
        return
      }
      if (showMultiFactor) return
      const res = await server.get(resource)

      if (res?.auth_state !== "TWO_FA_WAITING" || res?.auth_state !== "SUCCESS") {
        attemptRef.current = attemptRef.current + 1
        console.log(`pulling ${resource} ${attemptRef.current}`)
        return
      }
      if (res?.auth_state === "TWO_FA_WAITING") {
        attemptRef.current = 0
        setShowMultiFactor(true)
        // setConnectID("")
      }
      if (res?.auth_state === "SUCCESS") {
        setIsSuccess(true)
      }
    }

    if (connectID) {
      interval = setInterval(() => {
        pull(`/Social/platform/${connectID}/`)
      }, 2000)
    } else {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [connectID, showMultiFactor, isSuccess])

  if (isSuccess) {
    return <div className="margin-x-4x margin-y-4x  flex">Connection Successful</div>
  }

  return (
    <div className="padding-y-10x padding-x-15x">
      {showMultiFactor ? (
        <>
          <div>
            <div className="max-width-290 margin-x-auto">
              <p className="txt-center button-medium-font nc30-fg margin-bottom-5x">
                A verification code has been sent to
                <br />
                your [email/phone]
              </p>
              <p className="button-medium-font margin-bottom-2x"> Enter Verification Code</p>
              <OtpInput onChange={(v) => setOTPInput(v)} />
              {!!errorText && <p className="caption margin-bottom-3x negative-fg">{errorText}</p>}
              <Button onClick={sendMultifactor} fluid className="margin-top-5x" disabled={loaderMultiFactor}>
                Submit Code {loaderMultiFactor ? <Loader></Loader> : null}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <h5 className="button-medium-font nc100-fg margin-bottom-2x">Email or Username</h5>
          <Input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="margin-bottom-3x"
            placeholder={"Enter your email address"}
          />
          <h5 className="button-medium-font nc100-fg margin-bottom-2x">Password</h5>
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            className="margin-bottom-3x"
            placeholder={"Enter your Password"}
          />
          {!!errorText && <p className="caption margin-bottom-3x negative-fg">{errorText}</p>}
          <Button onClick={onConnectClick} disabled={loaderPasswordLogin} fluid>
            Connect {loaderPasswordLogin || !!connectID ? <Loader></Loader> : null}
          </Button>
        </>
      )}
    </div>
  )
}

const TikTokConnectWithZelf = ({ triggerElement }) => {
  const [isQRTabSelected, setIsQRTabSelected] = useState(true)
  return (
    <Modal>
      <Modal.Button asChild>
        {triggerElement}
        {/* <Button >Connect Tiktok via Zelf</Button> */}
      </Modal.Button>
      <Modal.Content
        overlayClassName="backdrop-blur-4"
        title="Connect Tiktok With Zelf"
        contentBodyWidthClassName="dialog-max-width-690"
      >
        <div className="">
          <div className="flex">
            <div
              className={`txt-center cursor-pointer full padding-y-3x headline-small-highlight border-bottom-2 ${isQRTabSelected ? "primary-border" : "transparent-border"} `}
              onClick={() => setIsQRTabSelected(true)}
            >
              QR Code
            </div>
            {/* <div
              className={`txt-center cursor-pointer full padding-y-3x headline-small-highlight border-bottom-2 ${!isQRTabSelected ? "primary-border" : "transparent-border"}`}
              onClick={() => setIsQRTabSelected(false)}
            >
              Login Credential
            </div> */}
          </div>
          <hr className="hr nc10-border"></hr>

          {isQRTabSelected ? <QRCodeLogin /> : <PasswordLogin />}
          <hr className="hr nc10-border"></hr>
          <div className="flex padding-y-3x padding-x-5x">
            <Modal.Close asChild>
              <Button className="secondary margin-left-auto">Dismiss</Button>
            </Modal.Close>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

const CompetitorAddDialog = ({ triggerElement }) => {
  const [isOpened, setIsOpened] = useState(false)
  const [isManual, setIsManual] = useState(false)
  const [searchTermInput, setSearchTermInput] = useState("")
  const [searchTerm, setSearchTerm] = useState([])
  const [selectedIndustry, setSelectedIndustry] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedCompetitor, setSelectedCompetitor] = useState(null)
  const [typedCompetitor, setTypedCompetitor] = useState("") // For manual competitor

  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const toast = useToast()

  const { payload, loader } = useLocalBoundData({
    ...GET_CALLS.get_brand_catalogs,
  })
  const { executeCall: addCompetitor, loader: addLoader } = useLocalDataMutation(MUTATION_CALLS.create_new_brand)

  const addedCompetitors = data?.me?.brand?.sub_brands
    .filter((x) => x.sync_from_brand_id)
    .map((x) => x.sync_from_brand_id)

  const handleSearchTerm = (e) => {
    if (e.key === "Enter") {
      // check duplicate
      if (searchTerm.includes(searchTermInput)) {
        return
      }
      setSearchTerm((prev) => [...prev, searchTermInput])
      setSearchTermInput("")
    }
  }

  const handleAddCompetitor = () => {
    // {
    //   "title": "CocaCola",
    //   "is_sub_brand": true,
    //   "sub_brand_type": 1,
    //   "brandtags": {
    //       "hashtags": ["a", "b", "c"],
    //       "search_terms": ["c", "d", "e"],
    //       "is_search_inclusion": true
    //   }
    //   "sync_from_brand_id": (integer | null),  // Optional, must be a valid Brand ID with brand_type=2 (GOD_VIEW)
    //   "industry_name": (string),   // Optional, cannot be blank if provided
    //   "industry_id": (string),     // Optional, cannot be blank if provided
    //   "category_name": (string),   // Optional, cannot be blank if provided
    //   "category_id": (string)
    // }

    if ((isManual && (!typedCompetitor || !searchTerm.length)) || (!isManual && !selectedCompetitor)) {
      return
    }

    let postPayload = {
      is_sub_brand: true,
      sub_brand_type: 1,
      category_id: selectedCategory,
      industry_id: selectedIndustry,
      category_name: payload
        .find((x) => x.industry_id === selectedIndustry)
        ?.categories?.find((x) => x.category_id == selectedCategory)?.category_name,
      industry_name: payload.find((x) => x.industry_id == selectedIndustry)?.industry_name,
      name: isManual
        ? typedCompetitor
        : payload
            .find((x) => x.industry_id == selectedIndustry)
            ?.categories?.find((x) => x.category_id == selectedCategory)
            ?.brands?.find((x) => x.brand_id == selectedCompetitor)?.brand_name,
      title: isManual
        ? typedCompetitor
        : payload
            .find((x) => x.industry_id == selectedIndustry)
            ?.categories?.find((x) => x.category_id == selectedCategory)
            ?.brands?.find((x) => x.brand_id == selectedCompetitor)?.brand_title,
    }
    if (isManual) {
      postPayload.brand_tags = {
        search_terms: searchTerm,
        is_search_inclusion: true,
      }
      // delete category and industry
      delete postPayload.category_id
      delete postPayload.category_name
      delete postPayload.industry_id
      delete postPayload.industry_name
    } else {
      postPayload.sync_from_brand_id = parseInt(selectedCompetitor)
    }

    addCompetitor(postPayload)
      .then((res) => {
        if (res) {
          api?.common?.me()
          toast("Competitor added successfully")
          reset()
          setIsOpened(false)
        }
      })
      .catch((e) => {
        toast("Something went wrong")
      })
  }
  const reset = () => {
    setSearchTerm([])
    setSearchTermInput("")
    setSelectedCategory(null)
    setSelectedCompetitor(null)
    setSelectedIndustry(null)
    setIsManual(false)
    setTypedCompetitor("")
  }

  useEffect(() => {
    if (isOpened) {
      reset()
    }
  }, [isOpened])

  return (
    <Modal open={isOpened} onOpenChange={setIsOpened}>
      <Modal.Button asChild>{triggerElement}</Modal.Button>
      <Modal.Content
        title="Add a competitor"
        overlayClassName="backdrop-blur-4"
        contentBodyWidthClassName="dialog-max-width-500"
      >
        <div className="padding-y-10x padding-x-8x">
          <h5 className="button-medium-font nc100-fg margin-bottom-2x">Select Industry</h5>
          <Select
            className="margin-bottom-3x"
            placeholder={loader ? "Loading Industries.." : "Which industry does the brand belong to?"}
            value={selectedIndustry}
            onChange={(e) => {
              setSelectedIndustry(e.target.value)
              setSelectedCategory(null)
            }}
            disabled={loader}
          >
            <option value={""}>Select Industry</option>
            {payload?.map((x, i) => (
              <option key={i} value={x.industry_id}>
                {x.industry_name}
              </option>
            ))}
          </Select>
          <h5 className="button-medium-font nc100-fg margin-bottom-2x">Select category</h5>
          <Select
            className="margin-bottom-3x"
            placeholder={loader ? "Loading Categories.." : "Which industry does the brand belong to?"}
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value)
              setSelectedCompetitor(null)
            }}
            disabled={loader || !selectedIndustry}
          >
            <option value={""}>Select Category</option>
            {payload
              ?.find((x) => x.industry_id === selectedIndustry)
              ?.categories?.map((x, i) => (
                <option key={i} value={x.category_id}>
                  {x.category_name}
                </option>
              ))}
          </Select>
          {!isManual ? (
            <>
              <h5 className="button-medium-font nc100-fg margin-bottom-2x">Select competitor</h5>
              <Select
                className="margin-bottom"
                placeholder={"Select Competitor"}
                value={selectedCompetitor}
                onChange={(e) => {
                  setSelectedCompetitor(e.target.value)
                  console.log(e.target.value)
                }}
                disabled={loader || !selectedCategory}
              >
                <option value={""}>Select Competitor</option>
                {payload
                  ?.find((x) => x.industry_id === selectedIndustry)
                  ?.categories?.find((x) => x.category_id === selectedCategory)
                  ?.brands.filter((x) => !addedCompetitors.includes(x.brand_id))
                  ?.map((x, i) => (
                    <option key={i} value={x.brand_id}>
                      {x.brand_title}
                    </option>
                  ))}
              </Select>
              <div className="button-small-font">
                Can't find brand?{" "}
                <span onClick={() => setIsManual(true)} className="cursor-pointer primary-fg">
                  Add Manually
                </span>
              </div>
            </>
          ) : (
            <>
              <h5 className="button-medium-font nc100-fg margin-bottom-2x flex a-start gap-2">
                <span className="cursor-pointer" onClick={() => setIsManual(false)}>
                  {LeftThinArrow}
                </span>
                Add brand manually
              </h5>
              <Input
                className="margin-bottom-3x"
                placeholder={"Your competitor name..."}
                value={typedCompetitor}
                onChange={(e) => setTypedCompetitor(e.target.value)}
              />
              <h4 className="button-medium-font nc100-fg margin-bottom-2x">Search terms</h4>
              <Input
                value={searchTermInput}
                onKeyDown={handleSearchTerm}
                onChange={(e) => setSearchTermInput(e.target.value)}
                placeholder={`type and hit enter to add terms...`}
                className="margin-bottom-4x"
              />
              <div className="flex wrapped">
                {searchTerm.map((x, i) => (
                  <ChipWithDelete
                    // hideRemoveIcon={readOnly}
                    key={i}
                    title={`#${x}`}
                    onRemove={() => setSearchTerm((prev) => prev.filter((y) => y !== x))}
                  />
                ))}
              </div>
            </>
          )}
          <div className="flex j-between margin-top-4x gap-4">
            <div className="button-medium-font margin-right-2x nc30-fg">
              It might take up to five minutes to reflect the changes
            </div>
            <Button onClick={handleAddCompetitor} disabled={addLoader} className="flex-shrink-0">
              {addLoader ? <Loader></Loader> : "Add competitor"}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export { TikTokConnectWithZelf, CompetitorAddDialog }
