import { atom, useAtom } from "jotai"
import { atomFamily } from "jotai/utils"
import { useEffect } from "react"

const engageStatsAtom = atom(null)
const dailyStatsAtom = atom(null)
const restrictionAtom = atom(false)
const localBoundDataHelperAtom = atom({})
const clientSideContent = atom({})
const archiveFromAICommentIDSetAtom = atom(new Set())

// Action atoms to modify the Set
const addArchiveFromAICommentIDAtom = atom(null, (get, set, id) => {
  const newSet = new Set(get(archiveFromAICommentIDSetAtom))
  newSet.add(id)
  set(archiveFromAICommentIDSetAtom, newSet)
})

const removeArchiveFromAICommentIDAtom = atom(null, (get, set, id) => {
  const newSet = new Set(get(archiveFromAICommentIDSetAtom))
  newSet.delete(id)
  set(archiveFromAICommentIDSetAtom, newSet)
})

const contentsAtom = atom({})
const clientSideContents = atomFamily((id) =>
  atom(
    (get) => get(contentsAtom)[id] || {},
    (get, set, update) => {
      set(contentsAtom, (prev) => ({
        ...prev,
        [id]: {
          ...(prev[id] || {}),
          ...update,
        },
      }))
    },
  ),
)

const useLogAtoms = (atoms) => {
  const atomEntries = Object.entries(atoms).map(([name, atom]) => [name, useAtom(atom)])

  useEffect(() => {
    if (window.__ALLOW_PROD_JOTAI_LOGGING) {
      console.group("Logging atoms")
      atomEntries.forEach(([name, [value]]) => {
        console.log(`${name}:`, value)
      })
      console.groupEnd()
    }
  }, [atomEntries, window.__ALLOW_PROD_JOTAI_LOGGING])
  useEffect(() => {
    if (window.__ALLOW_PROD_JOTAI_LOGGING) {
      console.log("now prod debug is enabled")
    }
  }, [window.__ALLOW_PROD_JOTAI_LOGGING])
}

const AtomLogger = () => {
  useLogAtoms({
    engageStatsAtom,
    dailyStatsAtom,
    restrictionAtom,
    localBoundDataHelperAtom,
    clientSideContent,
    contentsAtom,
    archiveFromAICommentIDSetAtom,
  })

  return null
}

export {
  engageStatsAtom,
  dailyStatsAtom,
  restrictionAtom,
  useLogAtoms,
  AtomLogger,
  localBoundDataHelperAtom,
  clientSideContent,
  clientSideContents,
  archiveFromAICommentIDSetAtom,
  addArchiveFromAICommentIDAtom,
  removeArchiveFromAICommentIDAtom,
}
