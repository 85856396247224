import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import UTCTime from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { LOCALSTORAGE_PREFIX, LOG_PROMISE_RESOLVES, TIKAPI_OAUTH_ID } from "../consts"
import _ from "lodash"
import clipboard from "clipboardy"
dayjs.extend(relativeTime)
dayjs.extend(UTCTime)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

function logOnlyDevLocal(verb, ...args) {
  if (!window.location.href.match(/https?:\/\/(localhost|atlantis)/)) return false
  return console[verb](...args)
}

const _debug = {
  log(...args) {
    return logOnlyDevLocal("log", ...args)
  },
  warn(...args) {
    return logOnlyDevLocal("warn", ...args)
  },
  info(...args) {
    return logOnlyDevLocal("info", ...args)
  },
  table(...args) {
    return logOnlyDevLocal("table", ...args)
  },
}

function arraySortByAccessorFunction(array = [], accessor = (x) => x?.value, reverse = false) {
  return array.sort(function (a, b) {
    const x = typeof accessor(a) === "number" ? accessor(a) : 0
    const y = typeof accessor(b) === "number" ? accessor(b) : 0
    return reverse ? y - x : x - y
  })
}

function checkUrl(url) {
  const regex = new RegExp(
    "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?",
  )
  const without_regex = new RegExp("^([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")

  if (regex.test(url) || without_regex.test(url)) {
    return true
  }
  return false
}

function flattenPromise(promiseObj) {
  return new Promise((resolve) => {
    promiseObj
      .then((data) => {
        if (LOG_PROMISE_RESOLVES) console.log(data)
        return resolve([data, null])
      })
      .catch((err) => {
        return resolve([null, err])
      })
  })
}

function _jp(data) {
  if (!data) return null
  try {
    return JSON.parse(data)
  } catch (e) {
    return null
  }
}

function _js(data) {
  if (!data) return null
  try {
    return JSON.stringify(data)
  } catch (e) {
    return ""
  }
}
function _uri_btoa(data) {
  if (!data) return null
  try {
    return btoa(encodeURIComponent(data))
  } catch {
    return null
  }
}
function _uri_atob(data) {
  if (!data) return null
  try {
    return decodeURIComponent(atob(data))
  } catch (e) {
    return null
  }
}

function _lsr(key) {
  if (!key) return null
  let data = localStorage.getItem(`${LOCALSTORAGE_PREFIX}${key}`)
  if (!data) return null
  if (data.match(/^(\{|\[)/)) {
    const parsed = _jp(data)
    if (parsed) return parsed
  }
  return data
}

function _lsw(key, val) {
  let data = val
  if (typeof val === "object") {
    data = _js(val)
  }
  return localStorage.setItem(`${LOCALSTORAGE_PREFIX}${key}`, data)
}

function _lsd(key, withPrefix = true) {
  if (!withPrefix) return localStorage.removeItem(key)
  return localStorage.removeItem(`${LOCALSTORAGE_PREFIX}${key}`)
}

function _noop() {
  return
}

function _nopropagate(e) {
  e?.preventDefault?.()
  e?.stopPropagation?.()
  e?.nativeEvent?.stopPropagation?.()
  e?.nativeEvent?.stopImmediatePropagation?.()
}

function getQueryParam(search, param) {
  if (!search || !param) return ""
  const patt = new RegExp(`[&?#]${param}=([^&?]+)`, "ig")
  const result = patt.exec(search)
  if (!result) return ""
  return result[1]
}

function num2Hip(number, decPlaces = 1, defaultTo = null) {
  if (typeof number !== "number") return defaultTo

  const abbrev = ["K", "M", "B", "T"]
  for (let i = abbrev.length - 1; i >= 0; i--) {
    let size = Math.pow(10, (i + 1) * 3)
    if (size <= number) {
      let rounded = Math.round((number * Math.pow(10, decPlaces)) / size) / Math.pow(10, decPlaces)

      // If the rounded number is in the hundreds, round up and drop decimals
      if (rounded >= 100) {
        rounded = Math.round(rounded)
      } else {
        rounded = Math.round(rounded * 10) / 10 // Ensure rounding up for .5 and above
      }

      return rounded + abbrev[i]
    }
  }
  // if number is within 100 - 999, drop decimals
  if (number >= 100) {
    return Math.round(number)
  }
  return Math.round(number * 10) / 10
}

function numberWithCommas(x) {
  const parts = x.toString().split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return parts.join(".")
}

function isPositiveFloat(s) {
  return !isNaN(s) && Number(s) > 0
}

function convertToNumber(str) {
  if (!str) return
  return str.replace(/[^-.0-9\s]/g, "")
}

function relTime(timestamp) {
  if (!timestamp) return ""
  return dayjs(timestamp).fromNow()
}

function getISODateBeforeDays(beforeDate = 30) {
  let date = new Date() // Get the current date
  date.setDate(date.getDate() - beforeDate) // Subtract 30 days
  date.setUTCHours(0, 0, 0, 0)
  return date.toISOString()
}

function convertToUTC(dateString) {
  const utcTime = dayjs(dateString).utc()
  return utcTime.format() // Returns formatted UTC date-time string
}

function hardwareProbe() {
  let hardware = "mobile"
  let is_mobile = false
  try {
    const isDesktop = getComputedStyle(document.querySelector(".mobile-probe")).display !== "none"
    hardware = isDesktop ? "desktop" : "mobile"
    is_mobile = !isDesktop
  } catch (e) {
    console.error("display probing failed")
  }
  return {
    hardware,
    is_mobile,
  }
}

function countGridColumns(selector) {
  let cols = 4 // default
  let width = 0
  try {
    const template = getComputedStyle(document.querySelector(selector)).gridTemplateColumns
    const indi = template.split(" ")
    cols = indi.length
    width = indi[0]
  } catch (e) {
    _debug.log("browser wont let me count grid columns")
  }
  return {
    cols,
    width,
  }
}

const _empty = (obj) => {
  if (!obj) return true
  return Object.keys(obj).length === 0
}

const fixedDollar = (amount, digits = 2, forceDigits = false) => {
  if (!amount || !Number.isFinite(amount)) return amount
  if (!forceDigits && amount % 1 === 0) return amount.toFixed(0)
  return amount.toFixed(digits)
}

const cents2USD = (cents, returnBlank = false, fixed = 0) => {
  const cent = parseFloat(cents)
  if (!Number.isFinite(cent)) return returnBlank ? null : 0
  const result = cents / 100
  if (fixed === 0) return result
  return fixedDollar(result, fixed)
}

const USDtoCents = (usd) => {
  const _usd = parseFloat(usd)
  if (!Number.isFinite(_usd)) return 0
  return Math.ceil(_usd * 100)
}

function createHlink(url) {
  const a = document.createElement("a")
  a.href = url
  a.target = "_blank"
  return a
}

function prepareDownload(content) {
  const url = content?.media_urls?.[0]
  if (!url) return null
  const a = createHlink(url)
  a.download = true
  a.click()
  return url
}

function hotTextbox(element) {
  if (!element) return false
  const len = element?.value?.length || 0
  element?.focus?.()
  element?.setSelectionRange?.(0, len)
}

function cleanURL(url) {
  if (!url) return ""
  url = url.replace(/^https?:\/\/(w{3}\.)?/, "")
  url = url.replace(/\/$/, "")
  return url
}

function getPathFromUrl(url) {
  if (!url) return ""
  let path = new URL(url).pathname
  path = path.replace(/\/$/, "")
  return path
}

function notMissing(value) {
  if (value === 0) return true
  if (value === false) return true
  if (value) return true
  return false
}

function missing(value) {
  if (value === 0) return false
  if (value === false) return false
  if (value) return false
  return true
}

function shareUrlViaWebshare({ title, url }) {
  if (navigator.share) {
    navigator.share({
      title,
      url,
    })
    return true
  }
  return false
}

function videhRounding(toRound, x = 25) {
  // Because Videh said he doesn't think this rounding algo has a name
  if (!toRound || !Number.isFinite(toRound)) return toRound
  return toRound % x ? toRound + (x - (toRound % x)) : toRound
}

function generateTimePrice(monthly, roundingFactor = null) {
  const result = {
    daily: 0,
    monthly: 0,
    yearly: 0,
    quarterly: 0,
  }
  monthly = parseFloat(monthly)
  if (!monthly) return result
  result.monthly = monthly
  result.daily = (monthly / 30) * 1.5
  result.weekly = (monthly / 30) * 7 * 1.25
  result.quarterly = monthly * 3 * 0.75
  result.yearly = monthly * 12 * 0.5
  if (typeof roundingFactor === "number" && roundingFactor > 0) {
    result.monthly = videhRounding(result.monthly, roundingFactor)
    result.daily = videhRounding(result.daily, roundingFactor)
    result.weekly = videhRounding(result.weekly, roundingFactor)
    result.quarterly = videhRounding(result.quarterly, roundingFactor)
    result.yearly = videhRounding(result.yearly, roundingFactor)
  }
  return result
}

function arraySwapItemToXPosition(_array, item, swapPosition = 0, accessor = (x) => x) {
  const index = _array.findIndex((e) => accessor(e) === item)

  if (index !== -1) {
    const _item = _array[index]
    _array.splice(index, 1)
    _array.splice(swapPosition, 0, _item)
  }
  return _array
}

function filterCB(item, values = [], field = "type") {
  // filter content booking helper function
  return values.indexOf(item[field]) !== -1
}

function cloneObject(obj) {
  if (typeof obj !== "object") return null
  return JSON.parse(JSON.stringify(obj))
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

function textReplace(text, data, path, defaultValue = "") {
  if (!text) return text
  if (!text.match("#{1}")) return text
  if (!path) return text.replace("#{1}", defaultValue)
  const val = data ? _.get(data, path, defaultValue) : defaultValue
  return text.replace("#{1}", val)
}

function suggestSort(arr, suggestion) {
  const newArr = []
  suggestion.forEach((_item) => {
    if (arr.indexOf(_item) !== -1) newArr.push(_item)
  })
  return newArr
}

function makeURLReady(text) {
  if (!text || typeof text !== "string") return ""
  text = text.toLowerCase().trim().replace(/&+/g, "-and-")
  text = text.replace(/\s+/g, "-")
  text = text.replace(/\-+/g, "-")
  text = text.replace(/-$/, "")
  return text
}

function copyToClipboard(data, onSuccess) {
  if (!data) return false
  clipboard
    .write(data)
    .then((status) => {
      onSuccess()
    })
    .catch((e) => {
      console.error(`Error accessing clipboard`)
    })
}

function convertToPercentage(val, base = 100, _default = null) {
  if (!val) return _default
  switch (base) {
    case 1:
      // console.log("base 1");
      return `${Number(val * 100).toFixed(1)}%`
    case 100:
      return `${Number(val).toFixed(1)}%`
    default:
      return `0%`
  }
}

function getDOMPosition(el) {
  if (!el) return false
  const crect = el?.getBoundingClientRect?.()
  console.log(crect)
  if (!crect)
    return {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    }
  else {
    return {
      left: crect.left,
      top: crect.top,
      right: crect.right,
      bottom: crect.bottom,
    }
  }
}

function prettyCent2USD(cent = 0) {
  if (cent > 99999) {
    return numberWithCommas(fixedDollar(cents2USD(cent), 2, true))
  }
  return fixedDollar(cents2USD(cent), 2, true)
}

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" })
}

function removeEverythingExceptPlusAndNumbers(str) {
  return str.replace(/[^+\d]/g, "")
}

function openLink(link) {
  if (!link) return false
  return window.open(link)
}
function makeUSPhoneNumberDefault(phone) {
  if (!phone) return ""
  const clean = removeEverythingExceptPlusAndNumbers(phone)
  if (clean.length === 10 && !clean.startsWith("+1")) {
    return "+1" + clean
  }
  if (clean.length === 11 && !clean.startsWith("+")) {
    return "+1" + clean
  }
  return clean
}

function _echo() {
  console.log("Echo")
  console.log(arguments)
}

function capitalizeFirstLetter(string) {
  if (!string) return ""
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function getFileNameFromURL(url) {
  let filename = "uploaded"
  try {
    filename = new URL(url).pathname.split("/").pop()
  } catch (e) {
    console.error(e)
  }
  return filename
}

function createCampaignInviteObject({ currentValue, creatorID, campaignID, ...rest }) {
  let payload = {}

  payload.cost_in_cents = Number(currentValue?.cost_in_usd ?? 0) * 100
  payload.content_count = currentValue?.content_count ?? 1
  payload.is_posting_enabled = Boolean(currentValue?.is_posting_enabled)

  payload.campaign = campaignID
  payload.creator = creatorID
  payload = {
    ...payload,
    ...rest,
  }

  return payload
}

function rebindLinkClick(e, newTab = true) {
  if (e?.target?.href) {
    e.target.click()
  }
}

function getArrayFromSeperatorString(str, separator = ",", noSpace = false) {
  if (!str) {
    return []
  }
  const temp_str = noSpace ? str.replace(/\s+/g, "") : str
  return temp_str.split(separator).filter((item) => item !== "")
}

function combineSimpleArrayAndRemoveDuplicates(...arrays) {
  let validArrays = arrays.filter((arr) => Array.isArray(arr))
  return [...new Set([].concat(...validArrays))]
}

function combineURLSearchParams(params1, params2, overwrite = false) {
  const combined = new URLSearchParams(params1.toString())

  for (const [key, value] of params2.entries()) {
    if (overwrite) {
      combined.set(key, value)
    } else {
      if (!combined.has(key)) {
        combined.append(key, value)
      }
    }
  }
  return combined
}

const moveToSubBrandPage = (dispatch, navigate, subBrand, overallSpace) => {
  dispatch({
    sub_brand_id: subBrand?.id,
  })
  navigate({
    pathname: overallSpace ? "/overall-space" : "/competitor-self-serve",
    search: new URLSearchParams({
      sub_brand_title: subBrand?.title,
      sub_brand_id: subBrand?.id,
    }).toString(),
  })
}

const combineUrlSearchParamString = (str1, str2, overwrite = false) => {
  return combineURLSearchParams(new URLSearchParams(str1), new URLSearchParams(str2), overwrite)
}

function extractQueryParamsString(url) {
  const match = url.match(/(^[^?]+)\??([^#]*)/)
  return {
    endpoint: match && match[1] ? match[1] : "",
    query: match && match[2] ? match[2] : "",
  }
}

function generateDateArray(numOfDays, dataArray) {
  let dates = {}
  let currentDate = new Date()

  // Initialize the dates object with dates from the last numOfDays days.
  for (let i = 0; i < numOfDays; i++) {
    let dateKey = currentDate.toISOString().split("T")[0]
    dates[dateKey] = 0
    currentDate.setDate(currentDate.getDate() - 1)
  }

  // Populate the dates object with values from dataArray.
  dataArray.forEach((item) => {
    if (dates.hasOwnProperty(item.x)) {
      dates[item.x] = { y: item.y, originalValue: item?.originalValue }
    }
  })

  // Convert the dates object to an array in the same format as dataArray.
  const result = Object.keys(dates).map((date) => ({
    x: date,
    y: dates[date].y ?? 0,
    originalValue: dates[date]?.originalValue,
  }))

  return result
}

function getBrandInviteUrl(ref_code) {
  if (!ref_code) {
    return ""
  }
  let hostName = window.location.hostname
  // localhost
  if (hostName === "localhost") {
    hostName = window.location.host
  }
  const link_add = `/start-from-brand-invite?refcode=${ref_code}`
  const prefix = window.location.protocol + "//"
  return prefix + hostName + link_add
}

function jsonToDoubleEncodedBase64(jsonData) {
  // Convert JSON to a string
  var jsonString = JSON.stringify(jsonData)

  // Convert string to a UTF-8 encoded Uint8Array
  var utf8EncodedArray = new TextEncoder().encode(jsonString)

  // Convert the UTF-8 encoded Uint8Array to a base64 string
  var base64String = arrayBufferToBase64(utf8EncodedArray)

  // Double URL-encode the base64 string
  return encodeURIComponent(encodeURIComponent(base64String))
}

function arrayBufferToBase64(buffer) {
  var binary = ""
  var bytes = new Uint8Array(buffer)
  var len = bytes.byteLength
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return btoa(binary)
}

function decodeDoubleEncodedBase64(doubleEncodedBase64) {
  // Double URL-decode the string
  var decodedBase64String = decodeURIComponent(decodeURIComponent(doubleEncodedBase64))

  // Convert base64 string to a UTF-8 encoded Uint8Array
  var utf8EncodedArray = base64ToArrayBuffer(decodedBase64String)

  // Convert the UTF-8 encoded Uint8Array to a string
  var jsonString = new TextDecoder().decode(utf8EncodedArray)

  // Parse the JSON string back into an object
  return JSON.parse(jsonString)
}

function base64ToArrayBuffer(base64) {
  var binary_string = atob(base64)
  var len = binary_string.length
  var bytes = new Uint8Array(len)
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}

function getCampaignLink(ref_code, campaign, brandName) {
  if (!ref_code) {
    return ""
  }
  let hostName = window.location.hostname
  // localhost
  if (hostName === "localhost") {
    hostName = window.location.host
  }
  const _value = {
    camp: campaign?.id,
    circle: true,
  }
  if (campaign?.name && brandName) {
    _value.invite_text = `Join ${brandName}'s ${campaign?.name} campaign`
    _value.invite_title = `Please click Join below to join the ${campaign?.name} campaign`
  }
  const link_add = `/start-from-brand-invite?refcode=${ref_code}&invite_config=${jsonToDoubleEncodedBase64(_value)}`
  const prefix = window.location.protocol + "//"
  return prefix + hostName + link_add
}

function replaceTemplateVariables(template, variables) {
  return template.replace(/\{\{(\s*[\w\.]+\s*)\}\}/g, (match, variableName) => {
    // Trim the variable name to remove extra spaces
    variableName = variableName.trim()

    // Access the variable value from the variables object
    // Supports nested properties using dot notation
    const value = variableName.split(".").reduce((obj, key) => obj && obj[key], variables)

    // Replace the template variable with the actual value
    // If the value is undefined, it keeps the original template variable
    return value !== undefined ? value : match
  })
}

function getDateRangeOfDays(inputDate, days = 1) {
  // Parse the input date with dayjs and convert it to UTC
  let startDate = dayjs(inputDate).format("YYYY-MM-DD")

  //    console.log(inputDate)

  // Validate the date
  // if (!startDate?.isValid()) {
  //     throw new Error('Invalid date provided');
  // }

  // Calculate the end date by adding the specified number of days
  let endDate = dayjs(inputDate).add(days, "day").format("YYYY-MM-DD")

  // console.log({
  //     startDate,
  //     endDate
  // })
  return {
    startDate: startDate,
    endDate: endDate,
  }
}
// return previous date range
const getPreviousPeriod = (startDate, endDate) => {
  const start = dayjs(startDate)
  const end = dayjs(endDate)
  const diff = end.diff(start, "day")
  const newStart = start.subtract(diff + 1, "day").format("YYYY-MM-DD")
  const newEnd = end.subtract(diff + 1, "day").format("YYYY-MM-DD")
  return { startDate: newStart, endDate: newEnd }
}

function getDaysCountBetweenDates(startDate, endDate, format = "YYYY-MM-DD") {
  // Parse the input date with dayjs and convert it to UTC
  const start = dayjs(startDate)
  const end = dayjs(endDate)

  // Calculate the difference in days between the two dates
  const days = end.diff(start, "day")

  return days
}

function getSubtractedDate(inputDate, days = 1, format = "YYYY-MM-DD") {
  // Parse the input date with dayjs and convert it to UTC
  const startDate = dayjs(inputDate).subtract(days, "day").format(format)
  return startDate
}
function getAddDate(inputDate, days = 1, format = "YYYY-MM-DD") {
  // Parse the input date with dayjs and convert it to UTC
  const startDate = dayjs(inputDate).add(days, "day").format(format)
  return startDate
}

function getStartDateOfWeek(year, weekNumber) {
  // Create a date object for the first day of the year in UTC
  let startDate = dayjs.utc(`${year}-01-01`)

  // Find out which day of the week is January 1st (0 = Sunday, 1 = Monday, ...)
  let dayOfWeek = startDate.day()

  // Calculate how many days to add to get to the first Monday of the year
  // If it's Sunday (0), adjust by 1 day; otherwise, adjust by (1 - dayOfWeek)
  let daysToAdd = 0

  // Adjust to the first Monday of the year
  startDate = startDate.add(daysToAdd, "day")

  // Calculate the start date of the specified week
  // Subtract 1 week because the first week is already in progress
  startDate = startDate.add((weekNumber - 1) * 7, "day")

  return startDate.toISOString()
}

function getDayBeforeEastern(date) {
  return dayjs.tz(date, "America/New_York").add(1, "day").format("YYYY-MM-DD")
}

function getCurrentWeekNumber() {
  const today = dayjs.utc()
  const startOfYear = dayjs.utc(`${today.year()}-01-01`)
  const dayOfWeek = startOfYear.day() // 0 (Sunday) to 6 (Saturday)
  const daysToAdd = dayOfWeek === 0 ? 1 : 1 - dayOfWeek // Adjust to Monday
  const firstMondayOfYear = startOfYear.add(daysToAdd, "day")
  const differenceInDays = today.diff(firstMondayOfYear, "day")

  return Math.ceil(differenceInDays / 7) + 1
}

function getStartDateOfWeekBasedOnCurrentWeek(weekNumber, year) {
  const currentYear = dayjs.utc().year()
  const currentWeek = getCurrentWeekNumber()
  const yearToUse = weekNumber >= currentWeek ? currentYear - 1 : currentYear
  return getStartDateOfWeek(year ?? yearToUse, weekNumber)
}

// function getStartDateOfWeek(year, weekNumber) {
//     // Create a new date object for the first day of the year
//     let startDate = new Date(year, 0, 1);

//     // Calculate the start date of the specified week
//     // Subtract 1 from weekNumber since the first week is already in progress on Jan 1
//     // Multiply by 7 to get the total number of days to add
//     startDate.setDate(startDate.getDate() + (weekNumber - 1) * 7);

//     return startDate;
// }
function getMonthStartAndEndDate(year, month) {
  // Create a date object for the first day of the given month (month is 1-indexed in this function)
  let startDate = dayjs.utc(`${year}-${month}-01`)

  // Calculate the end date by moving to the first day of the next month and subtracting one day
  let endDate = startDate.add(1, "month").subtract(1, "day")

  // Check if the month has 31 days
  let has31Days = endDate.date() === 31

  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    has31Days: has31Days,
  }
}

function getMonthStartAndEndDateBasedOnCurrentMonth(month, year = null) {
  const currentDate = dayjs.utc()
  const currentYear = currentDate.year()
  const currentMonth = currentDate.month() + 1 // month() returns 0-11, so add 1

  // Determine the year to use
  const yearToUse = year ? Number(year) : month >= currentMonth ? currentYear - 1 : currentYear

  // Call the original function with the determined year and the provided month
  return getMonthStartAndEndDate(yearToUse, month)
}

function generateProfileUrl(platform, username) {
  let baseUrl
  switch (platform.toLowerCase()) {
    case "tiktok":
      baseUrl = "https://www.tiktok.com/@"
      break
    case "instagram":
      baseUrl = "https://www.instagram.com/"
      break
    case "youtube":
      baseUrl = "https://www.youtube.com/c/"
      break
    default:
      return "Platform not supported"
  }
  return baseUrl + username
}

function getExternalUrlFromSocialAccount(socialAccount, platform) {
  if (socialAccount?.external_url) return socialAccount?.external_url
  return generateProfileUrl(platform, socialAccount?.username)
}

function getCreatorName(creator) {
  if (creator?.first_name && creator?.last_name) {
    return `${creator?.first_name} ${creator?.last_name}`
  }
  if (creator?.first_name) return creator?.first_name
  if (creator?.last_name) return creator?.last_name

  if (creator?.name) return creator?.name

  return creator?.username
}

function truncateString(str, maxChars, postFix = "...") {
  if (str.length > maxChars) {
    return str.substring(0, maxChars) + postFix
  } else {
    return str
  }
}

function connectTikapi() {
  if (!window.TikAPI) {
    console.error("Tikapi popup is not loaded")
    return
  }
  window.TikAPI.popup({
    client_id: TIKAPI_OAUTH_ID,
    //scope: ['VIEW_PROFILE', 'USER_MESSAGES'], (optional)
  })
}

function formatDate(_date, format = "YYYY-MM-DD") {
  return dayjs(_date).format(format)
}

function moveIdsToFront(arr, ids, accessor = (x) => x.id) {
  const objectMap = new Map(arr.map((item) => [accessor(item), item]))
  const withIds = ids.map((id) => objectMap.get(id)).filter(Boolean)
  const withoutIds = arr.filter((item) => !ids.includes(accessor(item)))
  return [...withIds, ...withoutIds]
}

const updateObjectCollection = ({ id, newValue = {}, override = false, initialObject = {}, deleteObj = false }) => {
  if (!id) {
    console.error("Can't update without an ID.")
    return initialObject
  }

  if (deleteObj) {
    if (!(id in initialObject)) {
      console.warn(`ID ${id} not found. Nothing to delete.`)
      return initialObject
    }

    const { [id]: _, ...remainingObjects } = initialObject
    return remainingObjects
  }

  return {
    ...initialObject,
    [id]: override ? newValue : { ...initialObject[id], ...newValue },
  }
}

const cutoffArray = ({ arr, cutoffAt }) => {
  if (!arr?.length) return []
  if (!cutoffAt) return arr
  return arr.slice(0, cutoffAt)
}

function determineNpsColor(data) {
  const currentNegative = data?.current_day?.total_negative_nps ?? 0
  const lastNegative = data?.last_day?.total_negative_nps ?? 0

  const currentTotal =
    data?.current_day?.total_positive_nps +
      data?.current_day?.total_negative_nps +
      data?.current_day?.total_neutral_nps ?? 1
  const lastTotal =
    data?.last_day?.total_positive_nps + data?.last_day?.total_negative_nps + data?.last_day?.total_neutral_nps ?? 1

  const currentNegativePercentage = (currentNegative / currentTotal) * 100
  const lastNegativePercentage = (lastNegative / lastTotal) * 100

  const increasePercentage = lastNegative > 0 ? ((currentNegative - lastNegative) / lastNegative) * 100 : 0

  if (currentNegativePercentage > 25 || increasePercentage > 150) {
    return { color: "red", currentNegativePercentage: currentNegativePercentage.toFixed(2) + "%", status: "negative" }
  }

  if (currentNegativePercentage > 10 || increasePercentage > 75) {
    return { color: "orange", currentNegativePercentage: currentNegativePercentage.toFixed(2) + "%", status: "neutral" }
  }

  return { color: "green", currentNegativePercentage: currentNegativePercentage.toFixed(2) + "%", status: "positive" }
}

function findContentIds(obj, currentPath = "") {
  const results = []

  // Base case: if obj is not an object or is null, return empty results
  if (!obj || typeof obj !== "object") return results

  // Loop through each key-value pair in the object
  Object.entries(obj).forEach(([key, value]) => {
    // Build the current path (e.g., "data.users.0.profile")
    const newPath = currentPath ? `${currentPath}.${key}` : key

    // If we found a content_ids array, add it to results
    if (key === "content_ids" && Array.isArray(value)) {
      const uniqueArray = [...new Set(value)]
      results.push({ path: newPath, values: uniqueArray })
    }

    // Recursively check nested objects (including arrays, which are objects in JS)
    if (typeof value === "object" && value !== null) {
      results.push(...findContentIds(value, newPath))
    }
  })

  return results
}
function removeLastDot(str) {
  return str.replace(/\.$/, "")
}

function arraysEqual(arr1 = [], arr2 = []) {
  if (arr1?.length !== arr2?.length) return false

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }

  return true
}

export {
  flattenPromise,
  _jp,
  _js,
  _uri_btoa,
  _uri_atob,
  _noop,
  _nopropagate,
  getQueryParam,
  num2Hip,
  numberWithCommas,
  arraySortByAccessorFunction,
  relTime,
  getISODateBeforeDays,
  hardwareProbe,
  countGridColumns,
  generateTimePrice,
  _empty,
  _lsr,
  _lsw,
  _lsd,
  fixedDollar,
  cents2USD,
  USDtoCents,
  prettyCent2USD,
  prepareDownload,
  hotTextbox,
  createHlink,
  cleanURL,
  checkUrl,
  getPathFromUrl,
  notMissing,
  missing,
  shareUrlViaWebshare,
  arraySwapItemToXPosition,
  videhRounding,
  filterCB,
  cloneObject,
  validateEmail,
  textReplace,
  suggestSort,
  makeURLReady,
  copyToClipboard,
  isPositiveFloat,
  cutoffArray,
  _debug,
  convertToPercentage,
  scrollToTop,
  makeUSPhoneNumberDefault,
  getDOMPosition,
  _echo,
  capitalizeFirstLetter,
  getFileNameFromURL,
  convertToNumber,
  openLink,
  createCampaignInviteObject,
  rebindLinkClick,
  getArrayFromSeperatorString,
  combineSimpleArrayAndRemoveDuplicates,
  moveToSubBrandPage,
  combineURLSearchParams,
  combineUrlSearchParamString,
  extractQueryParamsString,
  generateDateArray,
  getBrandInviteUrl,
  replaceTemplateVariables,
  getDateRangeOfDays,
  getPreviousPeriod,
  getDaysCountBetweenDates,
  getSubtractedDate,
  getAddDate,
  getStartDateOfWeek,
  getStartDateOfWeekBasedOnCurrentWeek,
  getMonthStartAndEndDateBasedOnCurrentMonth,
  getMonthStartAndEndDate,
  getExternalUrlFromSocialAccount,
  getCreatorName,
  truncateString,
  connectTikapi,
  getCampaignLink,
  decodeDoubleEncodedBase64,
  formatDate,
  moveIdsToFront,
  updateObjectCollection,
  getDayBeforeEastern,
  determineNpsColor,
  convertToUTC,
  findContentIds,
  removeLastDot,
  arraysEqual,
}
