import { dataContext } from "../datacontext"
import { useContext, useEffect, useRef } from "react"
import ReactGA from "react-ga4"
import { useLocation } from "react-router-dom"
import { _debug } from "../../helpers/utils"
import { useParams } from "react-router-dom"
function useAnalytics() {
  const [data] = useContext(dataContext)
  const params = useParams()
  const metadata = () => {
    let payload = {
      role: "guest",
      is_user_authenticated: false,
    }
    if (data?.authUser?.id) payload = { ...payload, auth_user_id: data.authUser.id }

    if (data?.role) {
      if (data?.role === "creator") payload = { ...payload, role: "creator" }
      if (data.role === "brand") {
        // make syre to send agency as agency
        const altRole = data?.me?.brand?.is_agency ? "agency" : "brand"
        payload = { ...payload, role: altRole }
      }
      payload = { ...payload, is_user_authenticated: true }
      if (data?.me?.id) payload = { ...payload, system_user_id: data.me.id }
      if (data?.me?.username) payload = { ...payload, system_username: data.me.id }
      if (data?.creator_name) payload = { ...payload, creator_username: data.creator_name }
      else if (params.creator_name) payload = { ...payload, creator_username: params.creator_name }
      if (data?.creator?.id) payload = { ...payload, creator_id: data.creator?.id }
      if (data?.search_query) payload = { ...payload, last_search_query: data.search_query }
      if (data?.brand_proposal_brand)
        payload = { ...payload, is_brand_proposal: true, brand_proposal_for: data.brand_proposal_brand }
    }
    //console.log(payload);
    return payload
  }
  const page = (page, title = "Creator Stock") => {
    try {
      const _metadata = metadata()
      ReactGA.send({
        hitType: "pageview",
        page: page,
        page_title: title,
        ..._metadata,
      })
      _debug.log("GA: Pageview event sent", { page, title, metadata: _metadata })
    } catch (e) {
      console.error("Analytics failed. supplimentary error")
    }
  }
  const action = (category, action, label, value, beacon = false) => {
    try {
      const payload = {
        category,
        action,
      }
      if (label) payload.label = label
      if (value) payload.value = value
      if (beacon) payload.transport = "beacon"
      const _metadata = metadata()
      ReactGA.event({
        ...payload,
        ..._metadata,
      })
      _debug.log("GA: Event sent", { category, action, label, value, metadata: _metadata })
    } catch (e) {
      console.error("Analytics failed. supplimentary error")
    }
  }
  const button = (_action, value = "") => {
    return action("button", _action, "button_interaction", value)
  }
  const link = (_action, value = "") => {
    return action("link", _action, "hyperlink", value, true)
  }
  return {
    page,
    action,
    button,
    link,
  }
}

function usePageAnalytics() {
  const location = useLocation()
  useEffect(() => {
    try {
      ReactGA.send({ hitType: "pageview", page: `${window.location.pathname}${window.location.search}` })
      ReactGA.set({ page: `${window.location.pathname}${window.location.search}` })
      _debug.log("GA pageview sent", `page: ${window.location.pathname}${window.location.search}`)
    } catch (e) {
      console.warn("GA:Minor error")
    }
  }, [location])
}

function useTabPageSpecificAnalytics(pageAlias, pageName = "") {
  const debug = true
  const { action } = useAnalytics()
  const pageStart = useRef(Date.now())
  const obs = useRef(
    new IntersectionObserver((entry) => {
      if (entry?.[0]?.isIntersecting === false) {
        debug && console.log(`scroll ${pageAlias}`)
        action("page", `Scrolls_${pageAlias}`)
      }
    }),
  )
  useEffect(() => {
    obs.current.observe(document.querySelector(".cs-header"))
    action("page", `Visit_${pageAlias}`)
    return () => {
      obs.current.disconnect()
      const timespent = (Date.now() - pageStart.current) / 1000
      debug && console.log(`time spent ${pageAlias}`, timespent)
      action("page", `time_spent_${pageAlias}`, "timespent")
    }
  }, [])
}

export { useAnalytics, usePageAnalytics, useTabPageSpecificAnalytics }
