import { useContext } from "react"
import { cloneObject } from "../../helpers/utils"
import { useServer } from "../../hooks/useServer"
import { dataContext } from "../datacontext"
import { useLoading } from "../loaderContext"
import { useCommentRestrictionAndStats } from "../../hooks/helperHooks"

function useCommonAPIS() {
  const { actions } = useLoading()
  const server = useServer()
  const [data, dispatch] = useContext(dataContext)
  const { setRestrictionAtom, syncRestriction } = useCommentRestrictionAndStats()

  const me = async (silent = false) => {
    if (!silent) actions.batchLoading(["auth0me", "me"])
    const _me = await server.get("/User/me/")
    if (!_me) return null
    _me?.brand?.sub_brands?.sort((a, b) => a.id - b.id)
    dispatch({ me: _me, authUser: _me })
    setRestrictionAtom(_me?.brand?.comment_restriction)
    syncRestriction()
    if (!silent) actions.batchNotLoading(["auth0me", "me"])
    return _me
  }

  const patchMe = async (payload) => {
    actions.batchLoading(["auth0me"])
    const _me = await server.patch("/User/me/", payload)
    actions.batchNotLoading(["auth0me"])
    if (!_me) return null
    dispatch({ me: _me })
    return _me
  }

  const upload = async (file, id = "", additionalLoaders = []) => {
    actions.batchLoading([...additionalLoaders, `file_upload`, `uploading_file_${id}`])
    const data = new FormData()
    data.append("file", file)
    const uploaded = await server.post("/Auth0User/upload/", data, true, "post", {
      "Content-Type": "multipart/form-data",
    })
    actions.batchNotLoading([...additionalLoaders, `file_upload`, `uploading_file_${id}`])
    if (uploaded) return uploaded
    return null
  }

  const uploadImage = async (image, id = "", additionalLoaders = []) => {
    actions.batchLoading([...additionalLoaders, `image_upload`, `uploading_image_${id}`])
    const data = new FormData()
    data.append("image", image)
    const uploaded = await server.post("/file_upload", data, true, "post", { "Content-Type": "multipart/form-data" })
    actions.batchNotLoading([...additionalLoaders, `image_upload`, `uploading_image_${id}`])
    if (uploaded) return uploaded
    return null
  }

  async function getRequestHelper({
    endpoint,
    section = "",
    filter = "",
    dataContextPrefix = "bd_sec_",
    loaders = [],
    skipDiscoveryPlatform = false,
    skipLoading = false,
  }) {
    // todo: remove dummy content implementation
    const page = data[`${dataContextPrefix}${section}_page`]
    const pagePart = page ? `page=${page}` : ""
    const _platf = data?.discovery_section_platform ? data?.discovery_section_platform : "tiktok"
    if (!skipLoading) actions.batchLoading(["default-get-loader", ...loaders])
    const _fullFilter = filter ? `?${filter}${pagePart ? `&${pagePart}` : ""}` : pagePart ? `?${pagePart}` : ""
    const fullFilter = skipDiscoveryPlatform
      ? _fullFilter
      : _fullFilter
        ? `${_fullFilter}&platform=${_platf}`
        : `?platform=${_platf}`
    const _data = await server.get(`${endpoint}${fullFilter}`)
    actions.batchNotLoading(["default-get-loader", ...loaders])
    if (!data) return null
    // const rawData = if(data?.[`${dataContextPrefix}${section}_page`] && )
    const listData =
      page && page > 1 && data[`${dataContextPrefix}${section}`]?.length
        ? [...data[`${dataContextPrefix}${section}`], ..._data.data]
        : _data.data
    const payload = _data?.pagination
      ? { [`${dataContextPrefix}${section}`]: listData, [`${dataContextPrefix}${section}_raw`]: _data }
      : { [`${dataContextPrefix}${section}`]: _data }
    dispatch({
      ...payload,
    })
    return _data?.data ? _data.data : _data
  }

  async function postRequestHelper({
    endpoint,
    section = "",
    payload,
    filter = "",
    dataContextPrefix = "bd_sec_",
    loaders = [],
    skipLoading = false,
    requestType = "post",
  }) {
    const page = data[`${dataContextPrefix}${section}_page`]
    const pagePart = page ? `page=${page}` : ""
    if (!skipLoading) actions.batchLoading(["default-post-loader", ...loaders])
    const fullFilter = filter ? `?${filter}${pagePart ? `&${pagePart}` : ""}` : pagePart ? `?${pagePart}` : ""
    const _data = await server[requestType](`${endpoint}${fullFilter}`, payload)
    actions.batchNotLoading(["default-post-loader", ...loaders])
    if (!data) return null
    const listData =
      page && page > 1 && data[`${dataContextPrefix}${section}`]?.length
        ? [...data[`${dataContextPrefix}${section}`], ..._data?.data]
        : _data?.data
    const _payload = _data?.pagination
      ? { [`${dataContextPrefix}${section}`]: listData, [`${dataContextPrefix}${section}_raw`]: _data }
      : { [`${dataContextPrefix}${section}`]: _data }
    dispatch({
      ..._payload,
    })
    return _data?.data ? _data.data : _data
  }

  const updateManyObjectTypeInDataContent = ({
    id,
    newValue = {},
    override = false,
    dataContextVariableName = "current_campaign_invite_cart",
    deleteObj = false,
  }) => {
    const newObject = cloneObject(data?.[dataContextVariableName] ?? {})
    if (!id) {
      console.error("can't update with out id")
    }
    newObject[id] = {
      ...newObject?.[id],
      ...newValue,
    }
    if (override) {
      newObject[id] = newValue
    }

    if (deleteObj) {
      delete newObject[id]
    }

    dispatch({
      [dataContextVariableName]: newObject,
    })
  }

  return {
    me,
    patchMe,
    upload,
    uploadImage,
    getRequestHelper,
    postRequestHelper,
    updateManyObjectTypeInDataContent,
  }
}

export { useCommonAPIS }
