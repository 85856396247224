import { useState } from "react"
import { num2Hip } from "../../helpers/utils"
import { Card } from "../dumbs"
import Chart from "react-apexcharts"
import { Bar, Line } from "@visx/shape"
import { Group } from "@visx/group"
import { Text } from "@visx/text"
import { scaleLinear, scaleBand } from "@visx/scale"
import { Wordcloud } from "@visx/wordcloud"
import { scaleLog } from '@visx/scale';

const fillerSeries = [
  {
    label: "Previous period",
    value: 12322300,
  },
  {
    label: "This period",
    value: 1000000,
  },
]
const IncreaseDecreaseChart = ({
  className = "",
  series = [],
  baseIndex = 0,
  title = "Total # of posts",
  width = 300,
  height = 250,
  numberStyler = num2Hip,
  onBarClick,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const __baseValue = series[baseIndex]?.y
  const data = [...series]

  data.forEach((d) => {
    d.change = d.y - __baseValue == 0 ? 0 : (d.y - __baseValue) / __baseValue
    d.color = d.change > 0 ? "#32EAC9" : "#FCD7D7"
    d.textColor = d.change > 0 ? "#29828F" : "#AC2A2A"
  })

  // const width = 300
  // const height = 250
  const margin = { top: 20, right: 10, bottom: 30, left: 5 }

  const xScale = scaleBand({
    domain: data.map((d) => d.x),
    range: [margin.left, width - margin.right],
    padding: 0.5,
  })

  const yScale = scaleLinear({
    domain: [0, Math.max(...data.map((d) => d.y)) * 1.2],
    range: [height - margin.bottom, margin.top],
  })

  return (
    <Card className={`${className}`}>
      <h5 className="headline-small-highlight padding-x-4x padding-y-3x">{title}</h5>
      <hr className="hr"></hr>
      {data.length === 0 ? (
        <NotEnoughData />
      ) : (
        <svg width={width} height={height}>
          <Group>
            {data.map((d, i) => {
              const x = xScale(d.x)
              const barWidth = xScale.bandwidth() + 10 // Increase width by 2px
              const baseHeight = yScale(__baseValue)
              const currentHeight = yScale(d.y)
              const changeHeight = baseHeight - currentHeight
              let changePercentage = (d?.change ?? 0) * 100
              changePercentage = num2Hip(changePercentage)
              return (
                <Group key={i}>
                  <Bar
                    className="cursor-pointer"
                    x={x - 1}
                    onMouseEnter={() => setHoveredIndex(i)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    y={baseHeight}
                    width={barWidth}
                    height={height - margin.bottom - baseHeight}
                    fill={hoveredIndex === i ? "#49A0ff" : "#4990EC"}
                    onClick={() => onBarClick?.(d, i)}
                  />
                  {d.change !== 0 && (
                    <>
                      <Bar
                        className="cursor-pointer"
                        x={x - 1}
                        y={changeHeight > 0 ? currentHeight : baseHeight}
                        width={barWidth}
                        height={Math.abs(changeHeight)}
                        fill={d.color}
                        onMouseEnter={() => setHoveredIndex(i)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        style={{
                          filter: hoveredIndex === i ? "brightness(1.05)" : "brightness(1)",
                        }}
                        // stroke="white"
                        onClick={() => onBarClick?.(d, i)}
                      />
                      <Bar
                        x={x - 1}
                        y={changeHeight > 0 ? baseHeight - 1 : currentHeight}
                        width={barWidth}
                        height={1}
                        fill={"white"}
                      // stroke="white"
                      />
                    </>
                  )}
                  <Text
                    x={x + barWidth / 2 - 1}
                    y={currentHeight - 12}
                    fontSize={12}
                    className="caption-regular"
                    fill="black"
                    textAnchor="middle"
                    dy={5}
                  >
                    {numberStyler(d.y)}
                  </Text>
                  {d.change !== 0 && (
                    <Text
                      x={x + barWidth / 2 - 1}
                      y={currentHeight - 12}
                      fontSize={12}
                      className="caption-regular"
                      fill="black"
                      textAnchor="middle"
                      dy={5}
                    >
                      {numberStyler(d.y)}
                    </Text>
                  )}
                  {d.change !== 0 && (
                    <Text
                      x={x + barWidth + 2}
                      y={changeHeight > 0 ? currentHeight - 1 + changeHeight / 2 : baseHeight - changeHeight / 2}
                      fontSize={12}
                      className="caption"
                      fill={d.textColor}
                      textAnchor="start"
                      verticalAnchor="middle"
                    >
                      {`${d.change > 0 ? "+" : ""}${changePercentage}%`}
                    </Text>
                  )}
                  <Text
                    x={x + barWidth - 1}
                    className={`${hoveredIndex === i ? "nc75-fill" : "nc50-fill"} caption`}
                    y={height - 10}
                    fontSize={12}
                    // fill="gray"
                    textAnchor="end"
                  >
                    {d.x}
                  </Text>
                </Group>
              )
            })}
            <Line
              from={{ x: 0, y: height - margin.bottom }}
              to={{ x: width, y: height - margin.bottom }}
              stroke="black"
              className="nc10-stroke"
              strokeWidth={1}
            />
          </Group>
        </svg>
      )}
    </Card>
  )
}

const WordCloudGraph = ({
  data = [],
  fontSizeRange = [12, 60],
  maxVal,
  minVal,
  className = "",
  width = 560,
  height = 260
}) => {
  const fontScale = scaleLog({
    domain: [
      minVal ?? Math.min(...data.map((w) => w.value)),
      maxVal ?? Math.max(...data.map((w) => w.value))
    ],
    range: fontSizeRange,
  });


  return (
    <div className={`pp-formula-font ${className}`}>
      <Wordcloud
        words={data ?? []}
        width={width}
        height={height}
        fontSize={(datum) => fontScale(datum.value)}
        font={ (datum) => datum.font ?? "Poppins"}
        fontWeight={"600"}
        padding={8}
        spiral={"archimedean"}
        rotate={0}
        random={() => 0.5}
      >
        {(cloudWords) =>
          cloudWords.map((w, i) => (
            <Text
              key={w.text}
              fill={"#000000"}
              textAnchor={'middle'}
              transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
              fontSize={w.size}
              fontFamily={w.font}
            >
              {w.text}
            </Text>
          ))
        }
      </Wordcloud>
    </div>
  )
}


let barChartConfig = {
  series: [
    {
      data: [23, 32, 23, 21],
    },
  ],
  options: {
    chart: {
      type: "bar",
      // sparkline: {
      //     enabled: true  // this will remove gridlines, yaxis, xaxis labels, etc.
      // },

      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          // Customize the displayed value here.
          // This is just a simple example.
          return num2Hip(val)
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        distributed: true,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      // enabled: false  // This will hide the data labels
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#767B8A"],
      },
      formatter: function (val, opts) {
        return num2Hip(val)
      },
    },
    colors: [
      "#663FF1",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
    ],
    xaxis: {
      snow: false,
      // categories: ["NC","DC","MR","SN"],
      labels: {
        rotate: -90, // Rotate the labels to make them vertical
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      paddding: {
        left: 0,
      },
    },
    grid: {
      show: false, // This will hide the grid
      padding: {
        left: 0,
      },
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      show: false, // This hides the entire legend
    },
  },
}

const donutChartConfig = {
  labels: ["Positive", "Neutral", "Negative"],
  fill: {
    colors: ["#663FF1", "#DD5C5C", "#FF9D00", "#ACAFB9", "#ACAFB9"],
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        // Customize the displayed value here.
        // This is just a simple example.
        return val.toFixed(2) + "%"
      },
    },
  },
  title: {
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 100,
    floating: true,
    style: {
      fontSize: "22px",
      fontFamily: "Poppins, Arial, sans-serif",
      fontWeight: 600,
      color: "#373d3f",
    },
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
}

const NotEnoughData = ({ text = "Not enough data", className = "", heightClassName = "min-height-248" }) => {
  return (
    <div className={`${heightClassName} flex a-center j-center ${className}`}>
      <h4 className="headline-small-highlight nc30-fg">{text}</h4>
    </div>
  )
}

const BarChart = ({
  title = "",
  data = [
    { x: "LD", y: 21 },
    { x: "OL", y: 10 },
    { x: "RB", y: 15 },
    { x: "MN", y: 25 },
  ],
  className = "",
  subtitle = "",
  colors,
  options,
}) => {
  return (
    <Card className={`padding-top-5x full ${className}`}>
      {title && <h3 className="padding-x-5x margin-bottom headline-small-highlight flex">{title}</h3>}
      {subtitle && <span className="caption-regular-font nc50-fg min-height-32 padding-x-5x flex">{subtitle}</span>}
      {!data || !data?.length ? (
        <NotEnoughData />
      ) : (
        <Chart
          series={[{ name: title, data: data }]}
          options={{
            ...barChartConfig.options,
            colors: colors || barChartConfig.options.colors,
            ...(options || {}),
          }}
          type="bar"
          height={250}
        />
      )}
    </Card>
  )
}

const DonutChart = ({
  title = "",
  data,
  labels,
  className = "",
  titleClassName = "",
  colors = [],
  height = 250,
  centerLabel,
  centerLabelOffsetY = 100,
  onDonutClick,
  options = {},
}) => {
  const handleClick = (event, chartContext, config) => {
    if (config.dataPointIndex < 0) return
    onDonutClick?.(config.dataPointIndex)
  }

  return (
    <Card className={`padding-y-5x full ${className}`}>
      {title && <h3 className={`padding-x-5x margin-bottom-4x headline-small-highlight ${titleClassName}`}>{title}</h3>}
      {!data || !data?.length ? (
        <NotEnoughData />
      ) : (
        <Chart
          options={{
            ...donutChartConfig,
            chart: {
              events: {
                // click: handleClick,
                dataPointSelection: handleClick,
              },
            },
            title: {
              ...donutChartConfig.title,
              text: centerLabel || `${(data[0] + data[1]).toFixed(0)}%`,
              offsetY: centerLabelOffsetY,
            },
            fill: {
              colors: colors.length ? colors : donutChartConfig.fill.colors,
            },
            labels: labels || donutChartConfig.labels,
            ...(options || {}),
          }}
          series={data}
          type="donut"
          height={height}
        />
      )}
    </Card>
  )
}

const SmallBarNegativeChart = ({
  title = "",
  data = null,
  categories = [],
  className = "",
  subtitle = "",
  onBarClick,
  height = 200,
  enableCenterZero = false,
}) => {
  const handleClick = (event, chartContext, config) => {
    onBarClick?.({
      category: categories[config.dataPointIndex],
      type: config.seriesIndex === 0 ? "positive" : "negative",
    })
  }

  const maxNum = Math.max(...data?.positive, ...data?.negative.map((n) => -n))

  return (
    <Card className={`padding-top-5x full ${className}`}>
      {(title || subtitle) && (
        <div className="padding-x-3x gap-1 vcenter margin-bottom min-height-40">
          {title && <h3 className="headline-small-highlight">{title}</h3>}{" "}
          {subtitle && <span className="margin-left-1x caption-regular-font nc50-fg inline flex">{subtitle}</span>}
        </div>
      )}
      {!data ? (
        <NotEnoughData />
      ) : (
        <Chart
          series={[
            {
              name: "Positive",
              data: data?.positive || [],
            },
            {
              name: "Negative",
              data: data?.negative || [],
            },
          ]}
          options={{
            chart: {
              type: "bar",
              stacked: true,
              toolbar: {
                show: false,
              },
              events: {
                click: handleClick,
              },
            },
            colors: ["#32EAC9", "#F67979"],
            plotOptions: {
              bar: {
                borderRadius: 0,
                borderRadiusApplication: "end", // 'around', 'end'
                borderRadiusWhenStacked: "all", // 'all', 'last'
                horizontal: false,
                barHeight: "60%",
                dataLabels: {
                  position: "top", // top, center, bottom
                  total: {
                    offsetX: 0,
                    offsetY: 10,
                  },
                  hideOverflowingLabels: false,
                },
              },
            },
            dataLabels: {
              enabled: true,
              offsetY: -20,
              style: {
                fontSize: "12px",
                colors: ["#767B8A"],
                fontWeight: 400,
              },
              formatter: function (val, opts) {
                return typeof val == "number" ? num2Hip(val > 0 ? val : -val) : ""
                // return val < 0 ? num2Hip(val * -1) : num2Hip(val);
              },
            },
            stroke: {
              width: 1,
              colors: ["#fff"],
            },
            legend: {
              show: false,
            },
            grid: {
              show: false,
            },
            yaxis: {
              stepSize: 1,
              show: false,
              // increase the max value 20% to make the chart look better
              min: enableCenterZero ? maxNum * -1.2 : undefined,
              max: enableCenterZero ? maxNum * 1.2 : undefined,
            },
            xaxis: {
              categories: categories, // ['Biden', 'Trump', 'Kennedy'],
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
            },
            tooltip: {
              shared: false,
              x: {
                formatter: function (val) {
                  return val
                },
              },
              y: {
                formatter: function (val) {
                  return val < 0 ? num2Hip(val * -1) : num2Hip(val)
                },
              },
            },
          }}
          type="bar"
          height={height}
        />
      )}
    </Card>
  )
}

export { barChartConfig, WordCloudGraph, NotEnoughData, BarChart, DonutChart, SmallBarNegativeChart, IncreaseDecreaseChart }
