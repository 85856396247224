import React, { useContext, useEffect, useState } from "react"
import dayjs from "dayjs"
import { getDateRangeOfDays, _nopropagate } from "../../helpers/utils"
import { useFilteredContent } from "../../hooks/useFilteredContents"
import { CreatorCard } from "../creatorCard"
import { dataContext } from "../../contexts/datacontext"
import { ScrollSpy } from "../scrollspy"
import { CommonContentStats, ExpandButtonInline, WrappedInLoader } from "../dumbs"
import { IfNotGuest } from "../functional/auth_components"
import { DISCOVERY_POST_SECTION_CONFIG } from "../../consts"
import { ContentFilter } from "./contentAndCreatorFilter"
import { EcoSystemThumbnail } from "../contentThumbnail"
import { apiContext } from "../../contexts/apicontext"
import { Button } from "../button"
import { useLocation, useSearchParams } from "react-router-dom"
import { RightArrowLargeOutlinedMono, RightArrowMediumOutlinedMono } from "../Icons"

const FilteredContent = ({
  className = "",
  section = "data_point_specific_content",
  additionalFilters,
  guestBlurAfter,
  formattedDate,
  formattedDateTo,
  title,
  normalDefaultFilter,
  skipFormattedTo = false,
  showFilter = true,
}) => {
  const [data] = useContext(dataContext)
  const api = useContext(apiContext)
  const {
    modified_payload,
    setFilters,
    loader,
    filters,
    content_stats_payload,
    showModal,
    pagination,
    cutoff,
    sDO,
    isGuest,
    filterQueryParam,
    detailOpen,
    sortAndTimeFrame,
    onApply,
    pollingStatus,
    setSortAndTimeFrame,
  } = useFilteredContent({
    section,
    additionalFilters,
    showFilter: true,
    normalDefaultFilter,
    countAdditionalFilterAsOG: true,
  })

  return (
    <>
      <div className="flex j-space-between">
        <div>
          <h2 className="headline-xlarge">{title}</h2>
          {
            (formattedDate || formattedDateTo) && (
              <div className="flex nc50-fg">
                <span className="headline-small-highlights ">
                  {formattedDate} {skipFormattedTo ? null : <>- {formattedDateTo}</>}
                </span>
              </div>
            )
          }
        </div>
        <CommonContentStats globalSeparator={false} data={content_stats_payload} align="right" />
      </div>

      <div
        className={`brand-ecosystem-content-list brand-content-list-${section} ${className} ${isGuest ? "guest-mode-list" : ""} guest-blur-after-${guestBlurAfter}`}
      >
        <div className="border-1 border-radius-8 padding-5x nc10-border margin-top-6x">
          {showFilter && (
            <div className=" margin-bottom-4x ">
              <ContentFilter
                showBarNps={!data?.me?.brand?.settings?.is_political_dashboard_enabled}
                showNpsFilter={!data?.me?.brand?.settings?.is_political_dashboard_enabled}
                showNotInfluencer={false}
                showIsNewFilter={false}
                preAppliedFilters={DISCOVERY_POST_SECTION_CONFIG[section]?.filters}
                showPostOnlyFiler={section === "all_posts"}
                onApply={onApply}
                filters={filters}
                setFilters={setFilters}
                sortAndTimeFrame={sortAndTimeFrame}
                setSortAndTimeFrame={setSortAndTimeFrame}
              />
            </div>
          )}
          <WrappedInLoader
            data={modified_payload}
            loader={loader}
            silent={pagination?.page >= 1}
            noContentText="No contents found"
          >
            <div className="be-list-section-content-list">
              {cutoff(modified_payload)?.map?.((_item, _idx) => (
                <EcoSystemThumbnail
                  onClick={() => {
                    api?.alert?.dismiss()
                    showModal(_item, cutoff(modified_payload), _idx, section)
                  }}
                  key={_item.content.id}
                  payload={_item}
                  hideThumbNPSIcon={data?.me?.brand?.settings?.is_political_dashboard_enabled}
                />
              ))}
            </div>
            <IfNotGuest>
              {pagination?.hasNext ? <ScrollSpy loader={loader} onEngage={pagination.loadNext} /> : null}
            </IfNotGuest>
          </WrappedInLoader>
        </div>
      </div>
    </>
  )
}

function formatDate(_date) {
  return dayjs(_date).format("DD MMMM YYYY")
}

const DateSpecificContent = ({ config }) => {
  // a sample config
  // {
  //   brand: { id: 1, title: "Brand Title", name: "brand_name", is_sub_brand: false },
  //   from_date: "2021-08-01",
  //   to_date: "2021-08-01",
  //   filters: { nps_score: 1 },
  //   excludeFilters: { nps_score: false },
  // }
  const _filters = {
    post_date__gte: config?.from_date,
    post_date__lte: config?.to_date,
    ...(config?.filters ?? {}),
  }

  if (config?.from_date === config?.to_date) {
    delete _filters.post_date__gte
    delete _filters.post_date__lte
    _filters.post_date = config?.from_date
  }
  if (config?.brand?.is_sub_brand) {
    _filters.brand_id = config?.brand?.id
  }

  if (config?.excludeFilters) {
    Object.keys(config.excludeFilters).forEach((key) => {
      if (config.excludeFilters[key]) {
        delete _filters[key]
      }
    })
  }

  const formattedDate = config?.from_date ? formatDate(config?.from_date) : null
  const formattedDateTo = config?.to_date ? formatDate(config?.to_date) : null
  return (
    <div className="txt-left nc100-fg">
      <FilteredContent
        formattedDate={formattedDate}
        skipFormattedTo={config?.from_date === config?.to_date}
        formattedDateTo={formattedDateTo}
        title={config?.brand?.title}
        additionalFilters={_filters}
        showStats
        showFilter={config?.showFilter ?? true}
        normalDefaultFilter={config?.filters?.nps_score !== undefined ? { nps_score: config.filters.nps_score } : null}
      />
    </div>
  )
}

const OpenInAPageButton = ({ config, dismiss }) => {
  const values = {
    brand_title: config?.brand?.title,
    ...config?.filters,
  }
  if(config?.from_date) {
    values.from_date = config?.from_date
  }
  if(config?.to_date) {
    values.to_date = config?.to_date
  }
  if (config?.brand.is_sub_brand) {
    values.sub_brand_id = config?.brand?.id
  }

  const onOpenPage = () => {
    window.open("/date-specific-content?" + new URLSearchParams(values).toString())
  }
  return (
    <div className="flex j-space-between a-center full">
      <Button className="nc75-fg-override" type="ghost" onClick={onOpenPage}>
        Open in a page{" "}
        <span className="nc50-fg" style={{ transform: "rotate(-45deg)" }}>
          {RightArrowLargeOutlinedMono}
        </span>
      </Button>
      <Button onClick={dismiss}>Done</Button>
    </div>
  )
}

const DateSpecificContentContentPage = () => {
  // Create a URLSearchParams object from the query string
  const _queryParams = new URLSearchParams(window.location.search)
  const queryParams = Object.fromEntries(_queryParams.entries())

  const from_date = queryParams?.from_date
  const to_date = queryParams?.to_date

  const formattedDate = from_date ? formatDate(from_date) : null
  const formattedDateTo = to_date ? formatDate(to_date) : null
  const _filters = {}
  if(typeof from_date === "string" && from_date === to_date) {
    _filters.post_date = from_date
  } else if (from_date && to_date) {
    _filters.post_date__gte = from_date
    _filters.post_date__lte = to_date
  }

  if (queryParams?.sub_brand_id) {
    _filters.brand_id = queryParams.sub_brand_id
  }

  // for all params add them to the filters
  Object.keys(queryParams).forEach((key) => {
    if (key !== "from_date" && key !== "to_date" && key !== "sub_brand_id" && key !== "brand_title") {
      _filters[key] = queryParams[key]
    }
  })

  return (
    <div className="content-area padding-5x">
      <FilteredContent
        formattedDate={formattedDate}
        formattedDateTo={formattedDateTo}
        title={queryParams?.brand_title}
        additionalFilters={_filters}
        normalDefaultFilter={queryParams?.nps_score !== undefined ? { nps_score: queryParams.nps_score } : null}
      />
    </div>
  )
}

function DateSpecificContentPopup() {
  const [data, dispatch] = useContext(dataContext)
  let [searchParams, setSearchParams] = useSearchParams()
  const dismissDialog = () => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete("sub_brand_id")
    setSearchParams(newSearchParams)
    dispatch({ date_specific_dialog_data: null })
  }

  const config = data?.date_specific_dialog_data

  useEffect(() => {
    if (!config) return
    if (config?.brand?.is_sub_brand) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set("sub_brand_id", config?.brand?.id)
      setSearchParams(newSearchParams)
    }
  }, [config])

  if (!config) return null
  return (
    <div style={{ zIndex: 2500 }} onClick={dismissDialog} className={`cs-dialog cs-dialog-cover flex hcenter vcenter`}>
      <div className={`cs-dialog-body closes-other-popups flex column min-width-1232-important`} onClick={_nopropagate}>
        <div
          className={`cs-dialog-content full nc00-bg padding-y-5x txt-center padding-x-5x border-radius-top-right-8 border-radius-top-left-8 scrollable-dialog-content`}
        >
          <DateSpecificContent config={config}></DateSpecificContent>
        </div>
        <div
          className={`cs-ns-tag-dialog-footer flex j-end border-radius-bottom-left-4 border-radius-bottom-right-4 padding-4x nc00-bg border-top-1 nc05-border border-radius-bottom-right-8 border-radius-bottom-left-8`}
        >
          <OpenInAPageButton config={config} dismiss={dismissDialog} />
        </div>
      </div>
    </div>
  )
}

export { DateSpecificContent, OpenInAPageButton, DateSpecificContentContentPage, DateSpecificContentPopup }
