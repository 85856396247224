import React, { useContext, useEffect, useLayoutEffect, useState } from "react"
import { apiContext } from "../../../contexts/apicontext"
import { GET_CALLS } from "../../../consts"
import { dataContext } from "../../../contexts/datacontext"
import {
  capitalizeFirstLetter,
  convertToPercentage,
  formatDate,
  getSubtractedDate,
  moveIdsToFront,
  num2Hip,
} from "../../../helpers/utils"
import { useDebounce, useSearchParamsHelper } from "../../../hooks/helperHooks"
import { useAuthHelper } from "../../../hooks/useAuthHelper"
import { useBDBoundData, useBDPostData, useLocalBoundData } from "../../../hooks/useBoundData"
import { SearchMediumFilledMono } from "../../Icons"
import { Button } from "../../button"
import { Card, Collapsible, ExpandButtonInline, WrappedInLoader, YoutubeDataAggregationWarning } from "../../dumbs"
import { ScrollSpy } from "../../scrollspy"
import { Toggle } from "../../toggle"
import { ContentFilter, DiscoveryPlatformFilter, SubBrandFilterTabs } from "../contentAndCreatorFilter"
import {
  BECreatorList,
  BELocalContentList,
  BELocalCreatorList,
  BeSimpleContentStats,
  ContentOverviewSection,
  TrendingRankTable,
} from "./beSections"
import { BarChart } from "../../ui/graphs"
import { POLITICAL_CANDIDATE_NAME_SERIAL } from "../../../consts"
import { DateRangePicker } from "../../input"
import { useNavigate, useSearchParams } from "react-router-dom"
import { KVMDataParser } from "../../pages/brandCompetitorsStats"
import { Loader } from "../../loaders"

const TOP_FIVE_WORST_FIVE_CONFIG = {
  owned: {
    title: "All owned",
    defaultSortTimeFilter: {
      sort_by: "emv_in_cents",
      // mention_type__not: 2, // no comment mention
      is_owned_by_brand: true,
      is_influencer_content: false,
      fe_section: "owned_posts",
    },
    defaultNormalFilter: {
      is_owned_by_brand: true,
    },
    bestOrder: {
      limit: 5,
      sort_by: "emv_in_cents",
      fe_section: "owned_posts_best_five",
    },
    worstOrder: {
      limit: 5,
      sort_by: "-emv_in_cents",
      fe_section: "owned_posts_worst_five",
    },
  },
  influencer: {
    title: "All influencer",
    defaultSortTimeFilter: {
      sort_by: "emv_in_cents",
      mention_type__not: 2, // no comment mention
      is_influencer_content: true,
      is_owned_by_brand: false,
      fe_section: "influencer_post",
    },
    defaultNormalFilter: {
      is_influencer_content: true,
    },
    bestOrder: {
      limit: 5,
      sort_by: "emv_in_cents",
      fe_section: "earned_posts_best_five",
    },
    worstOrder: {
      limit: 5,
      sort_by: "-emv_in_cents",
      fe_section: "earned_posts_worst_five",
    },
  },
  earned: {
    title: "All earned",
    defaultSortTimeFilter: {
      sort_by: "emv_in_cents",
      // mention_type__not: 2, // no comment mention
      is_owned_by_brand: false,
      is_from_organic_search: true,
      fe_section: "earned_posts",
      is_influencer_content: false,
    },
    defaultNormalFilter: {
      is_from_organic_search: true,
    },
    bestOrder: {
      limit: 5,
      nps_score: "1",
      sort_by: "emv_in_cents,nps_engagement_multiplier,",
      fe_section: "earned_posts_best_five",
    },
    worstOrder: {
      limit: 5,
      nps_score: "-1",
      sort_by: "emv_in_cents,-nps_engagement_multiplier",
      fe_section: "earned_posts_worst_five",
    },
  },
}

function CreatorSection() {
  const [data, dispatch] = useContext(dataContext)

  return (
    <>
      <SubBrandFilterTabs className={"margin-top-7x"}></SubBrandFilterTabs>
      {!data?.be_section_view_all ? <DiscoveryPlatformFilter className="margin-top-6x margin-bottom-4x" /> : null}

      <BELocalCreatorList
        guestBlurAfter={8}
        useTimeframeStats
        cutoffAt={4}
        mini={true}
        section="top_creators_by_brand"
        className="margin-top-6x"
        title={`Top Creators`}
        showStats={true}
        showFilter
        forceViewAllButton
      ></BELocalCreatorList>

      <BELocalCreatorList
        guestBlurAfter={8}
        useTimeframeStats
        cutoffAt={4}
        mini={true}
        section="new_creators_by_brand"
        className="margin-top-6x"
        title={`Newly discovered creators`}
        showStats={true}
        showFilter
        forceViewAllButton
      ></BELocalCreatorList>
      <BELocalCreatorList
        guestBlurAfter={8}
        useTimeframeStats
        cutoffAt={4}
        mini={true}
        section="influencers_by_brand"
        className="margin-top-6x"
        title={`Influencers`}
        showStats={true}
        showFilter
        forceViewAllButton
      ></BELocalCreatorList>
      <BELocalCreatorList
        guestBlurAfter={8}
        useTimeframeStats
        cutoffAt={4}
        mini={true}
        section="all_creators_by_brand"
        className="margin-top-6x"
        title={`All creators`}
        showStats={true}
        showFilter
        forceViewAllButton
      ></BELocalCreatorList>
    </>
  )
}

const DefaultDiscoverySection = ({ toDate, fromDate, itemFilterFunction, platform }) => {
  return (
    <>
      <BELocalContentList
        itemFilterFunction={itemFilterFunction}
        showContentCountInTitle
        showCreateInsight
        onlyWhenData
        forceViewAllButton
        cutoffAt={5}
        guestBlurAfter={20}
        section="trending_posts"
        className="margin-top-6x"
        title="Trending posts"
        showFilter
        showStats
        fromDate={fromDate}
        toDate={toDate}
      />
      <BELocalContentList
        itemFilterFunction={itemFilterFunction}
        showContentCountInTitle
        showCreateInsight
        onlyWhenData
        forceViewAllButton
        cutoffAt={5}
        guestBlurAfter={20}
        section="negative_nps_posts"
        className="margin-top-6x"
        title="Negative posts"
        showFilter
        showStats
        fromDate={fromDate}
        toDate={toDate}
      />
      <BELocalContentList
        itemFilterFunction={itemFilterFunction}
        showContentCountInTitle
        showCreateInsight
        onlyWhenData
        forceViewAllButton
        cutoffAt={5}
        guestBlurAfter={20}
        section="owned_posts"
        className="margin-top-6x"
        title="Owned posts"
        showFilter
        showStats
        fromDate={fromDate}
        toDate={toDate}
      />
      <BELocalContentList
        itemFilterFunction={itemFilterFunction}
        showContentCountInTitle
        showCreateInsight
        onlyWhenData
        forceViewAllButton
        cutoffAt={5}
        guestBlurAfter={20}
        section="influencer_posts"
        className="margin-top-6x"
        title="Influencer posts"
        showFilter
        showStats
        fromDate={fromDate}
        toDate={toDate}
      />
      <BELocalContentList
        itemFilterFunction={itemFilterFunction}
        showContentCountInTitle
        showCreateInsight
        onlyWhenData
        forceViewAllButton
        cutoffAt={5}
        guestBlurAfter={20}
        section="social_priority_posts"
        className="margin-top-6x"
        title={`Earned posts - ${capitalizeFirstLetter(platform)} prioritized`}
        showFilter
        showStats
        fromDate={fromDate}
        toDate={toDate}
      />
      <BELocalContentList
        itemFilterFunction={itemFilterFunction}
        showContentCountInTitle
        showCreateInsight
        onlyWhenData
        forceViewAllButton
        cutoffAt={5}
        guestBlurAfter={20}
        section="community_posts"
        className="margin-top-6x"
        title="Earned posts - Community content"
        showFilter
        showStats
        fromDate={fromDate}
        toDate={toDate}
      />
      <BELocalContentList
        itemFilterFunction={itemFilterFunction}
        showContentCountInTitle
        showCreateInsight
        onlyWhenData
        forceViewAllButton
        cutoffAt={5}
        guestBlurAfter={20}
        section="community_posts_comment_only"
        className="margin-top-6x"
        title="Earned posts - Mention in comment only"
        showFilter
        showStats
        fromDate={fromDate}
        toDate={toDate}
      />
      <BELocalContentList
        itemFilterFunction={itemFilterFunction}
        showContentCountInTitle
        showCreateInsight
        onlyWhenData
        forceViewAllButton
        cutoffAt={5}
        guestBlurAfter={20}
        section="all_posts"
        className="margin-top-6x"
        title="Archive"
        showFilter
        showStats
        fromDate={"2018-01-01"} // all time
        toDate={formatDate(new Date(), "YYYY-MM-DD")}
      />
    </>
  )
}

const CONTENT_SECTION_TYPE_NAME = "type_of_content_section"

const AllOwnedEarnedSelector = () => {
  const { replaceQueryParams, searchParams, removeQueryParams } = useSearchParamsHelper()
  const selected = searchParams.get(CONTENT_SECTION_TYPE_NAME) ?? ""
  const updateValue = (value) => {
    if (!value) {
      removeQueryParams([CONTENT_SECTION_TYPE_NAME])
      return
    }
    const extraParams = {}
    const sub_brand_id = searchParams.get("sub_brand_id")
    if (sub_brand_id) {
      extraParams.sub_brand_id = sub_brand_id
    }
    console.log(extraParams)
    replaceQueryParams({ [CONTENT_SECTION_TYPE_NAME]: value, ...extraParams })
  }
  return (
    <>
      <div className="flex a-center padding-y-2x">
        <Button
          className={`capsule-button flex vcenter margin-right-2x ${selected === "" ? "selected" : ""}`}
          type="tertiary"
          size="small"
          onClick={() => updateValue("")}
        >
          All
        </Button>
        <Button
          className={`capsule-button flex vcenter margin-right-2x ${selected === "owned" ? "selected" : ""}`}
          type="tertiary"
          size="small"
          onClick={() => updateValue("owned")}
        >
          Owned
        </Button>
        <Button
          className={`capsule-button flex vcenter margin-right-2x ${selected === "influencer" ? "selected" : ""}`}
          type="tertiary"
          size="small"
          onClick={() => updateValue("influencer")}
        >
          Influencer
        </Button>
        <Button
          className={`capsule-button flex vcenter margin-right-2x ${selected === "earned" ? "selected" : ""}`}
          type="tertiary"
          size="small"
          onClick={() => updateValue("earned")}
        >
          Earned
        </Button>
      </div>
    </>
  )
}

const TopFiveBottomFiveSection = ({
  fromDate,
  toDate,
  itemFilterFunction,
  platform = "tiktok",
  title = "",
  defaultSortTimeFilter = {},
  defaultNormalFilter = {},
  bestOrder = { limit: 5 },
  worstOrder = { limit: 5 },
  sub_brand_id = null,
}) => {
  const [sortAndTimeFrame, setSortAndTimeFrame] = useState(defaultSortTimeFilter)
  const [filters, setFilters] = useState(defaultNormalFilter)
  const [subSection, setSubSection] = useState("all")
  const [detailOpen, sDO] = useState(false)
  const [combinedFilter, setCombinedFilter] = useState({
    ...defaultSortTimeFilter,
    ...defaultNormalFilter,
    post_date__gte: fromDate,
    post_date__lte: toDate,
  })

  useEffect(() => {
    if (fromDate && toDate) {
      setCombinedFilter((prev) => {
        return {
          ...prev,
          post_date__gte: fromDate,
          post_date__lte: toDate,
        }
      })
    }
  }, [fromDate, toDate])

  useEffect(() => {
    if (fromDate && toDate) {
      setCombinedFilter((prev) => {
        return {
          ...defaultSortTimeFilter,
          ...defaultNormalFilter,
          post_date__gte: fromDate,
          post_date__lte: toDate,
        }
      })
    }
  }, [defaultSortTimeFilter, defaultNormalFilter])

  const onApply = () => {
    setCombinedFilter({
      ...sortAndTimeFrame,
      ...filters,
      post_date__gte: fromDate,
      post_date__lte: toDate,
    })
  }

  const bestFiveFilter = { ...combinedFilter, ...bestOrder }
  const worstFiveFilter = { ...combinedFilter, ...worstOrder }

  const subSectionBasedFilter = {
    all: combinedFilter,
    top: bestFiveFilter,
    bottom: worstFiveFilter,
  }

  return (
    <div>
      <ContentFilter
        filters={filters}
        setFilters={setFilters}
        sortAndTimeFrame={sortAndTimeFrame}
        setSortAndTimeFrame={setSortAndTimeFrame}
        onApply={onApply}
      />
      <Card className={`margin-top-8x`}>
        <div className={"flex padding-x-3x"}>
          {platform === "youtube" ? (
            <YoutubeDataAggregationWarning />
          ) : (
            <>
              <BeSimpleContentStats
                className="full"
                filters={combinedFilter}
                title={title}
                platform={platform}
                sub_brand_id={sub_brand_id}
              />
              <div className="vertical-divider nc05-bg"></div>
              <BeSimpleContentStats
                className="full"
                filters={bestFiveFilter}
                title={"Top Five"}
                platform={platform}
                sub_brand_id={sub_brand_id}
              />
              <div className="vertical-divider nc05-bg"></div>
              <BeSimpleContentStats
                className="full"
                filters={worstFiveFilter}
                title={"Bottom Five"}
                platform={platform}
                sub_brand_id={sub_brand_id}
              />
            </>
          )}
        </div>
        <hr className="hr" />
        <div className="flex a-center j-end padding-5x">
          {detailOpen ? (
            <div className="margin-right-auto flex">
              <div
                className={`cursor-pointer ${subSection === "all" ? "" : "nc30-fg"}`}
                onClick={() => {
                  setSubSection("all")
                }}
              >
                {title}
              </div>
              <div className="nc30-fg margin-x-2x body-regular-font">/</div>
              <div
                className={`cursor-pointer ${subSection === "top" ? "" : "nc30-fg"}`}
                onClick={() => {
                  setSubSection("top")
                }}
              >
                Top Five
              </div>
              <div className="nc30-fg margin-x-2x body-regular-font">/</div>
              <div
                className={`cursor-pointer ${subSection === "bottom" ? "" : "nc30-fg"}`}
                onClick={() => {
                  setSubSection("bottom")
                }}
              >
                Bottom Five
              </div>
            </div>
          ) : null}
          <ExpandButtonInline trigger={detailOpen} onClick={() => sDO(!detailOpen)} />
        </div>
        <Collapsible expanded={detailOpen}>
          <ContentOverviewSection
            guestBlurAfter={10}
            section={"general_contents"}
            fromDate={fromDate}
            toDate={toDate}
            customFilter={{ ...subSectionBasedFilter[subSection] }}
          />
        </Collapsible>
      </Card>
      <BELocalContentList
        itemFilterFunction={itemFilterFunction}
        customSectionDetailsOverwrite={{ filter: bestFiveFilter }}
        cutoffAt={5}
        guestBlurAfter={20}
        section="general_contents"
        className="margin-top-6x"
        title="Top Five"
        skipShowingSortBy={true}
        showFilter
        fromDate={fromDate}
        toDate={toDate}
      />
      <BELocalContentList
        itemFilterFunction={itemFilterFunction}
        customSectionDetailsOverwrite={{ filter: worstFiveFilter }}
        cutoffAt={5}
        guestBlurAfter={20}
        section="general_contents"
        className="margin-top-6x"
        title="Bottom Five"
        skipShowingSortBy={true}
        showFilter
        fromDate={fromDate}
        toDate={toDate}
      />
      <BELocalContentList
        itemFilterFunction={itemFilterFunction}
        customSectionDetailsOverwrite={{ filter: combinedFilter }}
        showContentCountInTitle
        hideFilterUI
        guestBlurAfter={20}
        section="general_contents"
        className="margin-top-6x"
        title={title}
        showFilter
        fromDate={fromDate}
        toDate={toDate}
      />
    </div>
  )
}

function ContentSection() {
  const [fromDate, setFromDate] = useState(getSubtractedDate(new Date(), 29, "YYYY-MM-DD"))
  const [toDate, setToDate] = useState(formatDate(new Date(), "YYYY-MM-DD"))
  const [data] = useContext(dataContext)
  const [detailOpen, sDO] = useState(false)
  const [subSection, setSubSection] = useState("owned")
  const { searchParams } = useSearchParamsHelper()
  const { loader: all_stats_loader, payload: all_stats_payload } = useLocalBoundData({
    ...GET_CALLS["discovery_analytics_all_posts"],
    filter: {
      ...GET_CALLS["discovery_analytics_all_posts"].filter,
      post_date__gte: fromDate,
      post_date__lte: toDate,
    },
    platform: data?.discovery_section_platform,
    sub_brand_id: data?.sub_brand_id,
  })

  const titles = {
    discovery_analytics_all_posts: "",
    discovery_analytics_trending_posts: "(Trending Posts)",
    discovery_analytics_new_posts: "(New posts)",
  }

  const competitors = data?.me?.brand?.sub_brands?.filter((x) => x?.sub_brand_type === 1) ?? []

  const isViewAll = !!data?.be_section_view_all || data?.be_section_view_all == "all_posts"

  const itemFilterFunction = (contentPayload) => {
    const removeList = data?.deleted_from_discovery ?? []
    return !removeList.some((removed_content_id) => removed_content_id == contentPayload.content.id)
  }

  const competitorMode = searchParams.get("competitorMode")
  const best_worst_selector = searchParams.get("best_worst_selector")
  const typeOfContentSection = searchParams.get(CONTENT_SECTION_TYPE_NAME)

  const _filters = {
    owned: {
      is_owned_by_brand: true,
      fe_section: "top-level-owned-part",
    },
    influencers: {
      is_influencer_content: true,
      is_owned_by_brand: false,
      fe_section: "top-level-influncers-part",
      mention_type__not: 2,
    },
    public: {
      is_influencer_content: false,
      is_owned_by_brand: false,
      is_from_organic_search: true,
      fe_section: "top-level-public-part",
    },
  }

  const _subBrands = data?.me?.brand?.sub_brands ?? []
  const _subBrand = _subBrands.find((x) => x.id == searchParams.get("sub_brand_id"))
  const selectedSubBrandType = _subBrand?.sub_brand_type
  const platform = data?.discovery_section_platform
  const sub_brand_id = data?.sub_brand_id

  useEffect(() => {
    if (selectedSubBrandType === 2) {
      setSubSection("public")
    }
  }, [selectedSubBrandType])

  return (
    <>
      <SubBrandFilterTabs showAllCompetitors showTrending className={"margin-top-7x"} />
      {!competitorMode && (!_subBrand || _subBrand?.sub_brand_type === 1) ? <AllOwnedEarnedSelector /> : null}
      {!data?.be_section_view_all && (
        <div className="flex j-space-between margin-top-6x margin-bottom-4x">
          <DateRangePicker
            fromDate={fromDate}
            toDate={toDate}
            onChange={(from, to) => {
              setFromDate(from)
              setToDate(to)
            }}
          />

          <DiscoveryPlatformFilter skipYoutube={data?.me?.brand?.settings?.is_political_dashboard_enabled} />
        </div>
      )}
      {isViewAll || competitorMode || typeOfContentSection ? null : (
        <Card className={"circle-stat-block margin-y-3x"}>
          <div className={"flex padding-x-2x"}>
            {platform === "youtube" ? (
              <YoutubeDataAggregationWarning />
            ) : (
              <>
                {selectedSubBrandType === 2 ? null : (
                  <>
                    <BeSimpleContentStats
                      className="full"
                      filters={{ ..._filters["owned"] }}
                      fromDate={fromDate}
                      toDate={toDate}
                      title={"Owned post summary"}
                      platform={platform}
                      sub_brand_id={sub_brand_id}
                    />
                    <div className="vertical-divider nc05-bg"></div>
                    <BeSimpleContentStats
                      className="full"
                      filters={{ ..._filters["influencers"] }}
                      fromDate={fromDate}
                      toDate={toDate}
                      title={"Influencer summary"}
                      platform={platform}
                      sub_brand_id={sub_brand_id}
                    />
                    <div className="vertical-divider nc05-bg"></div>
                  </>
                )}
                <BeSimpleContentStats
                  className="full"
                  filters={{ ..._filters["public"] }}
                  fromDate={fromDate}
                  toDate={toDate}
                  title={"Earned post summary"}
                  platform={platform}
                  sub_brand_id={sub_brand_id}
                />
              </>
            )}
          </div>
          <hr className="hr" />
          <div
            className={`${detailOpen ? "nc10-border border-top-1 border-bottom-1" : ""} padding-y-4x padding-x-6x flex vcenter nc75-fg button-medium-font`}
          >
            {detailOpen ? (
              <>
                {selectedSubBrandType !== 2 ? (
                  <>
                    <div
                      className={`cursor-pointer  ${subSection === "owned" ? "" : "nc30-fg"}`}
                      onClick={() => {
                        setSubSection("owned")
                      }}
                    >
                      Owned posts
                    </div>
                    <div className="nc30-fg margin-x-2x button-regular-font">/</div>
                    <div
                      className={`cursor-pointer ${subSection === "influencers" ? "" : "nc30-fg"}`}
                      onClick={() => {
                        setSubSection("influencers")
                      }}
                    >
                      Influencer posts
                    </div>
                    <div className="nc30-fg margin-x-2x body-regular-font">/</div>
                  </>
                ) : null}
                <div
                  className={`cursor-pointer ${subSection === "public" ? "" : "nc30-fg"}`}
                  onClick={() => {
                    setSubSection("public")
                  }}
                >
                  Earned posts
                </div>
              </>
            ) : null}
            <div className="full"></div>
            {!all_stats_loader || !all_stats_payload ? (
              <ExpandButtonInline trigger={detailOpen} onClick={() => sDO(!detailOpen)} />
            ) : null}
          </div>
          <Collapsible expanded={detailOpen}>
            <ContentOverviewSection
              guestBlurAfter={10}
              section={"general_contents"}
              fromDate={fromDate}
              toDate={toDate}
              customFilter={{ ..._filters[subSection] }}
            />
          </Collapsible>
        </Card>
      )}

      {competitorMode ? (
        <CompetitorModeContents
          isViewAll={isViewAll}
          competitors={competitors}
          competitorMode={competitorMode}
          best_worst_selector={best_worst_selector}
          isPolitical={data?.me?.brand?.settings?.is_political_dashboard_enabled}
          itemFilterFunction={itemFilterFunction}
          mainBrandId={data?.me?.brand?.id}
          fromDate={fromDate}
          toDate={toDate}
          platform={data?.discovery_section_platform}
        />
      ) : typeOfContentSection ? (
        <TopFiveBottomFiveSection
          toDate={toDate}
          platform={data?.discovery_section_platform}
          sub_brand_id={data?.sub_brand_id}
          fromDate={fromDate}
          itemFilterFunction={itemFilterFunction}
          {...TOP_FIVE_WORST_FIVE_CONFIG?.[typeOfContentSection]}
        />
      ) : (
        <DefaultDiscoverySection
          toDate={toDate}
          fromDate={fromDate}
          itemFilterFunction={itemFilterFunction}
          platform={data?.discovery_section_platform}
        />
      )}
    </>
  )
}

const CompetitorModeContents = ({
  fromDate,
  toDate,
  isViewAll,
  competitors,
  competitorMode,
  best_worst_selector,
  itemFilterFunction,
  isPolitical,
  mainBrandId,
  platform,
}) => {
  const [data] = useContext(dataContext)
  const _filter_key_map = {
    "all-owned": "owned",
    "all-influencer": "influencer",
    "all-organic": "earned",
  }
  const _TOP_FIVE_WORST_FIVE_CONFIG = TOP_FIVE_WORST_FIVE_CONFIG
  const _all_ids = competitors.map((x) => x.id)

  const all_brand_ids = mainBrandId && !isPolitical ? [mainBrandId, ..._all_ids] : _all_ids

  let apiURL = {}
  switch (best_worst_selector) {
    case "best-five":
      apiURL = GET_CALLS.best_performing_owned_posts
      break
    case "worst-five":
      apiURL = GET_CALLS.worst_performing_owned_posts
      break
    default:
      apiURL = GET_CALLS.key_performance_metrics
  }
  const extra = {}
  if (best_worst_selector) {
    extra.rank_order = best_worst_selector === "best-five" ? "emv_in_cents" : "-emv_in_cents"
    if (competitorMode === "earned") {
      extra.rank_order = "emv_in_cents"
      extra.nps_score = best_worst_selector === "best-five" ? "1" : "-1"
    }
  }

  const allFilter = { ...TOP_FIVE_WORST_FIVE_CONFIG[competitorMode]?.defaultSortTimeFilter, platform }
  delete allFilter.sort_by
  const { payload: kpm_payload, loader: kpm_loader } = useLocalBoundData({
    ...apiURL,
    filter: {
      ...allFilter,
      ...extra,
      post_date__gte: fromDate,
      post_date__lte: toDate,
      brand_id_list: all_brand_ids?.join(","),
    },
  })
  delete extra.rank_order

  const all_ids = _all_ids.join(",")

  const sectionName =
    competitorMode == "owned"
      ? `competitor_owned_posts`
      : competitorMode == "influencer"
        ? `competitor_influencer_posts`
        : `competitor_organic_posts`

  const __additionalFilter = _TOP_FIVE_WORST_FIVE_CONFIG[competitorMode]?.defaultSortTimeFilter

  const processPayload = (field) => {
    if (isPolitical && kpm_payload) {
      return moveIdsToFront(KVMDataParser(kpm_payload, field), POLITICAL_CANDIDATE_NAME_SERIAL, (x) => x.x)
    }
    const _all_data = kpm_payload ? KVMDataParser(kpm_payload, field) : []

    if (!_all_data?.length) {
      return []
    }
    const all_data = []

    const com = [{ ...data?.me?.brand }, ...competitors]
    com?.map((comp) => {
      // console.log(comp)
      const x = comp?.title ?? comp?.name
      // console.log(x)
      const find = _all_data?.find((d) => d.x === x)
      if (find) {
        all_data?.push(find)
      } else {
        all_data?.push({ x: x, y: 0 })
      }
    })
    return all_data
  }

  return (
    <>
      {platform === "youtube" ? (
        <Card className={"circle-stat-block margin-y-3x"}>
          <YoutubeDataAggregationWarning />
        </Card>
      ) : (
        <>
          {!isViewAll && (
            <WrappedInLoader data={kpm_payload} loader={kpm_loader}>
              <div className={`grid grid-cols-${best_worst_selector ? 4 : 5} margin-bottom-4x`}>
                {!best_worst_selector && (
                  <BarChart
                    title="Total posts"
                    className={`margin-right-2x padding-x-2x full`}
                    data={processPayload("total_posts")}
                    options={{
                      dataLabels: {
                        offsetY: -20,
                        style: {
                          fontSize: "10px",
                          colors: ["#767B8A"],
                        },
                        formatter: function (val, opts) {
                          return num2Hip(val, 0)
                        },
                      },
                    }}
                  />
                )}
                <BarChart
                  title="Total views"
                  className={`margin-right-2x padding-x-2x full`}
                  data={processPayload("total_views")}
                  options={{
                    dataLabels: {
                      offsetY: -20,
                      style: {
                        fontSize: "10px",
                        colors: ["#767B8A"],
                      },
                      formatter: function (val, opts) {
                        return num2Hip(val, 0)
                      },
                    },
                  }}
                />
                <BarChart
                  title="Total comments"
                  className={`margin-right-2x padding-x-2x full`}
                  data={processPayload("total_comments")}
                  options={{
                    dataLabels: {
                      offsetY: -20,
                      style: {
                        fontSize: "10px",
                        colors: ["#767B8A"],
                      },
                      formatter: function (val, opts) {
                        return num2Hip(val, 0)
                      },
                    },
                  }}
                />
                <BarChart
                  title="Total engagement"
                  className={`margin-right-2x padding-x-2x full`}
                  data={processPayload("total_brand_engagement")}
                  options={{
                    dataLabels: {
                      offsetY: -20,
                      style: {
                        fontSize: "10px",
                        colors: ["#767B8A"],
                      },
                      formatter: function (val, opts) {
                        return num2Hip(val, 0)
                      },
                    },
                  }}
                />
                <BarChart
                  title="Engagement Rate"
                  className={` padding-x-2x full`}
                  data={processPayload("engagement_rate")}
                  options={{
                    dataLabels: {
                      offsetY: -20,
                      style: {
                        fontSize: "10px",
                        colors: ["#767B8A"],
                      },
                      formatter: function (val, opts) {
                        // return parseInt(num2Hip(val) * 100) + '%'
                        // add 1 decimal point
                        return parseFloat(val * 100).toFixed(1) + "%"
                      },
                    },
                  }}
                />
              </div>
            </WrappedInLoader>
          )}
        </>
      )}
      {best_worst_selector ? (
        <>
          <BELocalContentList
            key={competitorMode + "indivisiual" + "main_brand" + competitorMode + best_worst_selector}
            itemFilterFunction={itemFilterFunction}
            showContentCountInTitle
            viewAllCustomSectionName={sectionName + "main_brand" + competitorMode + best_worst_selector}
            additionalFilters={{
              ...__additionalFilter,
              ...extra,
              limit: 5,
              sort_by:
                competitorMode === "earned"
                  ? "emv_in_cents"
                  : best_worst_selector === "best-five"
                    ? "emv_in_cents"
                    : "-emv_in_cents",
            }}
            forceViewAllButton
            cutoffAt={5}
            guestBlurAfter={20}
            section={"general_contents"}
            className="margin-top-6x"
            title={`${best_worst_selector === "best-five" ? "Top five " : "Bottom five"} ${data?.me?.brand?.title} posts`}
            showFilter
            showStats
            skipShowingSortBy={true}
            waitTillMainFilterSet
            fromDate={fromDate}
            toDate={toDate}
          />

          {competitors.map((competitor, index) => (
            <BELocalContentList
              key={competitorMode + "indivisiual" + index + best_worst_selector}
              itemFilterFunction={itemFilterFunction}
              showContentCountInTitle
              viewAllCustomSectionName={sectionName + competitorMode + competitor?.id + best_worst_selector}
              // customViewAllAction = {()=>{navigate(`/discover-content?sub_brand_id=${competitor?.id}`)}}
              additionalFilters={{
                ...__additionalFilter,
                ...extra,
                limit: 5,
                sort_by:
                  competitorMode === "earned"
                    ? "emv_in_cents"
                    : best_worst_selector === "best-five"
                      ? "emv_in_cents"
                      : "-emv_in_cents",
              }}
              forceViewAllButton
              cutoffAt={5}
              guestBlurAfter={20}
              section={"general_contents"}
              className="margin-top-6x"
              title={`${best_worst_selector === "best-five" ? "Top five " : "Bottom five"} ${competitor?.title} posts`}
              showFilter
              showStats
              skipShowingSortBy={true}
              brandId={competitor?.id}
              waitTillMainFilterSet
              fromDate={fromDate}
              toDate={toDate}
            />
          ))}
        </>
      ) : (
        <>
          <BELocalContentList
            key={competitorMode + "trending"}
            additionalFilters={{ ...__additionalFilter, brand_id_list: all_ids }}
            itemFilterFunction={itemFilterFunction}
            showContentCountInTitle
            skipSubBrand
            forceViewAllButton
            cutoffAt={5}
            guestBlurAfter={20}
            section="trending_posts"
            className="margin-top-6x"
            title="Trending posts"
            showFilter
            showStats
            waitTillMainFilterSet
            fromDate={fromDate}
            toDate={toDate}
          />
          <BELocalContentList
            key={competitorMode + "all_posts_30_default"}
            additionalFilters={{ ...__additionalFilter, brand_id_list: all_ids }}
            itemFilterFunction={itemFilterFunction}
            showContentCountInTitle
            skipSubBrand
            forceViewAllButton
            cutoffAt={5}
            guestBlurAfter={20}
            section="all_posts_30_default"
            className="margin-top-6x"
            title="All Posts"
            showFilter
            showStats
            waitTillMainFilterSet
            fromDate={fromDate}
            toDate={toDate}
          />
          {competitors.map((competitor, index) => (
            <BELocalContentList
              defaultInternalSortTime={{ sort_by: "emv_in_cents" }}
              key={competitorMode + "indivisiual" + index}
              itemFilterFunction={itemFilterFunction}
              showContentCountInTitle
              viewAllCustomSectionName={sectionName + competitor?.id}
              additionalFilters={__additionalFilter}
              // customViewAllAction = {()=>{navigate(`/discover-content?sub_brand_id=${competitor?.id}`)}}
              forceViewAllButton
              cutoffAt={5}
              guestBlurAfter={20}
              section={"general_contents"}
              className="margin-top-6x"
              title={competitor?.title}
              showFilter
              showStats
              brandId={competitor?.id}
              waitTillMainFilterSet
              fromDate={fromDate}
              toDate={toDate}
            />
          ))}
        </>
      )}
    </>
  )
}

const TrendingContents = () => {
  const [data, dispatch] = useContext(dataContext)
  const queryParams = new URLSearchParams(window.location.search)
  let [searchParams, setSearchParams] = useSearchParams()

  const [fromDate, setFromDate] = useState(getSubtractedDate(new Date(), 6, "YYYY-MM-DD"))
  const [toDate, setToDate] = useState(formatDate(new Date(), "YYYY-MM-DD"))

  const industryId = queryParams.get("industry_id")
  const categoryId = queryParams.get("category_id")

  const { payload, loader } = useLocalBoundData({
    ...GET_CALLS.get_brand_catalogs,
  })

  const industries = payload ?? []
  const industry = industries?.find((x) => x.industry_id == industryId)
  const category = industry?.categories?.find((x) => x.category_id == categoryId)

  const onIndustryClick = (id) => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set("industry_id", id)

    if (id != searchParams.get("industry_id")) {
      newSearchParams.delete("category_id")
    }
    setSearchParams(newSearchParams)
  }
  const onCategoryClick = (id) => {
    const newSearchParams = new URLSearchParams(searchParams)
    if (id) {
      newSearchParams.set("category_id", id)
    } else {
      newSearchParams.delete("category_id")
    }
    setSearchParams(newSearchParams)
  }

  let commonTrendingFilter = {}
  if (categoryId) {
    commonTrendingFilter.category_id = categoryId
  } else if (industryId) {
    commonTrendingFilter.industry_id = industryId
  }

  useEffect(() => {
    if (payload?.length) {
      onIndustryClick(payload[0].industry_id)
    }
  }, [payload])

  if (loader) {
    return (
      <div className="flex center padding-y-6x">
        <Loader />
      </div>
    )
  }

  return (
    <>
      <SubBrandFilterTabs showAllCompetitors showTrending className={"margin-top-7x"} />
      <div className="flex wrapped padding-y-2x">
        {industries.map((industry, i) => (
          <Button
            key={i}
            className={`capsule-button flex vcenter margin-right-2x margin-bottom-2x ${industryId == industry.industry_id ? "selected" : ""} `}
            onClick={() => onIndustryClick(industry.industry_id)}
            type="tertiary"
            size="small"
          >
            {industry?.industry_name}
          </Button>
        ))}
      </div>

      {!data?.be_section_view_all && (
        <div className="flex vcenter gap-4 margin-top-4x margin-bottom-5x">
          <h1 className="headline-large">
            Trending {industry ? "in" : ""} {industry?.industry_name ?? "Across Zelf"}
          </h1>
          <DateRangePicker
            fromDate={fromDate}
            toDate={toDate}
            onChange={(from, to) => {
              setFromDate(from)
              setToDate(to)
            }}
          />
          <DiscoveryPlatformFilter
            className="margin-left-auto"
            skipYoutube={data?.me?.brand?.settings?.is_political_dashboard_enabled}
          />
        </div>
      )}

      {industryId && (
        <div className="flex wrapped">
          <Button
            className={`capsule-button flex vcenter margin-right-2x margin-bottom-2x ${categoryId == null ? "selected" : ""} `}
            onClick={() => onCategoryClick(null)}
            type="tertiary"
            size="small"
          >
            All
          </Button>
          {industries
            .find((x) => x.industry_id == industryId)
            ?.categories?.map((category, i) => (
              <Button
                key={i}
                className={`capsule-button flex vcenter margin-right-2x margin-bottom-2x ${categoryId == category.category_id ? "selected" : ""} `}
                onClick={() => onCategoryClick(category.category_id)}
                type="tertiary"
                size="small"
              >
                {category?.category_name}
              </Button>
            ))}
        </div>
      )}

      {!data?.be_section_view_all && (
        <TrendingRankTable
          fromDate={fromDate}
          toDate={toDate}
          industryId={industryId}
          categoryId={categoryId}
          platform={data?.discovery_section_platform}
        />
      )}

      <BELocalContentList
        key={(categoryId ?? industryId) + "trending_earned"}
        itemFilterFunction={() => true}
        forceViewAllButton
        cutoffAt={5}
        guestBlurAfter={20}
        section="god_view_trending_earned_posts"
        className="margin-top-6x"
        title="Trending earned posts"
        hideTableDetails
        showCreateInsight
        showFilter
        showStats
        waitTillMainFilterSet
        additionalFilters={{ ...commonTrendingFilter }}
        fromDate={fromDate}
        toDate={toDate}
      />
      <BELocalContentList
        key={(categoryId ?? industryId) + "trending_influencer"}
        itemFilterFunction={() => true}
        forceViewAllButton
        cutoffAt={5}
        showCreateInsight
        guestBlurAfter={20}
        hideTableDetails
        section="god_view_trending_influencer_posts"
        className="margin-top-6x"
        title="Trending influencer posts"
        showFilter
        showStats
        waitTillMainFilterSet
        additionalFilters={{ ...commonTrendingFilter }}
        fromDate={fromDate}
        toDate={toDate}
      />
      <BELocalContentList
        key={(categoryId ?? industryId) + "trending_brand"}
        itemFilterFunction={() => true}
        forceViewAllButton
        hideTableDetails
        showCreateInsight
        cutoffAt={5}
        guestBlurAfter={20}
        section="god_view_trending_owned_posts"
        className="margin-top-6x"
        title="Trending brand owned posts"
        showFilter
        showStats
        waitTillMainFilterSet
        additionalFilters={{ ...commonTrendingFilter }}
        fromDate={fromDate}
        toDate={toDate}
      />
    </>
  )
}

const defaultInclutionExclutionState = {
  include_add: [],
  include_remove: [],
  exclude_add: [],
  exclude_remove: [],
}
function HashtagsStatsTable({ section, onInclude, onExclude, title, className = "", showSearch = false }) {
  const [search, setSearch] = useState("")
  const debouncedSearchTerm = useDebounce(search, 500)
  const filter = debouncedSearchTerm ? new URLSearchParams({ q: debouncedSearchTerm }).toString() : null
  const { payload, loader, pagination } = useBDBoundData({
    section,
    queryFilter: filter,
    skipBrandRefresh: true,
    skipDiscoveryPlatformRefresh: true,
  })
  const api = useContext(apiContext)
  const [include_exclude_state, set_include_exclude_state] = useState(defaultInclutionExclutionState)
  const { loader: p_loader, executeCall } = useBDPostData({ section: "update_brand_inclution_exluclution" })
  const { isGuest } = useAuthHelper()
  const [data] = useContext(dataContext)

  const removeFromSetNames = (value, setNames) => {
    const a = {}
    for (let s of setNames) {
      const hashtagSet = new Set([...(include_exclude_state?.[s] ?? [])])
      hashtagSet.delete(value)
      a[s] = [...hashtagSet]
    }
    return a
  }
  const addInSetName = (value, s) => {
    const hashtagSet = new Set([...(include_exclude_state?.[s] ?? [])])
    hashtagSet.add(value)
    return [...hashtagSet]
  }

  const addOrRemove = (hashtag, type = "include", add = false) => {
    if (isGuest) {
      api?.alert?.unlockFullExperience()
      return
    }
    const opposite_type = { include: "exclude", exclude: "include" }[type]
    const adding_name = `${type}_${add ? "add" : "remove"}`

    let main_inclution_exlution_changes = {
      [adding_name]: addInSetName(hashtag.hashtag, adding_name),
    }
    let additional_changes = {}
    if (add) {
      console.log([type + "_remove", opposite_type + "_add"])
      additional_changes = removeFromSetNames(hashtag.hashtag, [type + "_remove", opposite_type + "_add"])
      additional_changes = {
        ...additional_changes,
        [opposite_type + "_remove"]: addInSetName(hashtag.hashtag, [opposite_type + "_remove"]),
      }
    } else {
      console.log([type + "_add", opposite_type + "_remove", opposite_type + "_add"])
      additional_changes = removeFromSetNames(hashtag.hashtag, [
        type + "_add",
        opposite_type + "_remove",
        opposite_type + "_add",
      ])
    }
    main_inclution_exlution_changes = { ...main_inclution_exlution_changes, ...additional_changes }
    set_include_exclude_state({
      ...include_exclude_state,
      ...main_inclution_exlution_changes,
    })
  }

  const onApply = async () => {
    // const _section ="update_brand_inclution_exluclution"
    // await api.common.postRequestHelper({
    //     endpoint:`/BrandTags/${data?.sub_brand_id ? data?.sub_brand_id : data?.me?.brand?.id}/inclusion_exclusion/`,
    //     section:_section,
    //     payload: include_exclude_state,
    //     dataContextPrefix:"bd_sec_",
    //     loaders:["bd-section-default",`bd-section-${_section}`]
    // })
    await executeCall(include_exclude_state)
    await api.brand.hardRefreshDashboardSections({ sectionsArray: [section] })
    set_include_exclude_state(defaultInclutionExclutionState)
  }

  const checkHashtag = (hashtag, type = "include") => {
    const _v_name = type == "include" ? "is_included" : "is_excluded"
    if (include_exclude_state?.[type + "_remove"]?.includes(hashtag.hashtag)) return false
    if (include_exclude_state?.[type + "_add"]?.includes(hashtag.hashtag)) return true
    return hashtag?.[_v_name]
  }

  const _payload = payload?.filter((x) => x.hashtag?.includes(search))

  useEffect(() => {
    return () => setSearch("")
  }, [])

  useEffect(() => {
    api.brand.resetSection(section)
  }, [debouncedSearchTerm])

  return (
    <div className={className}>
      {title ? <h3 className="headline-medium-highlight margin-bottom-3x">{title}</h3> : null}
      <Card>
        <div
          className={`hashtags-analysis-table-grid max-height-248 overflow-auto ${onInclude && onExclude ? "has-include-and-exclude" : ""} padding-y body-highlight-font row-stick-top `}
        >
          <div className="nc50-fg padding-y flex a-center padding-left-4x row-stick-top nc00-bg ">
            {showSearch ? (
              <div className="padding-x-3x padding-right-2x padding-y-2x nc02-bg nc10-border flex border-radius-4 nc10-border border-1">
                <input
                  placeholder="hashtags"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="bear-input nc02-bg full"
                />
                {SearchMediumFilledMono}
              </div>
            ) : (
              "hashtags"
            )}
          </div>
          <div className="nc50-fg padding-y flex a-center j-center row-stick-top nc00-bg txt-center">
            total posts <br /> on Zelf
          </div>
          <div className="nc50-fg padding-y flex a-center j-center row-stick-top nc00-bg txt-center">
            total views <br /> on Tiktok
          </div>
          <div className="nc50-fg padding-y flex a-center j-center row-stick-top nc00-bg txt-center">
            % of post <br /> on Zelf{" "}
          </div>
          {onInclude ? <div className=""></div> : null}
          {onInclude ? (
            <div className="nc50-fg padding-y flex a-center j-center row-stick-top nc00-bg">include</div>
          ) : null}
          {onExclude ? (
            <div className="nc50-fg padding-y flex a-center j-center row-stick-top nc00-bg">exclude</div>
          ) : null}

          <WrappedInLoader data={payload} loader={loader || p_loader} silent={data[`bd_sec_${section}_page`] > 1}>
            {_payload?.map((x, i) => (
              <React.Fragment key={i}>
                <hr className="hr full-span-grid-column " />
                <div className="padding-y-2x flex a-center padding-left-4x">#{x.hashtag}</div>
                <div className="padding-y-2x flex a-center j-center">{num2Hip(x.total_items)}</div>
                <div className="padding-y-2x flex a-center j-center">{num2Hip(x.total_hashtag_views)}</div>
                <div className="padding-y-2x flex a-center j-center">{convertToPercentage(x.percentage, 1)}</div>
                {onInclude ? <div className=""></div> : null}
                {onInclude ? (
                  <div className="nc50-fg padding-y flex a-center j-center">
                    <Toggle
                      value={checkHashtag(x, "include")}
                      onChange={() => addOrRemove(x, "include", !checkHashtag(x, "include"))}
                    />
                  </div>
                ) : null}
                {onExclude ? (
                  <div className="nc50-fg padding-y flex a-center j-center">
                    <Toggle
                      value={checkHashtag(x, "exclude")}
                      onChange={() => addOrRemove(x, "exclude", !checkHashtag(x, "exclude"))}
                    />
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          </WrappedInLoader>

          <ScrollSpy
            className="full-span-grid-column"
            rebindTrigger={data[`bd_sec_${section}_raw`]?.pagination?.next}
            loader={loader}
            onEngage={pagination.loadNext}
          />
        </div>
        {onInclude ? (
          <>
            <hr className="hr full-span-grid-column " />
            <div className="flex position-relative full-span-grid-column j-end a-center padding-x-4x nc00-bg-override z-index-100 padding-y">
              <Button type="ghost" onClick={onApply}>
                Apply changes
              </Button>
            </div>
          </>
        ) : null}
      </Card>
    </div>
  )
}

function HashtagsSection({ className = "margin-y-6x" }) {
  const [data] = useContext(dataContext)

  return (
    <div className={className}>
      <HashtagsStatsTable section="hashtag_analytics" title="Your Hashtags" />

      <HashtagsStatsTable
        showSearch
        section="related_hashtag_analytics"
        className="margin-y-4x"
        title="related Hashtags"
        onExclude={data?.me?.user_group != null || data?.me?.brand?.settings?.is_term_edit_enabled}
        onInclude={data?.me?.user_group != null || data?.me?.brand?.settings?.is_term_edit_enabled}
      />
    </div>
  )
}

export { ContentSection, TrendingContents, CreatorSection }
