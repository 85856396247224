import React, { useContext, useEffect, useState } from "react"
import { dataContext } from "../../contexts/datacontext"
import { Card, WrappedInLoader } from "../dumbs"
import { useBDBoundData, useBDPostData } from "../../hooks/useBoundData"
import { Toggle } from "../toggle"
import { capitalizeFirstLetter, convertToPercentage, num2Hip } from "../../helpers/utils"
import { ChevronBackwardSmallOutlinedMono, SearchMediumFilledMono } from "../Icons"
import { apiContext } from "../../contexts/apicontext"
import { useAuthHelper } from "../../hooks/useAuthHelper"
import { ScrollSpy } from "../scrollspy"
import { useDebounce } from "../../hooks/helperHooks"
import { Button } from "../button"
import { useNavigate } from "react-router-dom"

const defaultInclusionExclusionState = {
  include_add: [],
  include_remove: [],
  exclude_add: [],
  exclude_remove: [],
}
function HashtagsStatsTable({ section, onInclude, onExclude, title, className = "", showSearch = false }) {
  const [search, setSearch] = useState("")
  const debouncedSearchTerm = useDebounce(search, 500)
  const filter = debouncedSearchTerm ? new URLSearchParams({ q: debouncedSearchTerm }).toString() : null
  const { payload, loader, pagination } = useBDBoundData({
    section,
    queryFilter: filter,
    skipBrandRefresh: true,
    skipDiscoveryPlatformRefresh: true,
  })
  const api = useContext(apiContext)
  const [include_exclude_state, set_include_exclude_state] = useState(defaultInclusionExclusionState)
  const { loader: p_loader, executeCall } = useBDPostData({ section: "update_brand_inclution_exluclution" })
  const { isGuest } = useAuthHelper()
  const [data] = useContext(dataContext)

  const removeFromSetNames = (value, setNames) => {
    const a = {}
    for (let s of setNames) {
      const hashtagSet = new Set([...(include_exclude_state?.[s] ?? [])])
      hashtagSet.delete(value)
      a[s] = [...hashtagSet]
    }
    return a
  }
  const addInSetName = (value, s) => {
    const hashtagSet = new Set([...(include_exclude_state?.[s] ?? [])])
    hashtagSet.add(value)
    return [...hashtagSet]
  }

  const addOrRemove = (hashtag, type = "include", add = false) => {
    if (isGuest) {
      api?.alert?.unlockFullExperience()
      return
    }
    const opposite_type = { include: "exclude", exclude: "include" }[type]
    const adding_name = `${type}_${add ? "add" : "remove"}`

    let main_inclusion_exclusion_changes = {
      [adding_name]: addInSetName(hashtag.hashtag, adding_name),
    }
    let additional_changes = {}
    if (add) {
      console.log([type + "_remove", opposite_type + "_add"])
      additional_changes = removeFromSetNames(hashtag.hashtag, [type + "_remove", opposite_type + "_add"])
      additional_changes = {
        ...additional_changes,
        [opposite_type + "_remove"]: addInSetName(hashtag.hashtag, [opposite_type + "_remove"]),
      }
    } else {
      console.log([type + "_add", opposite_type + "_remove", opposite_type + "_add"])
      additional_changes = removeFromSetNames(hashtag.hashtag, [
        type + "_add",
        opposite_type + "_remove",
        opposite_type + "_add",
      ])
    }
    main_inclusion_exclusion_changes = { ...main_inclusion_exclusion_changes, ...additional_changes }
    set_include_exclude_state({
      ...include_exclude_state,
      ...main_inclusion_exclusion_changes,
    })
  }

  const onApply = async () => {
    await executeCall(include_exclude_state)
    await api?.brand.hardRefreshDashboardSections({ sectionsArray: [section] })
    set_include_exclude_state(defaultInclusionExclusionState)
  }

  const checkHashtag = (hashtag, type = "include") => {
    const _v_name = type == "include" ? "is_included" : "is_excluded"
    if (include_exclude_state?.[type + "_remove"]?.includes(hashtag.hashtag)) return false
    if (include_exclude_state?.[type + "_add"]?.includes(hashtag.hashtag)) return true
    return hashtag?.[_v_name]
  }

  const _payload = payload?.filter((x) => x.hashtag?.includes(search))

  useEffect(() => {
    return () => setSearch("")
  }, [])

  useEffect(() => {
    api?.brand.resetSection(section)
  }, [debouncedSearchTerm])

  return (
    <div className={`${className} margin-top-5x`}>
      <div className="flex vcenter margin-bottom-2x">
        {title && <h3 className="headline-medium-highlight">{title}</h3>}
        {(onInclude || onExclude) && (
          <Button className="margin-left-auto" type="transparent" onClick={onApply}>
            Apply changes
          </Button>
        )}
      </div>

      <Card className="overflow-hidden">
        <div className={`max-height-344 min-height-344 overflow-auto padding-y body-highlight-font `}>
          <div
            className={`hashtags-analysis-table-grid row-stick-top nc00-bg ${onInclude && onExclude ? "has-include-and-exclude" : ""}`}
          >
            <div className="nc50-fg padding-y flex a-center padding-left-4x as-stretch">
              {showSearch ? (
                <div className="padding-x-3x padding-right-2x padding-y-2x nc02-bg nc10-border flex border-radius-4 nc10-border border-1">
                  <input
                    placeholder="hashtags"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="bear-input nc02-bg full"
                  />
                  {SearchMediumFilledMono}
                </div>
              ) : (
                "hashtags"
              )}
            </div>
            <div className="nc50-fg padding-y flex a-center j-center txt-center as-stretch">
              total posts <br /> on Zelf
            </div>
            <div className="nc50-fg padding-y flex a-center j-center txt-center as-stretch">
              total views <br /> on Tiktok
            </div>
            <div className="nc50-fg padding-y flex a-center j-center txt-center as-stretch">
              % of post <br /> on Zelf{" "}
            </div>
            {onInclude && <div className="nc50-fg padding-y flex a-center j-center as-stretch">include</div>}
            {onExclude && <div className="nc50-fg padding-y flex a-center j-center as-stretch">exclude</div>}
          </div>
          <div className={`hashtags-analysis-table-grid ${onInclude && onExclude ? "has-include-and-exclude" : ""}`}>
            <WrappedInLoader data={payload} silent={data[`bd_sec_${section}_page`] > 1}>
              {_payload?.map((x, i) => (
                <React.Fragment key={i}>
                  <hr className="hr full-span-grid-column " />
                  <div className="padding-y-2x flex a-center padding-left-4x">#{x.hashtag}</div>
                  <div className="padding-y-2x flex a-center j-center">{num2Hip(x.total_items)}</div>
                  <div className="padding-y-2x flex a-center j-center">{num2Hip(x.total_hashtag_views)}</div>
                  <div className="padding-y-2x flex a-center j-center">{convertToPercentage(x.percentage, 1)}</div>
                  {onInclude && (
                    <div className="nc50-fg padding-y flex a-center j-center">
                      <Toggle
                        value={checkHashtag(x, "include")}
                        onChange={() => addOrRemove(x, "include", !checkHashtag(x, "include"))}
                      />
                    </div>
                  )}
                  {onExclude && (
                    <div className="nc50-fg padding-y flex a-center j-center">
                      <Toggle
                        value={checkHashtag(x, "exclude")}
                        onChange={() => addOrRemove(x, "exclude", !checkHashtag(x, "exclude"))}
                      />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </WrappedInLoader>
            <ScrollSpy
              className="full-span-grid-column"
              rebindTrigger={data[`bd_sec_${section}_raw`]?.pagination?.next}
              loader={loader}
              onEngage={pagination.loadNext}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

function HashtagsSection({ className = "margin-y-6x" }) {
  const [data] = useContext(dataContext)
  const brand = data?.me?.brand?.sub_brands?.find((x) => x.id === Number(data?.sub_brand_id))

  return (
    <div className={`${className} max-width-928 margin-x-auto`}>
      <HashtagsStatsTable section="hashtag_analytics" title="Your Hashtags" />
      <HashtagsStatsTable
        showSearch
        section="related_hashtag_analytics"
        className="margin-y-4x"
        title="Related Hashtags"
        onExclude={
          !brand?.sync_from_brand_id &&
          (data?.me?.user_group != null || data?.me?.brand?.settings?.is_term_edit_enabled)
        }
        onInclude={
          !brand?.sync_from_brand_id &&
          (data?.me?.user_group != null || data?.me?.brand?.settings?.is_term_edit_enabled)
        }
      />
    </div>
  )
}

function DiscoverHashtags({ onBack }) {
  const [data, dispatch] = useContext(dataContext)
  const navigate = useNavigate()

  const handleBack = () => {
    dispatch({ show_self_serve_brand_settings: true })
    navigate(-1)
  }

  const _id = Number(data?.sub_brand_id)
  const _sub_brand = data?.me?.brand?.sub_brands?.find((x) => x.id === _id)

  let brandTitle = _sub_brand?.title ?? data?.me?.brand?.title

  return (
    <div className="brand-ecosystem-root brand-ecosystem-main-page-root nc02-bg padding-bottom-10x">
      <div className="brand-ecosystem-header nc00-bg border-bottom-1 nc10-border">
        <div className="flex brand-header-items-left content-area">
          <div className="full">
            <div className="brand-ecosystem-header-logo-actions flex spreaded vcenter margin-top-10x margin-bottom-7x">
              <button
                className="headline-medium-highlight flex vcenter border-none-important transparent-bg cursor-pointer"
                type="button"
                onClick={handleBack}
              >
                {ChevronBackwardSmallOutlinedMono}
                <span className="padding-left-3x">
                  Discover related hashtags for{" "}
                  {brandTitle ? capitalizeFirstLetter(brandTitle) : (data?.me?.brand?.name ?? "your brand")}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={"brand-ecosystem-content-area content-area"}>
        <HashtagsSection />
      </div>
    </div>
  )
}

export { DiscoverHashtags }
