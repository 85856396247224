import React, { useContext, useEffect, useState } from "react";
import { scaleLinear } from "@visx/scale";
import Chart from "react-apexcharts"
import { Card, DotSeparator, WrappedInLoader } from "../dumbs";
import { DonutChart, WordCloudGraph } from "../ui/graphs";
import { EcoSystemThumbnail } from "../contentThumbnail";
import { arraysEqual, cents2USD, cloneObject, cutoffArray, findContentIds, num2Hip, removeLastDot } from "../../helpers/utils";
import { useModalHelper } from "../dialogs/creatorContentModal";
import { ai_summary_for_anything } from "../../dummydata";
import { useServer } from "../../hooks/useServer";
import { GET_CALLS, MUTATION_CALLS } from "../../consts";
import _ from 'lodash'
import { FullLoader, Loader } from "../loaders";
import { dataContext } from "../../contexts/datacontext";
import { useLocalBoundData, useLocalDataMutation } from "../../hooks/useBoundData";
import { useToast } from "../../hooks/useToast";

const getSortedArrayByEmv = (arr) => arr?.slice()?.sort((a, b) => b?.content_stats?.total_emv_in_cents - a?.content_stats?.total_emv_in_cents) || [];

const perceptionGraphConfig = {
    chart: {
        toolbar: {
            show: false
        },
        stacked: true,
    },
    plotOptions: {
        bar: {
            horizontal: true,
            distributed: false,
            barHeight: "50",
        },
    },
    colors: ["#53D7BF", "#F67985"],
    dataLabels: {
        enabled: true,
        style: {
            colors: ["#0E5A78"],
            fontSize: "14px",
            fontWeight: "semibold",
        },
        formatter: function (val, opts) {
            return val + "%";
        }
    },
    yaxis: {
        labels: { show: false },
        max: 100,
        min: 0
    },
    xaxis: {
        categories: ["Price Perception"],
        axisBorder: { show: false },
        axisTicks: { show: false },
        labels: { show: false },
    },
    grid: {
        show: false
    },
    tooltip: {
        y: {
            formatter: (val) => val + "%"
        }
    },
}


function updateContentDetails({ obj = {}, pathArray = [], valueArray = [], fieldName = "content_details" }) {
    for (let [index, value] of pathArray.entries()) {
        // console.log(`Index: ${index}, Value: ${value}`);
        const path = value?.path
        const parentPath = path.split('.').slice(0, -1).join('.');
        const parentObj = _.get(obj, parentPath);
        if (parentObj && valueArray[index]) {
            parentObj[fieldName] = valueArray[index]; // we will get from be // grounding_contents

            // console.log(valueArray[index])
            // console.log(parentObj)
        }
    }
    return obj; // Return the updated object
}

const useAskZelfAnythingResolver = (ai_summary_data) => {
    const [payload, setPayload] = useState(ai_summary_data) // pretend from ai call
    const [isLoading, setIsLoading] = useState(false)
    const { executeCall: getGroundingContents } = useLocalDataMutation(MUTATION_CALLS.grounding_contents)
    const { executeCall: getGroundingStats } = useLocalDataMutation(MUTATION_CALLS.grounding_stats)
    const server = useServer()

    useEffect(() => {
        (async () => {
            if (!ai_summary_data) return
            setIsLoading(true)
            const obj = cloneObject(ai_summary_data)
            const paths = findContentIds(obj)
            const content_id_list = paths.map(x => x.values)
            const _cid = btoa(JSON.stringify(content_id_list))
            const _p = await getGroundingContents({ content_ids: content_id_list })
            const _s = await getGroundingStats({ content_ids: content_id_list })
            updateContentDetails({ obj, pathArray: paths, valueArray: _p, fieldName: "content_details" })
            updateContentDetails({ obj, pathArray: paths, valueArray: _s, fieldName: "content_stats" })
            setPayload(obj)
            setIsLoading(false)

        })()
    }, [ai_summary_data])
    return {
        finalPayload: payload,
        isLoading
    }
}

const CustomerPositiveAndNegativeDrivers = ({
    title = "",
    subtitle = "",
    data = [],
    className = "",
    contentClassName = "",
    maxValue = 0,
    onViewAllClick = () => null,
    isLoading = false,
}) => {
    // Sample data


    const __data = data?.map(x => {
        return {
            name: x.value,
            value: x?.content_stats?.total_emv_in_cents ?? 0,
            content_ids: x?.content_ids,
            content_stats: x?.content_stats,
            content_details: x?.content_details

        }
    })


    // Sort data in descending order
    const sortedData = [...__data].sort((a, b) => b.value - a.value);
    // console.log(sortedData)

    // Scaling
    const _maxValue = maxValue
    const xScale = scaleLinear({ domain: [0, _maxValue], range: [0, 100] });

    const handleViewAllClick = (contentsPayload) => {
        const ids = contentsPayload.map(x => x.content.id)
        // console.log(ids)
        if (ids.length === 0) return
        onViewAllClick({
            filters: {
                ids: ids.join(",")
            },
        })
    }

    return (
        <div className={`flex column ${className}`} style={{ width: "100%" }}>
            <h3 className={`padding-x-4x headline-small-highlight padding-y-2x line-height-8 border-bottom-1 nc10-border flex gap-3 a-center`}>
                {title}
                <span className="button-medium-font nc30-fg margin-left-auto">
                    {subtitle}
                </span>
            </h3>
            {isLoading ? <div className="flex j-center a-center full-height padding-8x"><Loader /></div> : <div className={`flex column flex-grow ${contentClassName}`}>
                {sortedData.map((d, i) => (
                    <div key={i} className={`full-height flex a-center ${i != 0 ? "border-top-1 nc05-border" : ""}`} style={{ minHeight: "42px" }}  >
                        <div className="button-medium-font padding-y-2x padding-x-4x txt-right" style={{ width: "55%" }}>
                            {d.name}
                        </div>
                        <div className={`flex gap-3 a-center as-stretch padding-y-2x padding-right-4x border-left-1 nc02-bg nc10-border`} style={{ width: "45%" }}>
                            <div
                                onClick={() => handleViewAllClick(d?.content_details)}
                                className="cursor-pointer"
                                style={{
                                    height: "21px",
                                    width: `${xScale(d.value)}%`,
                                    backgroundColor: "rgba(102, 63, 241, 1)",
                                }}
                            ></div>
                            <div className="button-tiny-font">${num2Hip(cents2USD(d.value))}</div>
                        </div>
                    </div>
                ))}
            </div>}
        </div>
    );
};



const SingleFavouriteProduct = ({ className = "", title = "Adding ranch to tacos (and other items)", subtitle = '', emvGraphWidthInPercentage = 0, contentsPayload = [], onViewAllClick = () => { } }) => {
    const showModal = useModalHelper()

    const handleViewAllClick = () => {
        const ids = contentsPayload.map(x => x.content.id)
        if (ids.length === 0) return
        onViewAllClick({
            filters: {
                ids: ids.join(",")
            },
        })
    }

    return <div className={`flex j-space-between  ${className}`}>
        <div className="padding-5x full flex column j-center">
            <div className="flex column j-space-between">
                <div className="flex a-center">
                    <h5 className="headline-small-highlight ">{title}</h5>
                    <DotSeparator className="margin-x-2x" />
                    <div className="nc50-fg headline-small-highlights flex-shrink-0">{subtitle}</div>
                </div>
            </div>
            <div style={{ width: 300 }}>
                <div className="margin-top-3x" style={{ backgroundColor: "#663FF1", height: 12, width: emvGraphWidthInPercentage + "%" }}> </div>
            </div>
        </div>
        <div className="flex gap-2 a-center padding-x-4x padding-y-3x" >
            {cutoffArray({ arr: contentsPayload, cutoffAt: 2 })?.map?.((_item, _idx) => (
                <div style={{ width: "64px" }} className="" key={_idx}>
                    <EcoSystemThumbnail
                        hideStats
                        hideCreatorInfo
                        hideExtraInfo
                        hideThumbNPSIcon
                        hidePlatformInfo
                        onClick={() => showModal(_item, contentsPayload, _idx, "trending_now")}
                        payload={_item}
                    />
                </div>
            ))}
            <div role="button" className="cursor-pointer button-medium-font nc50-fg margin-left-2x" onClick={handleViewAllClick}>
                View all
            </div>
        </div>
    </div>

}


const SingleTrendingTopic = ({
    className = "",
    title = "",
    content_stats = {},
    contentsPayload = [],
    onViewAllClick = () => { },
    showViewAll = true,
    cutoffAt = 3,
    thumbnailWidth = "80px",
    smallInfoFont = false
}) => {
    const showModal = useModalHelper()

    const handleViewAllClick = () => {
        const ids = contentsPayload.map(x => x.content.id)
        if (ids.length === 0) return
        onViewAllClick({
            filters: {
                ids: ids.join(",")
            },
        })
    }

    return (
        <div className={`flex j-space-between ${className} `}>
            <div className="padding-5x flex">
                <div className="flex column ">
                    <div>
                        <h5 className="headline-small-highlight margin-bottom-2x">{title}</h5>
                    </div>
                    <div className={`nc50-fg flex gap-2 a-center ${smallInfoFont ? "caption-regular-font" : "headline-small-regular"}`}>
                        <div>{num2Hip(content_stats?.total_posts)} posts</div>
                        <DotSeparator />
                        <div>{num2Hip(content_stats?.total_views)} views</div>
                        <DotSeparator />
                        <div>{num2Hip(content_stats?.total_comments)} comments</div>
                        <DotSeparator />
                        <div>${num2Hip(cents2USD(content_stats?.total_emv_in_cents))} EMV</div>
                    </div>
                </div >
            </div >
            <div className="flex gap-2 a-center padding-x-4x padding-y-3x flex-shrink-0" >
                {cutoffArray({ arr: contentsPayload, cutoffAt: cutoffAt })?.map?.((_item, _idx) => (
                    <div style={{ width: thumbnailWidth }} className="" key={_item.content.id}>
                        <EcoSystemThumbnail
                            hideStats
                            hideCreatorInfo
                            hideExtraInfo
                            hideThumbNPSIcon
                            hidePlatformInfo
                            onClick={() => showModal(_item, contentsPayload, _idx, "trending_now")}
                            payload={_item}
                        />
                    </div>
                ))}
                {showViewAll ? <div role="button" className="cursor-pointer nc50-fg margin-left-2x" onClick={handleViewAllClick}>
                    View all
                </div> : null}
            </div>
        </div >
    )

}


const PerceptionGraph = ({
    data = [],
    dataCount = [],
    colors = ["#53D7BF", "#F67985"],
    title = "",
    subtitle = "",
    isLoading = false,
    onBarClick = () => { }
}) => {
    return (
        <Card>
            <div className="padding-x-5x padding-y-3x border-bottom-1 nc05-border flex gap-3 a-center">
                <h3 className={`headline-small-highlight`}>{title}</h3>
                <div className="button-medium-font nc30-fg">{subtitle}</div>
            </div>
            <div className="padding-4x">
                {
                    isLoading ? <div className="flex j-center a-center padding-4x"><Loader /></div> : (
                        <Chart
                            options={{
                                ...perceptionGraphConfig,
                                colors,
                                dataLabels: {
                                    ...perceptionGraphConfig.dataLabels,
                                    formatter: (val, opts) => {
                                        // return '<div>' + val + '%</div>'
                                        // return `${dataCount[opts.seriesIndex]} - ${val}%`
                                        return `${val}%`
                                    }
                                },
                                chart: {
                                    ...perceptionGraphConfig.chart,
                                    events: {
                                        click: (event, chartContext, config) => onBarClick(config.seriesIndex)
                                    }

                                }

                            }}
                            series={data}
                            type="bar"
                            height={120}
                        />
                    )
                }
            </div>
        </Card>
    )
}


const PriceQualityPerception = ({
    isLoading,
    valueWithGrounding,
    onBarClick = () => { }
}) => {
    const getPerception = (key) => {
        const high = valueWithGrounding?.[key]?.find(x => x.value === "high")?.content_ids?.length ?? 0
        const low = valueWithGrounding?.[key]?.find(x => x.value === "low")?.content_ids?.length ?? 0

        const total = high + low
        return {
            high: total > 0 ? (high / total * 100).toFixed(0) : 0,
            low: total > 0 ? (low / total * 100).toFixed(0) : 0,
            highCount: high,
            lowCount: low
        }
    }

    const handleBarClick = (key, index) => {
        const ids = valueWithGrounding?.[key]?.find(x => {
            // for price_perception, low is 0 and high is 1  & for quality_perception, low is 1 and high is 0
            return key === "price_perception"
                ? x.value === (index === 0 ? "low" : "high")
                : x.value === (index === 0 ? "high" : "low")
        })?.content_ids ?? []

        if (ids.length === 0) return
        onBarClick({
            filters: {
                ids: ids.join(",")
            },
        })
    }

    return (
        <div className="grid grid-cols-2 gap-4 margin-bottom-4x">
            <PerceptionGraph
                title="Price Perception"
                subtitle="(by total mentions)"
                data={[
                    {
                        name: "Affordable",
                        data: [getPerception('price_perception').low ?? 0]
                    },
                    {
                        name: "Expensive",
                        data: [getPerception('price_perception').high ?? 0]
                    }
                ]}
                dataCount={[getPerception('price_perception').lowCount, getPerception('price_perception').highCount]}
                isLoading={isLoading}
                onBarClick={(i) => handleBarClick('price_perception', i)}
            />
            <PerceptionGraph
                title="Quality Perception"
                subtitle="(by total mentions)"
                data={[
                    {
                        name: "High Quality",
                        data: [getPerception('quality_perception').high ?? 0]
                    },
                    {
                        name: "Low Quality",
                        data: [getPerception('quality_perception').low ?? 0]
                    },
                ]}
                colors={["#53C7D7", "#F6C079"]}
                dataCount={[getPerception('quality_perception').highCount, getPerception('quality_perception').lowCount]}
                isLoading={isLoading}
                onBarClick={(i) => handleBarClick('quality_perception', i)}
            />
        </div>
    )
}

const PeopleStatement = ({
    data = [],
    showModal = () => { },
}) => {
    const [cutOffCount, setCutOffCount] = useState(3)
    const handleViewAllClick = () => {
        if (cutOffCount >= data.length) return
        setCutOffCount(10)
    }
    const sortedData = data?.slice()?.sort((a, b) => b?.content_stats?.total_emv_in_cents - a?.content_stats?.total_emv_in_cents) || []

    return (
        <>
            <div>
                {
                    cutoffArray({ arr: sortedData, cutoffAt: cutOffCount })?.map?.((_item, _idx) => (
                        <div
                            className="flex gap-4 a-center padding-x-4x padding-y-2x border-top-1 nc05-border nc02-bg-onhover cursor-pointer"
                            onClick={() => _item?.content_details?.length > 0 ? showModal(_item?.content_details?.[0], [], _idx, "trending_now") : null}
                            key={_idx}
                        >
                            <div style={{ width: "80px" }} className="flex-shrink-0">
                                {_item?.content_details?.length > 0 && (
                                    <EcoSystemThumbnail
                                        hideStats
                                        hideCreatorInfo
                                        hideExtraInfo
                                        hideThumbNPSIcon
                                        hidePlatformInfo
                                        payload={_item?.content_details?.[0]}
                                    />
                                )}
                            </div>
                            <div className="line-height-7">{_item.value}</div>
                        </div>
                    )
                    )
                }
            </div>
            {
                (data.length > 3 && cutOffCount <= 3) && (
                    <>
                        <hr className="hr" />
                        <button type="button" className={`button transparent-bg fluid button-medium nc50-fg min-height-44`} onClick={handleViewAllClick}>View all</button>
                    </>
                )
            }

        </>
    )
}


const PeopleCommunicateWords = ({
    isLoading,
    valueWithGrounding,
}) => {
    const showModal = useModalHelper()

    const mergedData = [...valueWithGrounding?.positive_words ?? [], ...valueWithGrounding?.negative_words ?? []]
    const maxValue = Math.max(...mergedData.map(d => d?.content_stats?.total_emv_in_cents ?? 0) || 0)
    const minValue = Math.min(...mergedData.map(d => d?.content_stats?.total_emv_in_cents ?? 0) || 0)


    return (
        <div className="grid grid-cols-2 gap-4 a-start margin-bottom-4x">
            <Card>
                <h3 className={`padding-x-5x headline-small-highlight padding-y-3x border-bottom-1 nc05-border`}>
                    Positive Words
                </h3>
                <div className="">
                    {isLoading ? <div className="flex j-center a-center padding-4x"><Loader /></div> : (

                        <>
                            <div className="padding-4x">
                                <WordCloudGraph
                                    data={
                                        valueWithGrounding?.positive_words?.map(x => {
                                            return {
                                                text: x.value,
                                                value: parseInt(x?.content_stats?.total_emv_in_cents ?? 0)
                                            }
                                        })
                                        // sample [ { text: "Crispy", value: 100 }]
                                    }
                                    maxVal={maxValue}
                                    minVal={minValue}
                                />
                            </div>
                            <PeopleStatement data={valueWithGrounding?.positive_impactful_statements} showModal={showModal} />
                        </>
                    )}
                </div>
            </Card>
            <Card>
                <h3 className={`padding-x-5x headline-small-highlight padding-y-3x border-bottom-1 nc05-border`}>
                    Negative Words
                </h3>
                <div className="">
                    {isLoading ? <div className="flex j-center a-center padding-4x"><Loader /></div> : (
                        <>
                            <div className="padding-4x">
                                <WordCloudGraph
                                    data={
                                        valueWithGrounding?.negative_words?.map(x => {
                                            return {
                                                text: x.value,
                                                value: parseInt(x?.content_stats?.total_emv_in_cents ?? 0)
                                            }
                                        })
                                        // sample [ { text: "Crispy", value: 100 }]
                                    }
                                    maxVal={maxValue}
                                    minVal={minValue}
                                />
                            </div>
                            <PeopleStatement data={valueWithGrounding?.negative_impactful_statements} showModal={showModal} />
                        </>
                    )}
                </div>
            </Card>
        </div>
    )
}

const MentionComparisonGraphs = () => {
    return (
        <div className="grid grid-cols-2 gap-4 margin-bottom-4x">
            <Card >
                <h3 className={`padding-x-5x headline-small-highlight padding-y-3x border-bottom-1 nc05-border`}>
                    Top Mentioned competitors
                    <span className="button-medium margin-left-2x nc30-fg">(by total positive mentions)</span>
                </h3>
                <div className="padding-x-4x padding-top-4x">
                    <Chart
                        options={{
                            plotOptions: {
                                bar: {
                                    horizontal: true,
                                    distributed: true,
                                    barHeight: "50%",
                                },
                            },
                            colors: ["#5CAC57", "#2B8CDB", "#E64743", "#90349F", "#EC9E22"],
                            chart: {
                                toolbar: {
                                    show: false
                                }
                            },
                            dataLabels: {
                                enabled: false,
                            },
                            xaxis: {
                                categories: ["McDonald's", "KFC", "Burger King", "Wendy's", "Taco Bell"],
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                }
                            },
                            yaxis: {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                }
                            },
                            grid: {
                                borderColor: "#F1F2F3",
                            },
                            legend: {
                                show: false,
                            },
                        }}
                        series={[
                            { name: "Mention", data: [44, 55, 41, 64, 22], },
                        ]}
                        type="bar"
                        height={300}
                    />
                </div>
            </Card>
            <Card>
                <h3 className={`padding-x-5x headline-small-highlight padding-y-3x border-bottom-1 nc05-border`}>
                    Brand mentions over time
                </h3>
                <div className="padding-x-4x padding-top-4x">
                    <Chart
                        options={{
                            colors: ["#5CAC57", "#2B8CDB", "#E64743", "#90349F", "#EC9E22"],
                            chart: {
                                toolbar: {
                                    show: false
                                },
                            },
                            dataLabels: {
                                enabled: false,
                            },
                            xaxis: {
                                categories: ["Jan", "Feb", "Mar", "Apr", "May"],
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                }
                            },
                            markers: {
                                size: 3,
                                strokeWidth: 0,
                                hover: {
                                    size: 4
                                }
                            },
                            stroke: {
                                curve: 'straight',
                                width: 2,
                            },
                            fill: {
                                type: "gradient",
                                gradient: {
                                    type: "vertical",
                                    shadeIntensity: 1,
                                    inverseColors: false,
                                    opacityFrom: 0.45,
                                    opacityTo: 0.00,
                                    stops: [20, 100, 100, 100]
                                },
                            },
                            yaxis: {
                                title: {
                                    text: "Mentions (average per day)",
                                },
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                }
                            },
                            grid: {
                                borderColor: "#F1F2F3",
                            },
                        }}
                        series={[
                            { name: "McDonald's", data: [20, 25, 27, 34, 36], },
                            { name: "KFC", data: [14, 15, 21, 24, 28], },
                            { name: "Burger King", data: [14, 25, 31, 34, 32], },
                            { name: "Wendy's", data: [24, 25, 31, 34, 37], },
                            { name: "Taco Bell", data: [14, 15, 21, 24, 36], },
                        ]}
                        type="area"
                    />
                </div>
            </Card>
        </div>
    )
}


const StandOut = ({ children, vibe = "positive" }) => {
    const style = {
        positive: {
            backgroundColor: "#F2FFF3",
            color: "#086018",
            borderColor: "#43D37D"

        },
        negative: {
            backgroundColor: "#FFF2F2",
            color: "#8F1818",
            borderColor: "#DD5C5C"

        }
    }
    return <span style={style[vibe]} className="headline-small-highlight font-semi-semi-bold-important border-1 nc10-border border-radius-capsule padding-x-3x padding-y-2x line-height-6">{children}</span>
}

const PeopleDescribeComparison = ({ mainBrandName = "", valueWithGrounding = [] }) => {

    return (<>
        <Card className={`overflow-hidden competor-compersion-grid `}>
            {valueWithGrounding?.map((x, i) => {
                const main_brand_strengths = x?.main_brand_strengths?.map(item => removeLastDot(item.trim()));
                const main_brand_weaknesses = x?.main_brand_weaknesses?.map(item => removeLastDot(item.trim()));
                const competitor_strengths = x?.competitor_strengths?.map(item => removeLastDot(item.trim()));
                const competitor_weaknesses = x?.competitor_weaknesses?.map(item => removeLastDot(item.trim()));
                return <React.Fragment key={i}>
                    {i !== 0 ? <hr className="hr full-span-grid-column nc10-bg-override" /> : null}
                    <div className="headline-medium-highlight padding-x-5x padding-y-2x nc02-bg">{mainBrandName ?? "Your brand"}</div>
                    <div className="nc02-bg">
                        <div className="full-height nc10-bg margin-x-auto" style={
                            { width: "1px" }
                        } ></div>
                    </div>
                    <div className="headline-medium-highlight padding-x-5x padding-y-2x nc02-bg">{x?.name}</div>
                    <hr className="hr full-span-grid-column nc10-bg-override" />
                    <div className="headline-medium-highlight padding-x-5x padding-y-5x ">
                        <div className="margin-bottom-3x">
                            <div className="button-medium-font nc50-fg margin-bottom-2x">Strengths</div>
                            <div className="flex wrapped gap-2">
                                {main_brand_strengths?.map((x, i) => <StandOut key={i}>+ {x}</StandOut>)}
                            </div>
                        </div>
                        <div className="margin-bottom">
                            <div className="button-medium-font nc50-fg margin-bottom-2x">Weaknesses</div>
                            <div className="flex wrapped gap-2">
                                {main_brand_weaknesses?.map((x, i) => <StandOut vibe="negative" key={i}>- {x}</StandOut>)}
                            </div>
                        </div>
                    </div>
                    <div className="flex column">
                        <div className="full-height nc10-bg full margin-x-auto" style={
                            { width: "1px" }
                        } ></div>
                        <div className="headline-small-highlight">VS</div>
                        <div className="full-height nc10-bg full margin-x-auto" style={
                            { width: "1px" }
                        } ></div>
                    </div>
                    <div className="headline-medium-highlight padding-x-5x padding-y-5x ">
                        <div className="margin-bottom-3x">
                            <div className="button-medium-font nc50-fg margin-bottom-2x">Strengths</div>
                            <div className="flex wrapped gap-2">
                                {competitor_strengths?.map((x, i) => <StandOut key={i}>+ {x}</StandOut>)}
                            </div>
                        </div>
                        <div className="margin-bottom">
                            <div className="button-medium-font nc50-fg margin-bottom-2x">Weaknesses</div>
                            <div className="flex wrapped gap-2">
                                {competitor_weaknesses?.map((x, i) => <StandOut vibe="negative" key={i}>- {x}</StandOut>)}
                            </div>
                        </div>
                    </div>
                    {x?.comparison_summary ? <>
                        <hr className="hr nc10-bg-override full-span-grid-column" />
                        <div className="full-span-grid-column">
                            <div className="body-regular-font nc50-fg  padding-5x">{x?.comparison_summary} </div>
                        </div>
                    </> : null}
                </React.Fragment>
            })}


        </Card>
    </>
    )
}


const TrendingNow = ({
    valueWithGrounding = [],
    onViewAllClick = () => { },
    className = ""
}) => {
    const sortedValue = getSortedArrayByEmv(valueWithGrounding)
    return <div className={className}>
        <h3 className="margin-bottom-3x margin-top-4x headline-medium-highlight">Trending now</h3>
        <Card className={`overflow-hidden`}>
            <h3 className="headline-small-highlight padding-y-4x border-bottom-1 nc10-border padding-x-5x">Creative or unique use cases/ hacks </h3>
            {sortedValue?.map((x, i) => {
                return <div key={i}>
                    {i !== 0 ? <hr className="hr" /> : null}
                    <SingleTrendingTopic contentsPayload={x?.content_details ?? []} content_stats={x?.content_stats} className={`${i % 2 !== 0 ? "nc02-bg" : ""} `} title={x?.value} onViewAllClick={onViewAllClick} />
                </div>
            })}
        </Card>
    </div>
}
const TrendingTopic = ({
    valueWithGrounding = [],
    onViewAllClick = () => { },
    className = ""
}) => {
    const sortedValue = getSortedArrayByEmv(valueWithGrounding)
    return <div className={className}>
        <Card className={` overflow-hidden`}>
            <h3 className="headline-small-highlight padding-y-4x border-bottom-1 nc10-border padding-x-5x">Trending topics </h3>
            <div className="grid grid-cols-2">

                {sortedValue?.map((x, i) => {
                    return <div key={i} className={`nc10-border ${i > 1 ? "border-top-1" : ""} ${i % 2 == 0 ? "border-right-1" : ""}`}>

                        <SingleTrendingTopic cutoffAt={2} thumbnailWidth="60px" showViewAll={false} contentsPayload={x?.content_details ?? []} content_stats={x?.content_stats} className={`nc02-bg`} title={x?.value} onViewAllClick={onViewAllClick} smallInfoFont />
                    </div>
                })}
            </div>
        </Card>

    </div>
}

const FavouriteProduct = ({
    className = "",
    title = "",
    subtitle = "",
    valueWithGrounding = [],
    countValue = "emv",
    onViewAllClick = () => { }
}) => {
    const maxValue = Math.max(...valueWithGrounding.map(d => d?.content_stats?.total_emv_in_cents ?? 0)) * 1.1;
    const sortedValue = getSortedArrayByEmv(valueWithGrounding)
    const xScale = scaleLinear({ domain: [0, maxValue], range: [0, 100] });

    return (
        <Card className={` overflow-hidden full ${className}`}>
            <div className="padding-y-3x padding-x-5x">
                <div className="headline-small-highlight">
                    {title}
                    <span className="button-medium-font nc30-fg margin-left-2x">{subtitle}</span>
                </div>
            </div>
            <hr className="hr" />
            {sortedValue?.map((x, i) => {
                return <div key={i}>
                    {i !== 0 ? <hr className="hr" /> : null}
                    <SingleFavouriteProduct
                        emvGraphWidthInPercentage={xScale(x?.content_stats?.total_emv_in_cents)}
                        contentsPayload={x?.content_details ?? []}
                        className={`${i % 2 == 0 ? "nc02-bg" : ""} `}
                        title={x?.value}
                        subtitle={`${countValue == "emv" ? `$${num2Hip(cents2USD(x?.content_stats?.total_emv_in_cents))} EMV` : `${num2Hip(x?.content_stats?.total_posts)} posts`}`}
                        onViewAllClick={onViewAllClick}
                    />
                </div>
            })}
        </Card>
    )
}


const AskZelfAnything = ({
}) => {
    const toast = useToast()
    const { payload, loader } = useLocalBoundData({
        ...GET_CALLS.ai_zelf_anything
    })

    const _zAnything = payload?.[0]
    const { finalPayload, isLoading } = useAskZelfAnythingResolver(_zAnything)
    const [allNPS, setAllNPS] = useState([0, 0, 0])
    const [_nps_content_ids, set_nps_content_id] = useState(null)
    const [data, dispatch] = useContext(dataContext)
    const { executeCall: getGroundingContents, loader: nps_loader } = useLocalDataMutation(MUTATION_CALLS.grounding_contents)

    const openContentsPopup = (_data) => {
        if (!_data?.filters) {
            toast("No more data found")
            return
        }
        const _d = {
            brand: {
                title: data?.me?.brand?.title,
                id: data?.me?.brand?.id,
                name: data?.me?.brand?.name
            },
            showFilter: false,
            excludeFilters: {
                post_date: true
            },
            ..._data
        }

        dispatch({
            date_specific_dialog_data: _d,
        })
    }

    useEffect(() => {
        if (!_nps_content_ids?.length) return
        (async () => {
            if (!_nps_content_ids?.length) return
            let all_nps_scores = [0, 0, 0]
            const _contentsPayload = await getGroundingContents({
                content_ids: [_nps_content_ids]
            })
            const contentsPayload = _contentsPayload?.[0]
            if (contentsPayload?.length) {
                contentsPayload?.map((_c => {
                    if (_c) {
                        const _nps = _c?.content?.nps_score
                        const _eng = _c?.content?.total_engagement
                        if (_nps === 1) {
                            all_nps_scores[0] = all_nps_scores[0] + _eng
                        } else if (_nps === 0) {
                            all_nps_scores[1] = all_nps_scores[1] + _eng
                        } else if (_nps === -1) {
                            all_nps_scores[2] = all_nps_scores[2] + _eng
                        }
                    }
                }))
            }
            const _total = all_nps_scores?.reduce((a, b) => a + b, 0)
            if (_total) {
                setAllNPS(all_nps_scores.map(x => (x / _total) * 100))
            }

        })()
    }, [_nps_content_ids])

    useEffect(() => {
        if (!_zAnything) return
        let content_ids = _zAnything?.content_ids
        if (!content_ids?.length) return
        if (arraysEqual(content_ids, _nps_content_ids ?? [])) return
        set_nps_content_id(content_ids)
    }, [_zAnything])

    const total_nps = allNPS?.reduce((a, b) => a + b, 0)
    const allPositiveNegative = [... (finalPayload?.insight_json?.general_insights.overall_sentiment?.grounding?.positive_drivers ?? []), ...(finalPayload?.insight_json?.general_insights.overall_sentiment?.grounding?.negative_drivers ?? [])]
    // const maxValue = Math.max(...allPositiveNegative.map(x => x ? content_stats?.total_emv_in_cents ?? 0 )
    const maxValue = Math.max(...allPositiveNegative.map(d => d.content_stats?.total_emv_in_cents)) * 1.5


    // console.log("finalPayload", finalPayload)


    return (
        <WrappedInLoader loader={loader} data={payload}>
            <div className='content-area padding-bottom-4x padding-top-4x'>

                <h3 className="margin-bottom-3x margin-top-4x headline-medium-highlight">Things to Improve on/ operational insights</h3>
                <Card className={`flex`}>
                    {total_nps > 0 && (
                        <>
                            <div className="border-right-1 nc10-border" style={{ width: "267px" }}>
                                <h3 className={`padding-x-4x headline-small-highlight padding-y-2x line-height-8 border-bottom-1 nc10-border`}>
                                    Overall Sentiment
                                </h3>
                                <DonutChart
                                    data={allNPS}
                                    className="border-none-important"
                                    colors={["#32EAC9", "#C9F8FF", "#F67985"]}
                                    options={{
                                        plotOptions: {
                                            pie: {
                                                startAngle: 0,
                                                endAngle: 360,
                                                donut: {
                                                    size: "55%",
                                                    labels: {
                                                        show: false,
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </>
                    )}
                    <CustomerPositiveAndNegativeDrivers
                        maxValue={maxValue}
                        onViewAllClick={openContentsPopup}
                        data={finalPayload?.insight_json?.general_insights.overall_sentiment?.grounding?.positive_drivers}
                        className="border-right-1 nc10-border full"
                        title="What do customers LOVE most"
                        subtitle="by total EMV"
                        isLoading={isLoading}

                    />
                    <CustomerPositiveAndNegativeDrivers
                        maxValue={maxValue}
                        onViewAllClick={openContentsPopup}
                        data={finalPayload?.insight_json?.general_insights.overall_sentiment?.grounding?.negative_drivers}
                        className="full"
                        title="What do customers DISLIKE most"
                        subtitle="by total EMV"
                        isLoading={isLoading}
                    />
                </Card>
                <div>
                    <h3 className="margin-bottom-3x margin-top-4x headline-medium-highlight">Price and Quality Perception</h3>
                    <PriceQualityPerception isLoading={isLoading} valueWithGrounding={finalPayload?.insight_json?.general_insights?.brand_perception?.grounding} onBarClick={openContentsPopup} />
                </div>
                <div className="grid grid-cols-2 gap-4 margin-y-4x">
                    <FavouriteProduct
                        isLoading={isLoading}
                        valueWithGrounding={finalPayload?.insight_json?.general_insights?.favorite_products_and_features?.grounding?.top_products}
                        onViewAllClick={openContentsPopup}
                        subtitle="by total EMV"
                        title="Favorite products"
                        countValue="emv"
                    />
                    <FavouriteProduct
                        isLoading={isLoading}
                        title="Favorite features"
                        subtitle="by total EMV"
                        valueWithGrounding={finalPayload?.insight_json?.general_insights?.favorite_products_and_features?.grounding?.top_features}
                        onViewAllClick={openContentsPopup}
                        countValue="emv"
                    />

                </div>


                <TrendingNow valueWithGrounding={[...(finalPayload?.insight_json?.general_insights?.trending_now?.grounding?.creative_and_unique_uses ?? [])]} onViewAllClick={openContentsPopup} />

                <div>
                    <h3 className="margin-bottom-3x margin-top-4x headline-medium-highlight">How people communicate</h3>
                    <PeopleCommunicateWords isLoading={isLoading} valueWithGrounding={finalPayload?.insight_json?.general_insights?.customer_communication?.grounding} />
                </div>
                <TrendingTopic className="" valueWithGrounding={[...(finalPayload?.insight_json?.general_insights?.trending_now?.grounding?.top_topics ?? [])]} onViewAllClick={openContentsPopup} />
                <div>
                    <h3 className="margin-bottom-3x margin-top-4x headline-medium-highlight">Competitive overview</h3>
                    {/* <MentionComparisonGraphs /> */}
                    <PeopleDescribeComparison mainBrandName={data?.me?.brand?.title ?? "Your brand"} valueWithGrounding={finalPayload?.insight_json?.general_insights?.competitor_overview?.top_competitors_insight} />
                </div>
            </div>
        </WrappedInLoader>
    )
}



export { AskZelfAnything }