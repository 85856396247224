import { useBDBoundData } from "../../../hooks/useBoundData"
import { useContext, useEffect, useState } from "react"
import { WrappedInLoader } from "../../dumbs"
import { _nopropagate } from "../../../helpers/utils"
import { dataContext } from "../../../contexts/datacontext"
import { apiContext } from "../../../contexts/apicontext"
function SingleCampaign({ campaign, onClick }) {
  return (
    <div
      onClickCapture={onClick}
      className="campaign-list-item nc100-fg txt-left padding-left-2x padding-right-4x padding-y-2x caption-regular-font"
    >
      {campaign.name} <span className="nc50-fg txt-left">({campaign?.creators?.length})</span>
    </div>
  )
}
function CampaignPopup({
  kind,
  onDismiss,
  onCampaignClick,
  onAddNewClick,
  className = "",
  hasDraft = false,
  watchNew = false,
  nextDialog = "",
}) {
  const { loader, payload } = useBDBoundData({ section: "campaign_list_compact" })
  const [finalList, setFinalList] = useState({})
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const listener = () => onDismiss && onDismiss()
  useEffect(() => {
    if (payload) {
      let newfinalList = {
        live: [],
        draft: [],
      }
      payload.forEach((_camp) => {
        // if(_camp.kind !== kind) return false;
        //console.log(_camp.campaign_status);
        if (_camp.campaign_status === "LIVE") newfinalList.live.push(_camp)
        else if (_camp.campaign_status === "DRAFT") newfinalList.draft.push(_camp)
      })
      setFinalList(newfinalList)
    }
  }, [payload])
  useEffect(() => {
    if (watchNew && data.newly_added_campaign) {
      if (onCampaignClick) onCampaignClick(data.newly_added_campaign)
      dispatch({
        newly_added_campaign: null,
      })
      onDismiss && onDismiss()
    }
  }, [watchNew, data.newly_added_campaign])
  useEffect(() => {
    document.body.addEventListener("click", listener, { capture: false, once: true })
    if (document.querySelector(".closes-other-popups"))
      document.querySelector(".closes-other-popups").addEventListener("click", listener, { capture: false, once: true })
    dispatch({
      newly_added_campaign: null, // clean slate
    })
  }, [])
  const campaignClicked = (campaign) => {
    if (onCampaignClick) onCampaignClick(campaign)
    onDismiss && onDismiss()
  }
  const addNew = () => {
    onDismiss && onDismiss()
    api?.alert?.createNewCampaign(hasDraft, nextDialog, kind)
    if (onAddNewClick) onAddNewClick()
  }
  return (
    <div
      onClick={_nopropagate}
      className={`campaign-popup nc10-border border-radius-dynamic border-1 nc00-bg padding-2x margin-bottom ${className}`}
    >
      <WrappedInLoader loader={loader} data={payload}>
        <div className="campaign-section-container">
          {finalList?.live?.length ? (
            <div className="cp-section">
              {/* <div className="cp-section-head nc50-fg margin-bottom txt-left">
                Live campaigns
            </div> */}
              {finalList.live.map((_camp) => (
                <SingleCampaign onClick={() => campaignClicked(_camp)} key={_camp?.id} campaign={_camp} />
              ))}
            </div>
          ) : null}
          {finalList?.draft?.length ? (
            <div className="cp-section">
              {/* <div className="cp-section-head nc50-fg margin-bottom txt-left">
                Draft campaigns
            </div> */}
              {finalList.draft.map((_camp) => (
                <SingleCampaign onClick={() => campaignClicked(_camp)} key={_camp?.id} campaign={_camp} />
              ))}
            </div>
          ) : null}
        </div>
      </WrappedInLoader>
      <div onClickCapture={addNew} className="button ghost padding-x-4x padding-y-4x campaign-compact-list-action">
        create a new campaign
      </div>
    </div>
  )
}

function useCampaignPopupHelper(initial = false) {
  const [popupState, changePopupState] = useState(initial)
  const show = (e) => {
    if (e) _nopropagate(e)
    changePopupState(true)
  }
  const hide = () => changePopupState(false)
  return {
    shown: popupState,
    show,
    hide,
  }
}

export { CampaignPopup, useCampaignPopupHelper }
